import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default function CargaDocumento(props) {
  // styles
  const classes = useStyles();

  const onChange = async (event) => {
    var file = document.querySelector('input[type="file"]').files[0];
    console.log("file", file);
    props.setArchivo(file);

    const base64 = await convertBase64(file);
    //console.log(base64);

    props.setPDF(base64);
    props.handleNext();
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <input
          accept="application/pdf"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          name="PDF"
          onChange={onChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            <Typography variant="h6" style={{ textTransform: "none" }}>
              CARGA DOCUMENTO PDF
            </Typography>
          </Button>
        </label>
      </Grid>
    </React.Fragment>
  );
}
