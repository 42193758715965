import React, { useLayoutEffect, useState } from "react";
import { render } from "react-dom";
import { isMobile } from 'react-device-detect';
import moment from "moment";
import axios from "axios";
import './style.css'; // css styling from the react-vis node module
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
makeVisFlexible,
 XYPlot,
 XAxis,
 YAxis,
 ChartLabel,
 VerticalGridLines,
 HorizontalGridLines,
 VerticalBarSeries,
 VerticalBarSeriesCanvas,
 LabelSeries,
 RadialChart,
 Borders,
 Hint,
 LineSeries,
 Crosshair
 } from 'react-vis';

var width=600;
var height=400;

if(isMobile){
  var width=350;
  var height=300;
}

export default function GraficaPromedioFirma() {
  const [data, setData] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let token = localStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/firmantes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        let arr = response.data;
        //console.log("para firma promedio",arr);
        var date = new Date();
        //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        //var lastDay =  new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var firstDay,lastDay;


        var mes = [];

        for (var i = 6; i >=0; i--) {
          var cont=0,sum=0;
          var monthname="";
          firstDay = new Date(date.getFullYear(), date.getMonth()-i, 1);
          lastDay =  new Date(date.getFullYear(), (date.getMonth()-i) + 1, 0);
          //console.log(moment(firstDay).format("MMM")," => ",firstDay,lastDay);
          monthname=moment(firstDay).format("MMM");
          var resultMonth = arr.filter(function (a) {
            return (
              new Date(a.created_at) >= firstDay &&
              new Date(a.created_at) <= lastDay
            );
          });
          //console.log("MES->",resultMonth);
          resultMonth.forEach((item, i) => {
            if(item.date_signed){
              const dateOneObj = new Date(item.date_notified);
              const dateTwoObj = new Date(item.date_signed);
              const milliseconds = Math.abs(dateTwoObj - dateOneObj);
              const hours = milliseconds / 36e5;
              const minutes = Math.floor(milliseconds/1000/60);
              //const hours = (dateTwoObj - dateOneObj)/36e5;
              //sum+=minutes;
              sum+=hours;
              cont++;

            }
          });
          //var prom = (Math.floor(sum/cont)) ? Math.floor(sum/cont) : "" ;
          var prom = (sum/cont) ? (sum/cont).toFixed(1) : "" ;
          mes = [...mes,{x:monthname,y:prom}]
        } //endfor
        console.log("mes->",mes);
        setData(mes);
      })
      .catch(function (error) {
        console.log(error);
      });

    }, []);

  return (
    <React.Fragment>
    {
      loading ? (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
          <CircularProgress />
        </div>
      ):null
    }
    <Grid item xs={12}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography color="dark" gutterBottom>
          <font size="5">
            <b>HORAS EN PROMEDIO DE LOS FIRMANTES</b>
          </font>
        </Typography>
      </Box>
    </Grid>
    <hr />
    <Grid item xs={12}>
      <Box display="flex" justifyContent="center" alignItems="center">
      {
        data ? (
          <XYPlot
            xType="ordinal"
            width={width}
            height={height}
            xDistance={100}
          >
              <HorizontalGridLines />
              <Borders style={{
                all: {fill: '#fff'},
                bottom: {fill: '#fff'}
              }}/>
              {/*
              <XAxis />
              <YAxis />
              */}
              <YAxis />
              <XAxis />
              <VerticalBarSeries
                data={data}
                color={"#00acc1"}
              />
              <LabelSeries
              animation
              allowOffsetToBeReversed
              data={data.map((obj) => {
                return { ...obj, label: obj.y.toString(), style: {fontSize: 10} };
              })}
              labelAnchorX="middle"
              labelAnchorY="text-after-edge"
            />
            </XYPlot>
        ) : null
      }
      </Box>

    </Grid>
    </React.Fragment>
  )
}
