import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/FooterLogin.js";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "views/Components/Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import SignUp from "containers/signUp/signUp";
import logo from "assets/img/ConCertezaAzul.png";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  whatsapp: {
    position: "fixed",
    width: "80px",
    height: "80px",
    bottom: " 60px",
    right: " 60px",
    backgroundColor: " #25d366",
    color: "#ffff",
    borderRadius: "60px",
    textAlign: "center",
    fontSize: "50px",
  },
}));

export default function Register(props) {
  const classes = useStyles();

  const classess2 = useStyles2();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand={<img src={logo} alt="logo" width="165" height="44" />}
        // rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "black",
        }}
        {...rest}
      />

      <Hidden only={["sm", "xs"]}>
        <Parallax image={require("assets/img/logoMano.png")} Register={true}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.note} style={{ paddingLeft: "115px" }}>
                  <h3
                    style={{
                      color: "#8304b3",
                      fontSize: "2.5rem",
                      fontWeight: "500",
                      maxWidth: "500px",
                      margin: "-40px 0 0",
                    }}
                  >
                    La forma más sencilla y segura para firmar, certificar y
                    notificar documentos electrónicos con certeza jurídica
                  </h3>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <Grid item xs={2} sm={3} md={6}>
            <SignUp />
          </Grid>
        </Parallax>
      </Hidden>
      <Hidden only={["lg", "md"]}>
        <Parallax
          image={require("assets/img/logoMano.png")}
          Register={true}
          celular={true}
        >
          <div className={classes.container}>
            <SignUp />
          </div>
        </Parallax>
      </Hidden>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <Hidden only={["sm", "xs"]}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <br />
            <h3 style={{ fontSize: "2.1rem", fontWeight: "500" }}>
              Conoce el proceso de Firma Digital Con-Certeza
            </h3>
            <br />
            <img
              src={require("assets/img/Con-CertezaProceso.jpg")}
              width="90%"
              height="400"
            />
          </div>
        </Hidden>
        <Hidden only={["lg", "md"]}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <br />
            <h3 style={{ fontSize: "2.1rem", fontWeight: "500" }}>
              Conoce el proceso de Firma Digital Con-Certeza
            </h3>
            <br />
            <img
              src={require("assets/img/procesoCon-CertezaResponsive.png")}
              width="100%"
              height="600"
            />
          </div>
        </Hidden>
        <a
          href="https://wa.me/5215529725568"
          className={classess2.whatsapp}
          target="_blank"
        >
          {" "}
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a>
        <br />
        <br />
        <br />
        {/* <SectionBasics />
        <SectionNavbars />
        <SectionTabs />
    
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript /> */}

        {/* <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem> */}
        {/* <SectionExamples /> */}
      </div>
      <Footer />
    </div>
  );
}
