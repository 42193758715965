import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import BuildIcon from '@material-ui/icons/Build';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Configuracion from "./Configuracion";
import Iniciar from "./Iniciar";
import Expedientes from "./Expedientes";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button:{
    margin:0,
  },
  iconbutton: {
    fontSize:20,
    margin:0,
    //marginTop:11, //15 center
    padding:0,
  },
}));

export default function Inicio(props) {
 const classes = useStyles();
 const [opcion, setOpcion] = React.useState(0);

 React.useEffect(() => {
   //console.log("hola")
 }, []);

  return (
    <React.Fragment>
    <Tooltip title="Ver expedientes" placement="top">
    <IconButton
      className={classes.iconbutton}
      //disabled={opcion==0?true:false}
      variant="contained"
      color="primary"
      onClick={()=>{setOpcion(0)}}
      >
      <ListAltIcon />
    </IconButton>
    </Tooltip>

    <Tooltip title="Iniciar flujo para crear expediente" placement="top">
    <IconButton
      className={classes.iconbutton}
      //disabled={opcion==0?true:false}
      variant="contained"
      color="primary"
      onClick={()=>{setOpcion(1)}}
      >
      <CreateNewFolderIcon />
    </IconButton>
    </Tooltip>

    <Tooltip title="Configuración" placement="top">
    <IconButton
      className={classes.iconbutton}
      //disabled={opcion==1?true:false}
      variant="contained"
      color="primary"
      onClick={()=>{setOpcion(2)}}
    >
      <SettingsApplicationsIcon />
    </IconButton>
    </Tooltip>
    <hr />
    {opcion==0 ?<Expedientes setOpcion={setOpcion} />:null}
    {opcion==1 ?<Iniciar setOpcion={setOpcion} />:null}
    {opcion==2 ?<Configuracion />:null}
    </React.Fragment>
  )

}
