import React from "react";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";

import logo from "assets/img/ConCertezaAzul.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    padding: theme.spacing(1),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ReviewEnd() {
  //id=idDocumento idfirmante=idFirmante

  const classes = useStyles();
  const classespro = useStylesprogrees();
  const [open, setOpen] = React.useState(true);
  let { idDoc, idfirmante } = useParams();
  const [base64, setbase64] = React.useState("");
  const [openZip, setOpenZip] = React.useState(true);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [fileName, setFileName] = React.useState("");

  React.useEffect(() => {
    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }

    handlePDFB64();
    handleinfoDocumento();
  }, []);

  const handlePDFB64 = () => {
    const token = `${process.env.REACT_APP_TOKEN}`;

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDoc}/signed/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleinfoDocumento = () => {
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFileName(res.data.filename);

        getZipDocument(idDoc, res.data.filename);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //funcion para descargar el paquete de constancia del documentos

  const getZipDocument = (idDocu, filename1) => {
    const nameFormat = filename1.slice(0, -4);
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios({
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/bundle/download`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log("RESPONSE", response);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nameFormat}.zip`);
      document.body.appendChild(link);
      link.click();
      setOpenZip(false);
    });
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <div>
      {base64.length == 0 ? (
        <Backdrop className={classespro.backdrop} open={open}>
          <CircularProgress size={44} className={classespro.buttonProgress} />
        </Backdrop>
      ) : (
        <React.Fragment>
          <AppBar
            position="absolute"
            color="default"
            className={classes.appBar}
          >
            <Toolbar>
              <img src={logo} alt="logo" width="240" height="70" />
            </Toolbar>
          </AppBar>
          <CssBaseline>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <div>
                  <Document
                    error="Error al intentar cargar el PDF"
                    loading="Cargando..."
                    file={`data: application / pdf; base64, ${base64} `}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      scale={1}
                      height={pdfHeigth}
                      width={pdfWidth}
                      renderTextLayer={false}
                      pageNumber={pageNumber}
                    />
                  </Document>
                  <div className="text-center">
                    <p>
                      Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                      {numPages || "--"}
                    </p>
                    <button
                      className="btn btn-dark"
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      Atrás
                    </button>{" "}
                    <button
                      className="btn btn-dark"
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              </Paper>
            </main>
          </CssBaseline>
        </React.Fragment>
      )}

      {/* Dialogo de espera de descarga de paquete de documentos firmados */}
      <div>
        <Dialog
          open={openZip}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Descarga de documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                La carpeta con los documentos firmados se descargaran al lugar
                habitual de Descargas: Espera un poco...
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {" "}
                <CircularProgress
                  size={44}
                  className={classespro.buttonProgress2}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
