/**
 * Store principal de la aplicacion.
 */

const initialState = {
    dashboard: {
        total: 0,
        registrado: 0,
        en_proceso: 0,
        firmados: 0,
        rechazados: 0,
        synced: false,
    }
};

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DASHBOARD": {
            return { ...state, dashboard: { ...action.payload, synced: true } };
        }
        default: {
            return state;
        }
    }
};