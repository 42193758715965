import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
//import ResizableRect from 'react-resizable-rotatable-draggable'
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import ButtonC from "components/CustomButtons/Button.js";

import HelpIcon from "@material-ui/icons/Help";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { makeStyles } from "@material-ui/core/styles";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import LogoFirma from "containers/Firmante/LogoFirma";
import CuadroFirma from "containers/Firmante/CuadroFirma";
import rectangle from "containers/Firmante/rectangle-firma.png";
//list
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import UbicarFirma from "containers/Firmante/newUbicarFirma";

import { green, deepOrange } from "@material-ui/core/colors";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 5,
  },
  button: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(0),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  codigo: {
    marginTop: theme.spacing(-1),
  },
  formControl: {
    margin: theme.spacing(-1),
    padding: 0,
    marginRight: "-15px",
  },
  container: {
    padding: theme.spacing(2),
  },

  buttonProgress: {
    color: green[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const useStyles2 = makeStyles((theme) => ({
  green: {
    color: "#fff",
    backgroundColor: green["A700"],
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
const useStylestable = makeStyles(styles2);

function Preview(props) {
  const classes2 = useStylestable();
  const classes = useStyles();
  const classesicon = useStyles2();
  const inputRef = useRef();
  const [tokenstate, setTokenstate] = React.useState("");
  //const [personalizada, setPersonalizada] = React.useState(false);
  //const personalizar = () => setPersonalizada(personalizada => !personalizada);
  const {
    user,
    idDocumento,
    nombre,
    topmt,
    idFirmante,
    setIdFirmante,
    tabs,
    setRegistrado,
    setfirma,
    setopenDelete,
    handleInitProcess,
  } = props;

  const [base64, setbase64] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [marginTop, setMarginTop] = React.useState(600);

  const [namefirmante, setNameFirmante] = React.useState("");

  const [displaySign, setDisplaySign] = React.useState(false);

  const [datos, setDatos] = React.useState([]);

  const [nuevoFirmante, setNuevoFirmante] = React.useState(true);
  /*arreglo para almacenar el objeto coordenadas*/
  const [coordenadasFirma, setCoordenadasFirma] = React.useState([{}]);
  //almacena la coordenadas de la última posisición de la firma
  const [coordenadas, setCoordenadas] = useState({
    page: 0,
    x: 0,
    y: 0,
    screenX: 0,
    screenY: 0,
    w: 0,
    h: 0,
    top: 0,
    left: 0,
    nombre: "",
    height: 0,
    width: 0,
  });

  //almacena las firmas del firmante actual
  const [ubicacionFirma, setUbicacionFirma] = React.useState([
    {
      page: 0,
      x: 0,
      y: 0,
      screenX: 0,
      screenY: 0,
      w: 0,
      h: 0,
      top: 0,
      left: 0,
      nombre: "",
      height: 0,
      width: 0,
    },
  ]);

  //almacena todas las firmas configuradas en el documento
  const [ubicacionFirmaHistoria, setUbicacionFirmaHistoria] = React.useState(
    []
  );

  React.useEffect(() => {
    console.log("INFOUSER", user);

    //var localY = event.clientY
    registerFirma();
    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
      setMarginTop(400);
    }
    let token = localStorage.getItem("Token");
    setTokenstate(token);

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  if (coordenadas.length >= 10) {
    setfirma(false);
  }
  //FUNCIONES PARA REGISTRAR LA POSICION DE LA FIRMA

  const clickFijar = () => {
    setDisplaySign(false);
    let token = localStorage.getItem("Token");
    // setUbicacionFirma([...ubicacionFirma, coordenadas]);

    ubicacionFirmaHistoria.push(coordenadas);
    console.log("historiaFirma", ubicacionFirmaHistoria);

    //buscar cordenadas por id de firmante que pertenezcan a al firmante que vamos actualizar

    console.log("CORDENADAS", coordenadas);

    var datajs = JSON.stringify({
      signature_coords: ubicacionFirmaHistoria,
    });
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/firmantes/${datos[0].idFirmanteAPI}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: datajs,
    };

    axios(config)
      .then((res) => {
        const data = res.data;

        console.log("modificacion de Gryop.", data);
        // alert("se registro con exito ");
      })
      .catch((error) => {
        alert("El campo signature_coords debe ser un array");
        console.error(error);
      });
  };

  const registerFirma = () => {
    let token = localStorage.getItem("Token");
    var datajs = {};

    datajs = JSON.stringify({
      documentos_id: idDocumento,
      name: user.name,
      last_name: user.name,
      email: user.email,
      phone_country_code: 52,
      phone: user.phone,
      role: "Cliente",
      firmante_involvements_id: 1,
      company: user.company,
      notification_types_id: 1,
      id_required: false,
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/firmantes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: datajs,
    };

    axios(config)
      .then((response) => {
        const dataUser = response.data[1];
        setNameFirmante(dataUser.name.substr(-20, 9) + "...");
        setIdFirmante(dataUser._id);
        setDatos([
          {
            nombre: dataUser.name,
            apellidos: dataUser.name,
            correo: dataUser.email,
            codigo: dataUser.phone_country_code,
            celular: dataUser.phone,
            rol: dataUser.role,
            Compañia: dataUser.company,
            notificacion: dataUser.notification_types_id,
            idFirmanteAPI: dataUser._id,
          },
        ]);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.message) {
          if (
            error.response.data.message ==
            "Se encontró un registro duplicado en Firmantes"
          ) {
            setIdFirmante(error.response.data.id);
            setRegistrado(true);
            setNameFirmante(user.name.substr(-20, 9) + "...");
            setDatos([
              {
                nombre: user.name,
                apellidos: user.name,
                correo: user.email,
                codigo: 52,
                celular: user.phone,
                rol: "Cliente",
                Compañia: user.company,
                notificacion: 1,
                idFirmanteAPI: error.response.data.id,
              },
            ]);
          }
        } else if (error.response.data.errors.email) {
          alert(error.response.data.errors.email);
        } else if (error.response.data.errors.phone) {
          alert(error.response.data.errors.phone);
        } else if (error.response.data.message) {
          alert(error.response.data.message);
        }
      });
  };

  function Firmas() {
    //let result = ubicacionFirma.filter(det => det.pagina = 1);
    //{ubicacionFirma.map((detalle, index) =>
    if (ubicacionFirmaHistoria.length > 1) {
      return (
        <div>
          {ubicacionFirmaHistoria
            .filter((name) => name.page === pageNumber)
            .map((detalle, index) => (
              <LogoFirma
                key={index}
                src={rectangle}
                w={detalle.w - 25}
                h={detalle.h - 25}
                coordenadas={{ x: detalle.screenX + 10, y: detalle.screenY }}
                left={detalle.left}
                bottom={detalle.top}
                texto={detalle.nombre}
                height={detalle.height}
                width={detalle.width}
                mobile={isMobile()}
                topmt={topmt}
              />
            ))}
        </div>
      );
    }
    return <div></div>;
  }

  let disableSiguiente = true;

  if (ubicacionFirmaHistoria.length > 0) {
    disableSiguiente = false;
  }
  return (
    <React.Fragment>
      {console.log("corrdenrnskd", ubicacionFirmaHistoria)}
      <Grid>
        <List dense className={classes.root}>
          {datos.map((value) => {
            const labelId = `checkbox-list-secondary-label-${value}`;

            console.log("DATAFIRMANTES2", value);
            return (
              <ListItem key={value.nombre} button>
                <ListItemAvatar>
                  <Avatar
                    className={
                      value.date_signed ? classesicon.orange : classesicon.green
                    }
                  >
                    {value.nombre
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={labelId} primary={` ${value.nombre} `} />

                <ListItemSecondaryAction>
                  <Grid>
                    <Tooltip
                      id="tooltip-top"
                      title="Fijar Firma"
                      placement="top"
                      classes={{
                        tooltip: classes2.tooltip,
                      }}
                    >
                      <IconButton
                        aria-label="Edit"
                        className={classes2.tableActionButton}
                      >
                        <PhotoSizeSelectSmallIcon
                          className={
                            classes2.tableActionButtonIcon + " " + classes2.edit
                          }
                          onClick={() => {
                            setDisplaySign(true);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip
                      id="tooltip-top"
                      title="Editar Firmante"
                      placement="top"
                      classes={{
                        tooltip: classes2.tooltip,
                      }}
                    >
                      <IconButton
                        aria-label="Edit"
                        className={classes2.tableActionButton}
                      >
                        <EditIcon
                          className={
                            classes2.tableActionButtonIcon + " " + classes2.edit
                          }
                          onClick={() => {
                            // handleOpenEditar(value);
                          }}
                        />
                      </IconButton>
                    </Tooltip> */}
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid justifyContent="center" alignItems="center">
        <UbicarFirma
          idDocumento={idDocumento}
          nombre={namefirmante}
          setCoordenadasFirma={setCoordenadasFirma}
          setNuevoFirmante={setNuevoFirmante}
          nuevoFirmante={nuevoFirmante}
          topmt={topmt}
          displaySign={displaySign}
          setDisplaySign={setDisplaySign}
          idFirmante={idFirmante}
          arrayFirmantes={datos}
          clickFijar={clickFijar}
          setUbicacionFirmaHistoria={setUbicacionFirmaHistoria}
          ubicacionFirmaHistoria={ubicacionFirmaHistoria}
          setCoordenadas={setCoordenadas}
          autoFirma={true}
        />
      </Grid>
      <Firmas />
      <div className={classes.buttons}>
        <ButtonC
          color="danger"
          size="md"
          type="button"
          onClick={() => setopenDelete(true)}
          className={classes.button}
        >
          Eliminar
        </ButtonC>

        <ButtonC
          color="info"
          size="md"
          type="button"
          disabled={disableSiguiente}
          onClick={() => handleInitProcess()}
          className={classes.button}
        >
          Siguiente
        </ButtonC>
      </div>
    </React.Fragment>
  );
}

export default Preview;
