/**
 * Servicio que expone en fachada las llamadas al microservicio de CDO.
 */

import CdoDocumentos from './CdoDocumentos';
import CdoFirmantes from './CdoFirmantes';

const CdoApi = {
    CdoDocumentos,
    CdoFirmantes,
};

export default CdoApi;