import React, { useState, useRef, useEffect } from "react";

function LogoFirma(props) {
  const imgRef = useRef();
  const [mtState, setmtState] = React.useState(870);

  useEffect(() => {
    //console.log("REF:"+imgRef.current.getBoundingClientRect().left)
    console.log("TOPMT", props.topmt);
    if (props.topmt != undefined) {
      setmtState(540);
    }
    if (props.top != undefined) {
      setmtState(props.top);
    }
  });

  var sidebar = 260;
  var mt = mtState;

  //540

  if (props.mobile) {
    sidebar = 0;
    if (props.topmobile != undefined) {
      mt = props.topmobile;
    } else {
      mt = 1150;
    }
  }
  const x = props.left - sidebar + props.coordenadas.x;
  const y = props.coordenadas.y;
  const b = props.bottom + y;

  if (props.mobile) {
    var dif1 = props.height;
  } else {
    var dif1 = props.height;
  }
  const dif = dif1 + y;
  //const dif = props.defy + y
  const top = mt + dif;

  var texto = props.texto;
  if (texto === "") {
    texto = "Firmante";
  }

  return (
    <div>
      <img
        ref={imgRef}
        src={props.src}
        width={props.w}
        height={props.h}
        style={{
          opacity: "50%",
          display: "",
          position: "absolute",
          left: x,
          top: top,
        }}
        //style={{ opacity:'50%',display:'', position: 'absolute', left: x, bottom: -b }}
      />
      <div style={{ display: "", position: "absolute", left: x + 5, top: top }}>
        <font color="white">{texto}</font>
      </div>
    </div>
  );
}

export default LogoFirma;
