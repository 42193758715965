import React from "react";
import routes from "routes.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/fondoblack.jpg";
import logo from "assets/img/ConCertezaGris.png";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar.js";
import Footer from "components/Footer/Footer.js";
import Navbar from "components/Navbars/Navbar.js";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import InfoIcon from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from '@material-ui/core/CircularProgress'
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Snackbar from "components/Snackbar/Snackbar.js";
import moment from "moment";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStyles = makeStyles(styles);
const useStylestable = makeStyles(styles2);


/*
const useStyles = makeStyles((theme) => ({

  appBar: {
    position: "relative",
  },

  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "30%",
      marginRight: "auto",
      marginTop: "13%",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  divacciones: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  input: {
    display: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float:"right",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  footer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
}));
*/

export default function ConsultaLogs() {
  const colorStatistics="#024ebc";
  const classes = useStyles();
  const classes2 = useStylestable();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [color, setColor] = React.useState("blue");
  const [image, setImage] = React.useState(bgImage);
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");
  const [log, setLog] = React.useState("");
  const [modalDetalles, setModalDetalles] = React.useState(false);
  const [detalle, setDetalle] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 9000);
  };

  React.useEffect(() => {
    //usuario temnporal
    let token1 = localStorage.getItem("Token");

    axios
      .get(`${process.env.REACT_APP_URL}api/auth/user`, {
        headers: {
          Authorization: `Bearer ${token1}`,
        },
      })
      .then((res) => {
          console.log("user:",res.data);
      });
    //usuario

    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";

    let fecha = `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
    setDesde(fecha);
    setHasta(fecha);

    //cambiar para produccion
    let token = localStorage.getItem("Token");
    //let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjBmMmE2N2RlNzhhZWQwNjlkYjc3YzM0MjM4MWRkZWMwYmUxZDc0ODk2NjM1ZGZjNjE3NDRlYzg2ZmM2MjZhYjBlMDI3YTUxNjUxODc0ZmIiLCJpYXQiOjE2MTYwMTcxNzksIm5iZiI6MTYxNjAxNzE3OSwiZXhwIjoxNjE2MTAzNTc5LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.lW6yB6bJGDEYeTlVHc-uj54a-qYiTFP7KZtdRJmYqeK6sGrpo-BetaaXJN_ANlom3MRDqhLh0NNDupgDJ_7YutWw6l9-lRr1eXw9FYPXM1CDik5YYaiwI2sDUAOMFoG2PSZUXU9DYFkfHsCvcDG9JsdYnqUzsYQ37SoBWySHfa9HESvX5-2aNdEa7YNPVaDPtd5pMGQp91PFlHxLv7L8qpLs0JKqbsgRh3r7iycXmemJ9UMlDM14Sv3YfZ4uHAbuGtN1SoJzhQZbSe35hnSbyD5Gkeam8i3WtEL36bYTNyh5cSEVMcubERFydtlFfIv5OLqyZNzsobT6yDs6HAQ7zgDI5FDnsINsbVdvWA6Fl0Hja7FP2G4k78_3UpjcMkBTYb8T_VTAdRqM5ouewQD8FBQSvsKAOEd6To6NP6_YImZlwlDIeH1IT0zQ91jnWGsl3qXpNkB0SAKNygHK9ULhIKP6E64ph33415stPIzgkFwPS8XPWDQ32Kprdy_JdTzURMSHwpRLHZBQC14Zcsrwxovyb1wfimFb2fgt2Mp0mH5bgSqkRn_nQ7GwXXNwXzn0oYjaLX6tz49ijyNcEwjsOBhsPDNZZqgBlCrCdZoxE-4oRCJkXoC2_fg6M3DHXEW-KYsU_ovhoSDiWuenFwGU3fZqz7fCCiiRzGh-adk6h-8";
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      //cambiar para produccion
      url: `${process.env.REACT_APP_URL}api/logcapture/recents`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("LOG:",response.data);
        setLog(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  const onChangeDesde = (e) => {
    setDesde(e.target.value);
    console.log("desde", e.target.value); // Works
  };

  const onChangeHasta = (e) => {
    setHasta(e.target.value);
    console.log("hasta", e.target.value); // Works
  };

  const handleFecha = () => {
    showNotificacion(" Consultando datos. Espere un momento.");
    //cambiar para produccion
    let token = localStorage.getItem("Token");

    var config = {
      method: "get",
      //cambir para produccion
      url: `${process.env.REACT_APP_URL}api/logcapture/fechas/${desde}/${hasta}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let pp = response.data;
        setLog(pp)
        console.log("Rango:",pp);
        //setTitulo("INTERVALO PERSONALIZADO ( "+result.length+" )");
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });

    //FIN DOCUMENTOS
  };

  return (
      <React.Fragment>
      <div>
        <Grid container style={{ marginTop: 0 }}>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textPrimary"
                    gutterBottom
                  >
                    <b>Consulta Intervalo personalizado</b>
                  </Typography>
                  <hr />
                  <TextField
                    id="dateDesde"
                    label="Desde"
                    type="date"
                    onChange={onChangeDesde}
                    value={desde}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="dateHasta"
                    label="Hasta"
                    type="date"
                    onChange={onChangeHasta}
                    value={hasta}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="outlined" color="primary" onClick={()=>{
                    setLog("");
                    setLoading(true);
                    handleFecha()
                  }}>
                    VER DETALLE
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12}>
            <Card variant="outlined">
              <CardContent>
              <div style={{ maxWidth: "100%" }}>
              {
                loading ? (
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                  }}>
                    <CircularProgress />
                  </div>
                ):null
              }
              {
                log ? (
                  <MaterialTable
                    localization={localization}
                    options={{
                      exportButton: true,
                      sorting: true,
                      headerStyle: {
                        backgroundColor: colorStatistics,
                        color: "#FFF",
                      },
                      pageSize: 20, // make initial page size
                      pageSizeOptions: [20, 50, 100, 500, 1000],
                      actionsColumnIndex: -1,
                      exportButton: true,
                      actionsColumnIndex: 0,
                    }}
                    columns={[
                      {
                        title: "Fecha",
                        field: "created_at",
                        defaultSort: "desc",
                        render: (row) => (
                          <span>
                            {(row["created_at"])? moment(row["created_at"]).format("DD/MM/YYYY HH:mm:ss"):null}
                          </span>
                        ),
                      },
                      { title: "Actividad", field: "actividad" },
                      { title: "Usuario", field: "user.name" },
                      { title: "Compañía", field: "user.company" },
                      { title: "IP", field: "ip.IPv4" },
                      { title: "País", field: "ip.country_name" },
                      { title: "Ciudad", field: "ip.state" },
                      { title: "Dispositivo", field: "dataBrowser.deviceType" },
                      {
                        title: "Sistema Operativo",
                        field: "dataBrowser.osName"
                      },
                    ]}
                    data={log}
                    title={"Logs ("+log.length+")"}
                    actions={[
                      {
                        icon: () =>
                        <InfoIcon
                        className={
                          classes2.tableActionButtonIcon +
                          " " +
                          classes2.edit
                        }
                        />,
                        tooltip: "Ver más detalles",
                        onClick: (event, rowData) => {
                          console.log(rowData);
                          setDetalle(rowData);
                          setModalDetalles(true);
                        },
                      },
                    ]}
                  />
                ):null
              }
              </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </div>
        <Dialog
          open={modalDetalles}
          keepMounted
          fullScreen={false}
          fullWidth={true}
          maxWidth = {"lg"}
          onClose={() => setModalDetalles(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <b>DETALLES</b>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <div>
            <Grid container style={{ marginTop: 0 }}>
                <Grid item xs={12} sm={6}>
                {
                  detalle ? (
                    <iframe
                      src={`https://maps.google.com/maps?q=${detalle.ip.latitude}, ${detalle.ip.longitude}&z=15&output=embed`}
                      width="98%"
                      height="100%"
                      ></iframe>
                  ):null
                }

                </Grid>
                <Grid item xs={12} sm={6}>
                {
                  detalle ? (
                    <React.Fragment>
                    <Card variant="outlined" style={{ marginTop: 0 }}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                      >

                        <font color={colorStatistics}><b>USUARIO:</b></font>
                      </Typography>
                      <hr />
                      <b>Nombre:</b> {detalle.user.name} <br />
                      <b>Email:</b> {detalle.user.email} <br />
                      <b>Compañía:</b> {detalle.user.company} <br />
                      <b>Actividad:</b> {detalle.actividad} <br />
                      <b>URL:</b> {detalle.dataBrowser.pageon} <br />
                    </CardContent>
                    </Card>

                    <Card variant="outlined" style={{ marginTop: 10 }}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                      >
                        <font color={colorStatistics}><b>CONEXIÓN:</b></font>
                      </Typography>

                      <hr />
                      <b>Fecha:</b> {moment(detalle.created_at).format("DD/MM/YYYY HH:mm:ss")} <br />
                      <b>IP:</b> {detalle.ip.IPv4} <br />
                      <b>País:</b> {detalle.ip.country_name} <br />
                      <b>Estado:</b> {detalle.ip.state} <br />
                      <b>Localidad:</b> {detalle.ip.city} <br />
                      <b>Código Postal:</b> {detalle.ip.postal} <br />
                    </CardContent>
                    </Card>


                    <Card variant="outlined" style={{ marginTop: 10 }}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                      >
                        <font color={colorStatistics}><b>DISPOSITIVO:</b></font>
                      </Typography>
                      <hr />
                      <b>Tipo:</b> {detalle.dataBrowser.deviceType} <br />
                      <b>Navegador:</b> {detalle.dataBrowser.browser} <br />
                      <b>Idioma:</b> {detalle.dataBrowser.browserLanguage} <br />
                      <b>Versión:</b> {detalle.dataBrowser.browserVersion1a} <br />
                      <b>Sistema Operativo:</b> {detalle.dataBrowser.osName} ({detalle.dataBrowser.osVersion}) <br />
                      <b>Zona horaria:</b> GMT-{detalle.dataBrowser.timezone} <br />
                      <b>Móvil:</b> {detalle.dataBrowser.mobileVendor} ({detalle.dataBrowser.mobileModel}) <br />
                      <b>Pantalla:</b> W:{detalle.dataBrowser.sizeScreenH}, H:{detalle.dataBrowser.sizeScreenW} <br />
                    </CardContent>
                    </Card>

                    </React.Fragment>
                  ):null
                }

                </Grid>
            </Grid>
            </div>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => setModalDetalles(false)} color="success">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          place="tc"
          color="info"
          icon={InfoIcon}
          message={messageError}
          open={openAlert}
          closeNotification={() => setOpenAlert(false)}
          close
        />
      </React.Fragment>
  )

}
