import Http from './HttpProvider';

const CdoFirmantes = {
    async obtenerFirmaChallenge() {
        return Http.Fetch('GET', `api/firmas/challenge`, {}, 'application/json', true);
    },
    async validateFirmaChallenge(videoBlob) {
        const formData = new FormData();
        formData.append('speech_video', videoBlob);
        return Http.Fetch('POST', 'api/firmas/validateChallenge', formData, 'multipart/form-data', true);
    }
}

export default CdoFirmantes;