import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { BrowserRouter as Router, useParams } from "react-router-dom";

import axios from "axios";
//imput
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

import Backdrop from "@material-ui/core/Backdrop";
import { MySnackbar } from "components/Snackbar/MySnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "relative",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function NewPassword(props) {
  const { handleNext, success } = props;
  let { idUsuario } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\d)(?=.*[@$!%*;.,?&])[A-Za-z\d@$!%*?;.,&]{6,16}$/;
  const [valuepin, setValuePin] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [snackData, setSnackData] = useState({
    openSnack: false,
    snackError: "",
    snackMessage: "",
    snackSeverity: "",
  });
  const { openSnack, snackError, snackMessage, snackSeverity } = snackData;

  const handleEnd = () => {
    // handleNext();
  };

  const backHome = () => {
    history.push("/login");
  };

  let alertPass = regexPass.test(password) ? "" : "La contraseña no es valida.";

  const alertVerifyPassword =
    password == confirmPass ? "" : "Las contraseñas no coinciden.";

  let disablePin = true;

  if (
    valuepin !== "" &&
    password == confirmPass &&
    regexPass.test(password) == true
  ) {
    disablePin = false;
  }

  const handleChangePassword = () => {
    const url = `${process.env.REACT_APP_URL}api/auth/user/${idUsuario}`;

    var data = {};

    const token = `${process.env.REACT_APP_TOKEN}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    data = {
      password: password,
      password_confirmation: confirmPass,
      pin: valuepin,
    };
    axios
      .put(url, data, config)
      .then(async (response) => {
        console.log("responseStop", response);
        handleNext();
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          //  alert(error.message);
          setSnackData({
            openSnack: true,
            snackError: error,
          });
        }

        console.log("ERROR", error.response);
      });
  };
  const handleCloseSnack = () => {
    setSnackData({
      openSnack: false,
      snackError: "",
      snackMessage: "",
      snackSeverity: "",
    });
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12}>
          <Typography
            variant="span"
            gutterBottom
            style={{ textTransform: "none" }}
            align="center"
          >
            Escriba el código y su nueva contraseña
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            variant="outlined"
            required
            id="pin"
            name="pin"
            label="Código"
            type="number"
            fullWidth
            onChange={(event) => setValuePin(event.target.value)}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
            error={valuepin.length > 3 ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="password"
            label="Contraseña"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            inputProps={{
              maxLength: 15,
            }}
            type="password"
            id="password"
          />
          <FormHelperText style={{ color: "red" }}>{alertPass}</FormHelperText>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="confirmPass"
            label="Confirma tu contraseña"
            type="password"
            onChange={(event) => setConfirmPass(event.target.value)}
            inputProps={{
              maxLength: 12,
            }}
            value={confirmPass}
            id="confirmPass"
            error={alertVerifyPassword}
          />
          <FormHelperText style={{ color: "red" }}>
            {alertVerifyPassword}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" align="center">
            {
              "La contraseña debe tener de 6 a 16 caracteres y contener una mayúscula, una minúscula, símbolos (guión, !@#$&*, etc.) y dos o más números."
            }
          </Typography>
        </Grid>
      </Grid>
      <div>
        <br></br>
      </div>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button
          onClick={handleChangePassword}
          className={classes.button}
          color="primary"
          disabled={disablePin}
          variant="contained"
        >
          Continuar
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={success}>
        <Grid>
          <CircularProgress size={44} className={classes.buttonProgress} />
          <br></br>
          <Typography variant="body2" color="textSecondary" align="center">
            Espera un poco
          </Typography>
        </Grid>
      </Backdrop>
      <MySnackbar
        openAlert={openSnack}
        onClose={handleCloseSnack}
        error={snackError} //Opcional
        message={snackMessage} //Opcional
        severity={snackSeverity} //Opcional
        duration={20000}
      />
    </React.Fragment>
  );
}
