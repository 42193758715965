import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { useSelector } from "react-redux";

import { dashboardRoutes, AdminRoutes, GondiRoutes } from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/fondoblack.jpg";

import logo from "assets/img/ConCertezaGris.png";

let ps;

let uri = localStorage.getItem("uri");

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles

  const { permissions, imgLogo, user } = useSelector((state) => state.auth);

  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [Logocustom, setLogoCustom] = React.useState("");
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    const Admin = permissions.includes("stats.cdo");
    console.log("USERINFO", user);
    if (Admin == true) {
      console.log("El usuario es administrador", Admin);
      setAdmin(true);
    } else if (Admin == false) {
      setAdmin(false);
      console.log("El usuario no es admi", Admin);
    }
    if (user.custom_assets) {
      if (user.custom_assets.logo) {
        setLogoCustom(`data:image/jpeg;base64, ${user.custom_assets.logo}`);
      } else if (user.my_assets.logo) {
        setLogoCustom(`data:image/jpeg;base64, ${user.my_assets.logo}`);
      }
    } else {
      setLogoCustom(logo);
    }
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel, permissions]);

  const switchRoutes = () => {
    if (permissions.includes("user.admin") == true) {
      return (
        <Switch>
          {AdminRoutes.map((prop, key) => {
            if (prop.layout) {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
            return null;
          })}
          <Redirect from="/admin" to={`${uri}/dashboard`} />
        </Switch>
      );
    } else if (permissions.includes("menu.expedientes.view")) {
      return (
        <Switch>
          {GondiRoutes.map((prop, key) => {
            if (prop.layout) {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
            return null;
          })}
        </Switch>
      );
    } else {
      return (
        <Switch>
          {dashboardRoutes.map((prop, key) => {
            if (prop.layout) {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
            return null;
          })}
        </Switch>
      );
    }
  };

  // const handleImageClick = (image) => {
  //   setImage(image);
  // };
  // const handleColorClick = (color) => {
  //   setColor(color);
  // };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={
          permissions.includes("user.admin")
            ? AdminRoutes
            : permissions.includes("menu.expedientes.view")
            ? GondiRoutes
            : dashboardRoutes
        }
        logo={Logocustom}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={
            permissions.includes("user.admin")
              ? AdminRoutes
              : permissions.includes("menu.expedientes.view")
              ? GondiRoutes
              : dashboardRoutes
          }
          handleDrawerToggle={handleDrawerToggle}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes()}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes()}</div>
        )}
        {getRoute() ? <Footer /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
