import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
} from "assets/jss/material-dashboard-react.js";
const cardHeaderStyle = {
  cardHeader: {
    borderRadius: "1px",
    padding: "1rem 10px",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "-20px",
    border: "0",
    marginBottom: "0",
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
};

export default cardHeaderStyle;
