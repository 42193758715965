import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AddressFormConfirm from "./Confirmacion";
import Review from "../ReviewPDF/ReviewPDF";
import logo from "assets/img/ConCertezaAzul.png";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { BrowserRouter as Router, useParams } from "react-router-dom";
import { MySnackbar } from "components/Snackbar/MySnackbar";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered "}
      <Link color="inherit" href="https://certificaciondigital.online/">
        by CDO
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    padding: theme.spacing(1),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ConfirmacionContainer() {
  const classes = useStyles();
  let { idfirma } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [idDocumento, setidDocumento] = useState("");
  const [success, setSuccess] = useState(false);

  const [snackData, setSnackData] = useState({
    openSnack: false,
    snackError: "",
    snackMessage: "",
    snackSeverity: "",
  });
  const { openSnack, snackError, snackMessage, snackSeverity } = snackData;
  const history = useHistory();

  const steps = ["Confirme su firma en el documento", "Descargue su documento"];

  const handleCloseSnack = () => {
    setSnackData({
      openSnack: false,
      snackError: "",
      snackMessage: "",
      snackSeverity: "",
    });
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const setFirmado = () => {
    handleNext();
    setSuccess(false);
  };
  const handlefinalizar = () => {
    history.push("/login");
  };

  const handleConfirmar = async (valuepin) => {
    setSuccess(true);
    const token = `${process.env.REACT_APP_TOKEN}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const urlInit = `${process.env.REACT_APP_URL}api/firmas/${idfirma}/confirm?pin=${valuepin}`;
    console.log("ULRINIT CON ID", urlInit);
    axios
      .put(urlInit, {}, config)
      .then(async (response) => {
        console.log("RES DE CONFIRM", response);
        let data = response.data[0];
        console.log("RES DATA", data);
        console.log("dataID documento", data.documentos_id);
        const idDocumentofirmado = data.documentos_id;
        console.log("iddocumetovar", idDocumentofirmado);
        await setidDocumento(idDocumentofirmado);
        setTimeout(setFirmado, 1500);
      })
      .catch((error) => {
        // Error 😨
        setSuccess(false);

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          setSnackData({
            openSnack: true,
            snackError: error,
          });
          console.log(error.response.data.message);
          // alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddressFormConfirm
            setFirmado={setFirmado}
            handleConfirmar={handleConfirmar}
            success={success}
          />
        );
      case 1:
        return <Review idDocumento={idDocumento} setFirmado={setFirmado} />;
      default:
        throw new Error("Unknown step");
    }
  };

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="logo" width="220" height="60" />
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {activeStep !== 1 && activeStep !== activeStep.length && (
            <Typography component="h1" variant="h4" align="center">
              Confirmación de firma.
            </Typography>
          )}
          {/* <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="subtitle1">
                  Su documento ha sido firmado con exito. Se le enviara una
                  notificacion a su correo para que pueda consultar el
                  documento.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlefinalizar}
                  className={classes.button}
                >
                  Finalizar
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                {activeStep === steps.length - 2 ? (
                  ""
                ) : activeStep === steps.length - 1 ? (
                  ""
                ) : (
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Finalizar"
                        : activeStep === steps.length - 2
                        ? "Ver documento"
                        : activeStep === steps.length - 3
                        ? "Ver documento"
                        : "siguiente"}
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <MySnackbar
          openAlert={openSnack}
          onClose={handleCloseSnack}
          error={snackError} //Opcional
          message={snackMessage} //Opcional
          severity={snackSeverity} //Opcional
          duration={20000}
        />
        <Copyright />
      </main>
    </React.Fragment>
  );
}
