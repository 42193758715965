/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Previewpdf from "../PreviewPDF/PreviewPDF2";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import LinkIcon from "@material-ui/icons/Link";
import StepConnector from "@material-ui/core/StepConnector";

import iconoQR from "assets/img/iconoQRCDO.jpeg";

//import logo from "../Logo.png";
import logo from "assets/img/ConCertezaAzul.png";
import PaymentForm from "../Firma/Firma";
import bandeja from "assets/img/bandeja.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import cancelar from "assets/img/cancelar.png";
import confirmacion from "assets/img/confirmation.svg";
import firmado from "assets/img/Firmaado.svg";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { useParams, withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { whiteColor } from "assets/jss/material-dashboard-react";
import { green } from "@material-ui/core/colors";
import Review from "../ReviewPDF/ReviewPDF";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import QRCode from "qrcode.react";
import DetallesProceso from "containers/Firma/Detalles";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import GestureIcon from "@material-ui/icons/Gesture";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import DevicesIcon from "@material-ui/icons/Devices";
import Tooltip from "@material-ui/core/Tooltip";
import TestVoice from "containers/testVoice/testvoice";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MySnackbar } from "components/Snackbar/MySnackbar";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered"}
      <Link color="inherit" href="https://certificaciondigital.online/">
        {" "}
        by CDO
      </Link>{" "}
    </Typography>
  );
}
function CodigoQR() {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          float: "left",
          marginTop: "0px",
        }}
      >
        <div style={{ float: "left" }}>
          <font size="1">Firma desde otro dispositivo</font>
          <br />
          <QRCode
            id="qr-gen"
            value={window.location.href}
            size={225}
            level={"H"}
            includeMargin={true}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    padding: theme.spacing(1),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  button2: {
    backgroundColor: green[500],
    color: whiteColor,
    alignItems: "center",
    top: "50%",
    left: "20%",
    marginTop: 12,
    marginLeft: 12,
  },
  buttonProgress: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: "center",
  },
  // goBack: {
  //   // color: theme.palette.common.white,
  //   backgroundColor: deepOrange[500],
  //   "&:hover": {
  //     backgroundColor: deepOrange[600],
  //   },
  // },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

const FirmaURL = () => {
  const history = useHistory();

  const classes = useStyles();
  // const history = useHistory();
  let { idfirmante, idDoc, isLogged = false } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [openComemt, setOpenComent] = useState(false);
  const [comment, setComment] = useState("");
  const [idFirma, setidFirma] = useState("");
  const [progrees, setProgrees] = useState(false);
  const [valuepin, setValuePin] = useState("");
  const [success, setSuccess] = useState(false);
  const [idDocumento, setidDocumento] = useState("");
  const [nombreFirmante, setNombreFirmante] = useState("");
  const [coordenadas, setCoordenadas] = useState("");
  const [dialogoInfo, setDialogoInfo] = useState(false);
  const [dialogoQR, setDialogoQR] = useState(false);
  const [speechRequired, setSpeechRequired] = useState(false);
  const [documento, setDocumento] = useState("");
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [numeroRan, setNumeroRan] = useState("");
  const [DialogNavegator, setDialogNavegator] = useState(false);
  const [snackData, setSnackData] = useState({
    openSnack: false,
    snackError: "",
    snackMessage: "",
    snackSeverity: "",
  });
  const { openSnack, snackError, snackMessage, snackSeverity } = snackData;

  const handleCloseSnack = () => {
    setSnackData({
      openSnack: false,
      snackError: "",
      snackMessage: "",
      snackSeverity: "",
    });
  };

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    document.body.style.overflow = "auto";
    const token = `${process.env.REACT_APP_TOKEN}`;

    //datos del firmante

    //fin datos firmante

    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("resInfoDocu", res.data.status.name);
        console.log("Documento:", res.data);
        setDocumento(res.data);
        if (res.data.status.name === "firmado") {
          setActiveStep(5);
        } else {
          infoFirmante();
        }
        if (res.data.status.name === "rechazado") {
          setActiveStep(7);
        }
        if (res.data.stopped_at) {
          setActiveStep(6);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          setActiveStep(6);
        }
      });
  }, []);

  var steps = ["Revisa el documento", "Firma el documento"];

  if (speechRequired == true) {
    steps = ["Revisa el documento", "Prueba de vida", "Firma el documento"];
  }

  const infoFirmante = () => {
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("FIRMANTE:", res.data.speech_required);
        const firmas = res.data.firmas;
        setSpeechRequired(res.data.speech_required);
        if (res.data.speech_required == true) {
          handleValidarNav();
        }
        setCoordenadas(res.data.signature_coords);
        setNombreFirmante(res.data.name + " " + res.data.last_name);
        if (firmas.length >= 1 && firmas[0].date_confirmed == undefined) {
          setActiveStep(8);
        } else if (firmas[0].date_confirmed.length > 1) {
          setidDocumento(idDoc);
          setTimeout(function () {
            setActiveStep(4);
          }, 2000);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleValidarNav = () => {
    if (
      navegador() == "Chrome" ||
      navegador() == "Edge" ||
      navegador() == "Safari"
    ) {
      setDialogNavegator(false);
    } else {
      setDialogNavegator(true);
    }
  };

  function navegador() {
    var agente = window.navigator.userAgent;
    var navegadores = [
      "Chrome",
      "Firefox",
      "Safari",
      "Opera",
      "Trident",
      "MSIE",
      "Edge",
    ];
    for (var i in navegadores) {
      if (agente.indexOf(navegadores[i]) != -1) {
        return navegadores[i];
      }
    }
  }

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    let icons = {};
    if (speechRequired == true) {
      icons = {
        1: <FindInPageIcon />,
        2: <CameraAltIcon />,
        3: <GestureIcon />,
      };
    } else {
      icons = {
        1: <FindInPageIcon />,
        2: <GestureIcon />,
      };
    }
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? <Check /> : icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  const handleInput = (event) => {
    setValuePin(event.target.value);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const closeComment = () => {
    setOpenComent(false);
  };

  function confirmarSinCodigo(firma, pin) {
    setSuccess(true);
    const token = `${process.env.REACT_APP_TOKEN}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const urlInit = `${process.env.REACT_APP_URL}api/firmas/${firma}/confirm?pin=${pin}`;
    console.log("ULRINIT CON ID", urlInit);
    axios
      .put(urlInit, {}, config)
      .then(async (response) => {
        //Guardar log confirmar
        //buscar datos firmante
        const token = `${process.env.REACT_APP_TOKEN}`;
        axios
          .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            saveLog(
              token,
              res.data.name + " " + res.data.last_name + " confirmó " + idDoc
            );
            saveLogCapture(
              token,
              res.data.name + " " + res.data.last_name + " confirmó " + idDoc
            );
          })
          .catch((error) => {
            console.error(error);
          });
        //fin log

        await setidDocumento(idDoc);
        //setTimeout(handleNext, 1500);
        setTimeout(function () {
          setActiveStep(4);
          setSuccess(false);
        }, 6000);
      })
      .catch((error) => {
        // Error 😨
        setSuccess(false);

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data.message);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }
  const getlink = () => {
    alert("Se copio en el portapapeles");
  };

  const handleConfirmar = async () => {
    setSuccess(true);
    const token = `${process.env.REACT_APP_TOKEN}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const urlInit = `${process.env.REACT_APP_URL}api/firmas/${idFirma}/confirm?pin=${valuepin}`;
    console.log("ULRINIT CON ID", urlInit);
    axios
      .put(urlInit, {}, config)
      .then(async (response) => {
        //Guardar log confirmar
        //buscar datos firmante
        const token = `${process.env.REACT_APP_TOKEN}`;
        axios
          .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            saveLog(
              token,
              res.data.name + " " + res.data.last_name + " confirmó " + idDoc
            );
            saveLogCapture(
              token,
              res.data.name + " " + res.data.last_name + " confirmó " + idDoc
            );
          })
          .catch((error) => {
            console.error(error);
          });
        //fin log

        setidDocumento(idDoc);

        setActiveStep(4);
        setSuccess(false);
      })
      .catch((error) => {
        // Error 😨
        setSuccess(false);

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data.message);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const imageB64 = async (firma) => {
    const url = `${process.env.REACT_APP_URL}api/firmas`;
    const token = `${process.env.REACT_APP_TOKEN}`;
    let base64 = firma;

    const data = new FormData();
    //validacion  de la data cuando es requerido la prueba de vida, video y texto
    if (speechRequired == true) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      data.append("speech_filename", blob);
      data.append("firmantes_id", idfirmante);
      data.append("graphic_signature", base64);
      data.append("speech_text", numeroRan);
    } else {
      data.append("firmantes_id", idfirmante);
      data.append("graphic_signature", base64);
    }

    console.log("datafirma", data);
    setProgrees(true);
    await axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //Guardar log Registró Grafo firma
        //buscar datos firmante
        const token = `${process.env.REACT_APP_TOKEN}`;
        axios
          .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            saveLog(
              token,
              res.data.name +
                " " +
                res.data.last_name +
                " registró grafo " +
                idDoc
            );
            saveLogCapture(
              token,
              res.data.name +
                " " +
                res.data.last_name +
                " registró grafo " +
                idDoc
            );
          })
          .catch((error) => {
            console.error(error);
          });
        //fin log
        console.log("successds", response.data);
        let datares = response.data[1];
        console.log("success", datares._id);
        setidFirma(datares._id);
        //aqui verifica si el documento es sin confirmación
        let pin = datares.pin;
        let firma = datares._id;
        console.log("TIPO:", documento.type.id);
        if (documento.type.id === 8) {
          console.log("CONFIRMAR");
          confirmarSinCodigo(firma, pin);
        } else {
          handleNext();
        }

        setProgrees(false);
      })
      .catch((err) => {
        setProgrees(false);
        console.log("Error servidor", err.response.data.message);
        alert(err.response.data.message);
      });
  };

  const openDialogcomment = () => {
    setOpenComent(true);
  };
  const handleInputComment = (event) => {
    setComment(event.target.value);
    console.log("comentario", comment);
  };

  function handleAlerta() {
    console.log("Alerta");
    const token = `${process.env.REACT_APP_TOKEN}`;
    const data = new FormData();
    data.append("uid", documento.uid);
    data.append("asunto", "Rechazo de firma: " + documento.filename);
    data.append(
      "contenido",
      "El documento <u>" +
        documento.filename +
        "</u> (id:" +
        documento._id +
        ") ha sido rechazado por <u>" +
        nombreFirmante +
        "</u>. Motivo: <u>" +
        comment +
        "</u>."
    );
    data.append("severidad", 1);
    data.append("estatus", 1);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/alertas`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          console.log("Alerta rechazo:", response.data);
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  const handleRechazar = () => {
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .put(
        `${process.env.REACT_APP_URL}api/firmantes/${idfirmante}/refuse?comment=${comment}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSnackData({
          openSnack: true,
          snackMessage: "Ha rechazado firmar el presente documento",
          snackSeverity: "success",
        });
        // alert("acaba de rechazar el firmado del documento");
        handleAlerta();
        setActiveStep(14);
        closeComment();
      });
  };
  const getStepContent = (step) => {
    if (speechRequired == true) {
      switch (step) {
        case 0:
          {
            /*return <Previewpdf coordenadas={coordenadas} />;*/
          }
          return <Previewpdf />;
        case 1:
          return (
            <TestVoice
              recordedChunks={recordedChunks}
              setRecordedChunks={setRecordedChunks}
              numeroRan={numeroRan}
              setNumeroRan={setNumeroRan}
              handleNext={handleNext}
            />
          );

        case 2:
          return (
            <PaymentForm
              isLogged={isLogged}
              handleNext={handleNext}
              imageB64={imageB64}
              progrees={progrees}
              tipodoc={documento.type.id}
            />
          );
        default:
          throw new Error("Unknown step");
      }
    } else {
      switch (step) {
        case 0:
          {
            /*return <Previewpdf coordenadas={coordenadas} />;*/
          }
          return <Previewpdf />;
        case 1:
          return (
            <PaymentForm
              isLogged={isLogged}
              handleNext={handleNext}
              imageB64={imageB64}
              progrees={progrees}
              tipodoc={documento.type.id}
            />
          );
        default:
          throw new Error("Unknown step");
      }
    }
  };

  let disableform = true;
  if (comment.length >= 5) {
    disableform = false;
  }
  let disablePin = true;

  if (valuepin.length > 3) {
    disablePin = false;
  }

  return (
    <div>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <a href="https://certificaciondigital.online/" target="_blank">
            <img src={logo} alt="logo" width="200" height="50" />
          </a>
          {user.my_assets && (
            <>
              <h5 className={classes.title}></h5>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                Regresar
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {/*BOTONES*/}
          {activeStep === 0 ? (
            <div className={classes.buttons}>
              {activeStep === 0 && (
                <React.Fragment>
                  <Tooltip title="Da clic para obtener un código QR y firma desde otro dispositivo.">
                    <Button
                      onClick={() => {
                        setDialogoQR(true);
                      }}
                      //color="secondary"
                      className={classes.button}
                      startIcon={
                        <img
                          src={iconoQR}
                          alt="Con-Certeza"
                          width="37"
                          height="37"
                        />
                      }
                      style={{ backgroundColor: "#00acc1", color: "white" }}
                    >
                      Escanea
                    </Button>
                  </Tooltip>
                  <Button
                    onClick={() => {
                      setDialogoInfo(true);
                    }}
                    //color="secondary"
                    className={classes.button}
                    style={{ backgroundColor: "#00acc1", color: "white" }}
                  >
                    {isMobile ? (
                      <font size="1">info</font>
                    ) : (
                      "Información adicional"
                    )}
                  </Button>
                  <Button
                    onClick={openDialogcomment}
                    //color="secondary"
                    className={classes.button}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    {isMobile ? <font size="1">rechazar</font> : "rechazar"}
                  </Button>
                </React.Fragment>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {speechRequired == true ? "Siguiente" : "firmar"}
              </Button>
            </div>
          ) : null}
          {/*FIN BOTONES*/}
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  ¡Gracias por utilizar nuestra plataforma!
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: "none" }}
                >
                  Se registró con éxito tu firma. Para culminar el proceso
                  deberás registrar el código de confirmación que te será
                  enviado.
                </Typography>
                <div align="center">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="pin"
                      name="pin"
                      label="Código"
                      type="number"
                      fullWidth
                      autoComplete="given-name"
                      onChange={handleInput}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      }}
                      error={valuepin.length > 3 ? false : true}
                    />
                  </Grid>
                </div>
                <div align="center">
                  <img src={bandeja} alt="logo" width="260" height="220" />
                </div>
                <div align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmar}
                    disabled={disablePin}
                    className={classes.button}
                  >
                    confirmar
                  </Button>
                </div>
              </React.Fragment>
            ) : activeStep === 4 ? (
              <React.Fragment>
                <Review idDocumento={idDocumento} setFirmado={handleNext} />
              </React.Fragment>
            ) : activeStep === 14 ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  La firma del documento ha sido rechazada
                </Typography>
                <Typography variant="subtitle1">
                  No se generará ninguna versión final firmada del documento y
                  por tal motivo, no se requiere acción adicional de su parte.
                  <br />
                  Con-Certeza agradece su participación.
                  <br />
                  <a
                    href="https://certificaciondigital.online/"
                    target="_blank"
                  >
                    https://certificaciondigital.online/
                  </a>{" "}
                </Typography>
                <div align="center">
                  <img src={cancelar} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : activeStep === 5 ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom align={"center"}>
                  El documento ha sido firmado
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: "none" }}
                  align={"justify"}
                >
                  {/*El documento que desea consultar ya ha sido firmado.*/}
                  {documento.type.id === 8 ? (
                    <React.Fragment>
                      Has firmado con éxito el documento. Te notificaremos
                      cuando las demás personas involucradas hayan firmado y
                      puedas descargar tu ejemplar original.
                      <br />
                      <br />
                    </React.Fragment>
                  ) : null}
                  Con-Certeza agradece su participación.
                  <br />
                  <a
                    href="https://certificaciondigital.online/"
                    target="_blank"
                  >
                    https://certificaciondigital.online/
                  </a>{" "}
                </Typography>
                <div align="center">
                  <img src={firmado} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : activeStep === 6 ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom align={"center"}>
                  El documento solicitado no esta disponible.
                </Typography>
                <Typography
                  variant="subtitle1"
                  align={"center"}
                  style={{ textTransform: "none" }}
                >
                  El documento que desea consultar ha sido eliminado o detenido.
                  Por favor contacta al emisor del documento.
                  <br />
                  <a
                    href="https://certificaciondigital.online/"
                    target="_blank"
                  >
                    https://certificaciondigital.online/
                  </a>{" "}
                </Typography>
                <div align="center">
                  <img src={cancelar} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : activeStep === 7 ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom align={"center"}>
                  El documento solicitado fue rechazado.
                </Typography>
                <Typography
                  variant="subtitle1"
                  align={"center"}
                  style={{ textTransform: "none" }}
                >
                  El documento que desea consultar ha sido rechazado y no esta
                  disponible para firma. Por favor contacta al emisor del
                  documento.
                  <br />
                  Con-Certeza agradece su participación.
                  <br />
                  <a
                    href="https://certificaciondigital.online/"
                    target="_blank"
                  >
                    https://certificaciondigital.online/
                  </a>{" "}
                </Typography>
                <div align="center">
                  <img src={cancelar} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : activeStep === 8 ? (
              <React.Fragment>
                <Typography
                  variant="subtitle1"
                  align={"center"}
                  style={{ textTransform: "none" }}
                >
                  Ya ha plasmado su firma en el documento, ahora deberá
                  registrar el código de confirmación el cual será proporcionado
                  vía correo electrónico o mensaje de texto (SMS).
                  <br />
                  Con-Certeza agradece su participación.
                  <br />
                  <a
                    href="https://certificaciondigital.online/"
                    target="_blank"
                  >
                    https://certificaciondigital.online/
                  </a>{" "}
                </Typography>
                <div align="center">
                  <img src={confirmacion} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                {activeStep === steps.length - 1 ? (
                  ""
                ) : (
                  <div className={classes.buttons}>
                    {activeStep === 0 && (
                      <React.Fragment>
                        <Button
                          onClick={() => {
                            setDialogoInfo(true);
                          }}
                          //color="secondary"
                          className={classes.button}
                          style={{ backgroundColor: "#00acc1", color: "white" }}
                        >
                          {isMobile ? (
                            <font size="1">Información adicional</font>
                          ) : (
                            "Información adicional"
                          )}
                        </Button>
                        <Button
                          onClick={openDialogcomment}
                          //color="secondary"
                          className={classes.button}
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          {isMobile ? (
                            <font size="1">rechazar</font>
                          ) : (
                            "rechazar"
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Mandar a firma"
                        : activeStep === steps.length - 2
                        ? "Firmssar"
                        : activeStep === steps.length - 3
                        ? "Firmar"
                        : "siguiente"}
                    </Button> */}
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>

        <Copyright />
      </div>
      <div>
        <Dialog
          open={openComemt}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Escriba el motivo de rechazo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <TextField
                  id="comment"
                  label="Comentario"
                  multiline
                  rows={4}
                  variant="outlined"
                  onChange={handleInputComment}
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeComment}
              variant="contained"
              color="secondary"
            >
              Cerrar
            </Button>
            <Button
              onClick={handleRechazar}
              variant="contained"
              color="primary"
              disabled={disableform}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop className={classes.backdrop} open={success}>
        <Grid>
          <CircularProgress size={44} className={classes.buttonProgress} />
          <br></br>
          <Typography variant="body2" color="textSecondary" align="center">
            Espera un poco
          </Typography>
        </Grid>
      </Backdrop>
      {/*DIALOGO INFORMACIÓN DEL PROCESO*/}
      <Dialog
        open={dialogoInfo}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DetallesProceso />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogoInfo(false);
            }}
            color="success"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogoQR}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Escanea el código QR"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <CodigoQR />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setDialogoQR(false);
            }}
            style={{ backgroundColor: "#00acc1", color: "white" }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*FIN DIALOGO INFO*/}

      {/* Dialogo para validar las pruebas de vida y compatibilidad de navegador */}
      <Dialog
        open={DialogNavegator}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"El navegador no es compatible para realizar Prueba de vida"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <DialogContentText id="alert-dialog-description">
              Puede ingresar desde los navegadores: Chome, Edge, Chrome Android.
            </DialogContentText>
            <CopyToClipboard text={window.location.href}>
              <Button
                variant="contained"
                startIcon={<LinkIcon />}
                onClick={() => {
                  getlink();
                }}
                style={{ backgroundColor: "#00acc1", color: "white" }}
              >
                Copiar URL
              </Button>
            </CopyToClipboard>
          </Grid>
        </DialogContent>
      </Dialog>
      <MySnackbar
        openAlert={openSnack}
        onClose={handleCloseSnack}
        error={snackError} //Opcional
        message={snackMessage} //Opcional
        severity={snackSeverity} //Opcional
        duration={10000}
      />
    </div>
  );
};
export default withRouter(FirmaURL);
