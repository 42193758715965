import React from "react";
import ApexCharts from 'apexcharts';
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Ejemplo(props){
const [firmados, setFirmados] = React.useState(0);
const [proceso, setProceso] = React.useState(0);
const [rechazados, setRechazados] = React.useState(0);
const [registrados, setRegistrados] = React.useState(0);
const [loading, setLoading] = React.useState(true);


  const grafica = (uno,dos,tres,cuatro,cinco) => {
    var options = {
        series: [{
        name: 'Últimos 7 días',
        data: [tres[0], dos[0], cuatro[0], uno[0], cinco[0]]
      }, {
        name: 'Últimos 30 días',
        data: [tres[1], dos[1], cuatro[1], uno[1],cinco[1]]
      }, {
        name: 'Año en curso',
        data: [tres[2], dos[2], cuatro[2], uno[2],cinco[2]]
      }, {
        name: 'Todos',
        data: [tres[3], dos[3], cuatro[3], uno[3],cinco[3]]
      }],
        chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: 'Documentos por Estatus'
      },
      xaxis: {
        categories: ["Firmados", "En Proceso", "Rechazados", "Registrados","Conservados"],

        labels: {
          formatter: function (val) {
            //return val + ""
          }
        }

      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ""
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
      };

      var chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();
  }

  React.useEffect(() => {
      registra();
    }, []);

async function registra() {
  var uno = await total(1); //registrados
  var dos = await total(2); //proceso
  var tres = await total(3); //firmados
  var cuatro = await total(4); //rechazados
  var cinco = await total(5); //conservados
  //var siete = await total(7); //eliminados
  await grafica(uno,dos,tres,cuatro,cinco);
  setLoading(false);
  props.setMostrarBotones(true);

}

function total(status){
  let token = localStorage.getItem("Token");
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_URL}api/documentos/status/${status}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(function (resolve, reject) {
    axios(config).then(
      (response) => {
        let arr = response.data;
        console.log("DOCUMENTOS", arr);
        let newDate = new Date();
        let year = newDate.getFullYear();
        var startDate = new Date(year, 0, 1);
        var endDate = new Date();
        var thirtyDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
        var sevenDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 7);
        var oneDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 1);

        var resultYear = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= startDate &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultMonth = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= thirtyDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultWeek = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= sevenDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        var resultToday = arr.filter(function (a) {
          return (
            new Date(a.created_at) >= oneDaysAgo &&
            new Date(a.created_at) <= endDate
          );
        });

        var result = [resultWeek.length, resultMonth.length, resultYear.length, arr.length ];
        resolve(result);
      },
      (error) => {
        reject(error);
      }
    );
  });



}


    function totales(status){
      let token = localStorage.getItem("Token");
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_URL}api/documentos/status/${status}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      };


      axios(config)
        .then(function (response) {
          let arr = response.data;
          console.log("DOCUMENTOS", arr);
          let newDate = new Date();
          let year = newDate.getFullYear();
          var startDate = new Date(year, 0, 1);
          var endDate = new Date();
          var thirtyDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
          var sevenDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 7);
          var oneDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 1);

          var resultYear = arr.filter(function (a) {
            return (
              new Date(a.created_at) >= startDate &&
              new Date(a.created_at) <= endDate
            );
          });

          var resultMonth = arr.filter(function (a) {
            return (
              new Date(a.created_at) >= thirtyDaysAgo &&
              new Date(a.created_at) <= endDate
            );
          });

          var resultWeek = arr.filter(function (a) {
            return (
              new Date(a.created_at) >= sevenDaysAgo &&
              new Date(a.created_at) <= endDate
            );
          });

          var resultToday = arr.filter(function (a) {
            return (
              new Date(a.created_at) >= oneDaysAgo &&
              new Date(a.created_at) <= endDate
            );
          });

          var arreglo = [resultWeek.length, resultMonth.length, resultYear.length, arr.length ];

          switch (status) {
            case 1:
              setRegistrados(arreglo);
              console.log("registrados->",arreglo);
              break;
            case 2:
              setProceso(arreglo);
              console.log("proceso->",arreglo);
              break;
            case 3:
              setFirmados(arreglo);
              console.log("firmados->",arreglo);
              break;
            case 4:
              setRechazados(arreglo);
              console.log("rechazados->",arreglo);
              break;
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }

  return (
    <React.Fragment>
    {
      loading ? (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
          <CircularProgress />
        </div>
      ):null
    }
    <div id="chart">
    </div>
    </React.Fragment>
  )
}
