import React from "react";
import ApexCharts from 'apexcharts';
import axios from "axios";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Ejemplo(){
  const [loading, setLoading] = React.useState(true);

const grafica = (categorias,sobre,debajo) => {


            var options = {
            series: [{
            name: 'Documentos firmados sobre el tiempo promedio',
            data:sobre
          },
          {
            name: 'Documentos firmandos debajo del tiempo promedio',
            data:debajo
          }
          ],
            chart: {
            type: 'bar',
            height: 440,
            stacked: true
          },
          colors: ['#008FFB', '#FF4560'],
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '80%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },

          grid: {
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          yaxis: {
            min: -100,
            max: 100,
            title: {
              // text: 'Age',
            },
          },
          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                return val
              }
            },
            y: {
              formatter: function (val) {
                return Math.abs(val) + "%"
              }
            }
          },
          title: {
              text: 'Documentos según tiempo promedio de firma'
          },
          xaxis: {
            //categories: ['84+', '80-84', '75-79', '70-74', '65-69', '60-64'],
            categories: categorias,
            title: {
              text: 'Porcentaje'
            },

            labels: {
              formatter: function (val) {
                return Math.abs(Math.round(val)) + "%"
              }
            }

          },
          };
          var chart = new ApexCharts(document.querySelector("#chart"), options);
          chart.render();
      }

      React.useEffect(() => {
        let token = localStorage.getItem("Token");
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_URL}api/documentos`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        };
        axios(config)
          .then(function (response) {
            let arr = response.data;
            var date = new Date();
            var firstDay,lastDay;
            var mes = [];

            for (var i = 6; i >=0; i--) {
              var cont=0,sum=0;
              var monthname="";
              firstDay = new Date(date.getFullYear(), date.getMonth()-i, 1);
              lastDay =  new Date(date.getFullYear(), (date.getMonth()-i) + 1, 0);
              monthname=moment(firstDay).format("MMM");
              var resultMonth = arr.filter(function (a) {
                return (
                  new Date(a.created_at) >= firstDay &&
                  new Date(a.created_at) <= lastDay
                );
              });

              //primer recorrido para calcular el promedio
              resultMonth.forEach((item, i) => {
                if(item.status.id===3){
                  const dateOneObj = new Date(item.created_at);
                  var numfirmantes=item.firmantes.length-1;
                  if (numfirmantes>=0){
                    const dateTwoObj = new Date(item.firmantes[numfirmantes].date_signed);
                    const milliseconds = Math.abs(dateTwoObj - dateOneObj);
                    const hours = milliseconds / 36e5;
                    const minutes = Math.floor(milliseconds/1000/60);
                    sum+=hours;
                    cont++;
                  }
                }
              });
              var prom = (sum/cont) ? (sum/cont) : "" ;
              var dias = (prom/24).toFixed(1);
              var numero=cont;

              //segundo recorrido para contar los que estan encima y debajo
              var sobre=0;
              var debajo=0;
              resultMonth.forEach((item, i) => {
                if(item.status.id===3){
                  const dateOneObj = new Date(item.created_at);
                  var numfirmantes=item.firmantes.length-1;
                  if (numfirmantes>=0){
                    const dateTwoObj = new Date(item.firmantes[numfirmantes].date_signed);
                    const milliseconds = Math.abs(dateTwoObj - dateOneObj);
                    const hours = (milliseconds / 36e5)/24;
                    if(hours>=dias){
                        sobre++;
                    }
                    else {
                      debajo++;
                    }
                  }
                }
              });

              var up=((sobre/cont)*100).toFixed(1);
              var down=((-debajo/cont)*100).toFixed(1)
              mes = [...mes,{name:monthname,value:dias,num:numero,up:up,down:down,prom:dias}]
            } //endfor
            console.log("mes->",mes);
            var categorias = [];
            var sobre = [];
            var debajo = [];

            for (var i = 0; i < mes.length; i++) {
              categorias[i]=mes[i].name;
              sobre[i]=mes[i].up;
              debajo[i]=mes[i].down;
            }
            grafica(categorias,sobre,debajo);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
          });

        }, []);

          return (
            <React.Fragment>
            {
              loading ? (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                  <CircularProgress />
                </div>
              ):null
            }
            <div id="chart">
            </div>
            </React.Fragment>
            );

   }
