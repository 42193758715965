/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
//imput
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import clsx from "clsx";
import CardHeader from "components/Card/CardHeader";
import Backdrop from "@material-ui/core/Backdrop";
import InputLabel from "@material-ui/core/InputLabel";

import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
// import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textodias: {
    marginTop: theme.spacing(+2),
  },
  textodiaslabel: {
    marginTop: theme.spacing(+1),
  },
  formControlTitle: {
    textAlign: "center",
    marginTop: 10,
    width: "90%",
  },
  formControl1: {
    marginTop: 10,
    width: "80%",
  },
  formControl2: {
    width: "80%",
  },
}));

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function AddressFormDocument(props) {
  const {
    onChangePDF,
    progress,
    setAsistido,
    asistido,
    descripcion,
    setDescripcion,
    setInicio,
    setTermino,
    setDiasAlert,
    setActiveRender,
    roles,
    corporaciones,
  } = props;
  const { permissions } = useSelector((state) => state.auth);
  const classes = useStyles();
  // Metadata
  const [selectCustomMeta, setSelectCustomMeta] = React.useState("");
  const [customMeta, setCustomMeta] = React.useState([]);
  const [data, setData] = React.useState({});

  ////

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [desde, setDesde] = React.useState("");
  const [hasta, setHasta] = React.useState("");
  const [diasAlerta, setDiasAlerta] = React.useState(0);
  const [alerta, setAlerta] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [selectRol, setSelectRol] = React.useState([]);
  const [idCompany, setIdCompany] = React.useState("");
  const [tipoRoles, setTiporoles] = React.useState({
    codigo: "Seleccione el nombre del remitente",
    name: "Seleccione el nombre del remitente",
  });
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      if (diasAlerta === 0) {
        setDiasAlert(30);
        setDiasAlerta(30);
      } else {
        setDiasAlert(diasAlerta);
      }
    } else {
      setDiasAlert(0);
      setDiasAlerta(0);
    }
  };
  const timer = React.useRef();
  const classespro = useStylesprogrees();

  /// Metadata Functions

  const handleChangeMetadatos = (atributo, event) => {
    setData({
      ...data,
      [atributo]: event.target.value,
    });
    // clg
  };

  console.log(data, "Metadata Completa");

  const handlechangeCustom = (event) => {
    setData({});
    console.log("eventssd", event.target.value);
    const result = selectCustomMeta.filter(
      (item) => item._id == event.target.value
    );
    setCustomMeta(result);
    console.log("resultFlter", result);
  };

  function getCustomMeta() {
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/custommeta`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("MetadatosOLV:", response.data);
        setSelectCustomMeta(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setSelectCustomMeta("");
      });
  }

  const Textos = () => {
    // {
    //   DETALLE: "Escribir detalle",
    //   NOMBRE: "Escribir nombre",
    // }
    let mainData = customMeta[0].fields;
    return (
      <React.Fragment>
        <br />
        <br />
        <Grid container spacing={3} className={classes.formControl2}>
          {Object.keys(mainData).map((_itm, index) => (
            <>
              <TextField
                style={{ marginTop: 5 }}
                fullWidth
                key={index}
                label={_itm}
                //value={data[index]}
                helperText={"* " + mainData[_itm]}
                onChange={(e) => {
                  handleChangeMetadatos(_itm, e);
                  // const { value, id } = e.target;
                  // //const newArray = [...data];
                  // let newObject = [...data];
                  // //newArray[id] = value;
                  // newObject = [...newObject, { name: _itm, value: value }];
                  // setData(newObject);
                }}
                //onBlur={handleChange}
                name="name"
                id={index.toString()}
                variant="outlined"
              />
              <hr />
            </>
          ))}
        </Grid>
      </React.Fragment>
    );
  };
  ////

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  useEffect(() => {
    getCustomMeta();
  }, []);

  useEffect(() => {
    if (corporaciones.length > 0) {
      itemRoles();
    }

    getUser();
    return () => {
      clearTimeout(timer.current);
    };
  }, [corporaciones]);

  const itemRoles = () => {
    var collection = [];

    console.log("skfjs", corporaciones);
    corporaciones.map(function (data) {
      var rolesItem = {
        codigo: data._id,
        name: data.name,
      };

      collection.push(rolesItem);
    });
    console.log("COLLECTION", collection);
    setSelectRol(collection);
  };
  const getUser = () => {
    let token = localStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/auth/user`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log("User:",response.data);
        setUser(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = () => {
    onChangePDF(idCompany, data);

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 4000);
    }
  };

  const handlechangeRoles = (event) => {
    console.log("eventssd", event.target.value);
    setIdCompany(event.target.value);
    let datal = {
      codigo: event.target.value,
      name: event.target.value,
    };
    setTiporoles(datal);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  //const personalizar = () => setAsistido(asistido => !asistido);

  const marcaAsistido = () => {
    if (user.permissions.search("stats.cdo") >= 0) {
      return (
        <Grid item xs={12} sm={12}>
          <label htmlFor="contained-button-file">
            <input
              type="checkBox"
              checked={asistido}
              onClick={() => console.log("Clicked!")}
              onChange={() => setAsistido((asistido) => !asistido)}
            />
            Es un proceso asistido
          </label>
        </Grid>
      );
    }
  };

  return (
    <React.Fragment>
      {/* <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      ></Grid> */}
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      >
        {/*METADATA*/}
        {corporaciones.length > 0 ? (
          <Grid item xs={12} sm={12}>
            {/* {console.log(
              "mpermiso",
              permissions.includes("documento.group-assign")
            )} */}
            <FormControl className={classes.formControl}>
              <InputLabel id="remitente">Compañía del solicitante</InputLabel>

              <Select
                labelId="remitente"
                id="remitenteid"
                value={tipoRoles.codigo}
                onChange={handlechangeRoles}
              >
                <MenuItem value="Seleccione el nombre del remitente" disabled>
                  Selecciona compañía del solicitante
                </MenuItem>
                {selectRol.map(({ codigo, name }, index) => (
                  <MenuItem key={index} value={codigo}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <br></br>
          </Grid>
        ) : (
          ""
        )}
        <Container maxWidth="xs">
          <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <p align="center">
                    <b>Información general del documento</b>
                  </p>
                  <hr />
                </Grid>

                <Grid item xs={12} sm={6}>
                  Descripción:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    maxRows={4}
                    inputProps={{
                      maxlength: 360,
                    }}
                    aria-label="minimum height"
                    rowsMin={2}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={`${descripcion.length}/360`}
                    placeholder=""
                    //style={{ width: "100%" }}
                    onChange={(e) => {
                      console.log("descripcion", e.target.value); // Works
                      setDescripcion(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  Inicio vigencia:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="dateDesde"
                    type="date"
                    onChange={(e) => {
                      console.log("inicio", e.target.value); // Works
                      setInicio(e.target.value);
                      setDesde(e.target.value);

                      if (e.target.value > hasta) {
                        setAlerta(
                          "La fecha de término debe ser superior a la fecha de inicio"
                        );
                      } else {
                        setAlerta("");
                      }
                      var date = new Date(e.target.value);
                      var f = new Date();

                      var hoy = new Date("Y-m-d");
                      if (date.getFullYear() > 2300) {
                        showNotificacion("La fecha de inicio es inválida");
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  Término vigencia:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="dateDesde"
                    type="date"
                    onChange={(e) => {
                      console.log("termino", e.target.value); // Works
                      setTermino(e.target.value);
                      setHasta(e.target.value);

                      if (e.target.value < desde) {
                        //alert("La fecha de término debe ser superior a la fecha de inicio")
                        setAlerta(
                          "La fecha de término debe ser superior a la fecha de inicio"
                        );
                      } else {
                        setAlerta("");
                      }
                      var date = new Date(e.target.value);
                      if (date.getFullYear() > 2300) {
                        showNotificacion("La fecha de término es inválida");
                      }
                    }}
                    //value={desde}
                    //className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                {alerta ? (
                  <Grid item xs={12} sm={12}>
                    <font size="2" color="red">
                      <b>{alerta}</b>
                    </font>
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={12}>
                  <font size="2" color="darkblue">
                    <b>Estos datos son opcionales</b>
                  </font>
                </Grid>

                {hasta ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      className={classes.textodiaslabel}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="default"
                        //inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <font size="2" color="green">
                        Alerta de vencimiento:
                      </font>
                    </Grid>
                    <Grid item xs={12} sm={2} className={classes.textodias}>
                      <TextField
                        //variant="outlined"
                        id="standard-number"
                        type="number"
                        disabled={!checked}
                        value={diasAlerta}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 2,
                          max: 90,
                          style: { fontSize: 15, color: "green" },
                        }}
                        style={{ width: "80%" }}
                        onChange={(e) => {
                          console.log("días", e.target.value); // Works
                          setDiasAlerta(e.target.value);
                          setDiasAlert(e.target.value);
                          if (e.target.value < 2 || e.target.value > 90) {
                            showNotificacion(
                              "La alerta de vencimiento debe comprender de mínimo 2 a máximo 90 días."
                            );
                            setDiasAlerta(30);
                            setDiasAlert(30);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1} className={classes.textodias}>
                      <font size="2" color="green">
                        días
                      </font>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Paper>
          </Box>
        </Container>
        {/*FIN METADATA*/}

        {/*METADATOS CUSTOM*/}
        <br />
        {selectCustomMeta ? (
          <>
            <CardHeader className={classes.formControlTitle} color="info">
              METADATOS ADICIONALES
            </CardHeader>
            <FormControl className={classes.formControl1}>
              <InputLabel id="remitente">Seleccione metadatos</InputLabel>
              <Select
                labelId="custommeta"
                id="custommetaid"
                //value={customMeta._id}
                onChange={handlechangeCustom}
              >
                <MenuItem value="Seleccione los metadatos" disabled>
                  Selecciona metadatos
                </MenuItem>
                {selectCustomMeta.map(({ _id, name }, index) => (
                  <MenuItem key={index} value={_id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {customMeta.length > 0 ? Textos() : null}
          </>
        ) : null}

        {/*FIN METADATOS CUSTOM*/}

        <Grid item xs={12} sm={12}>
          <input
            accept="pdf/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            name="PDF"
            onChange={onChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={loading}
              className={buttonClassname}
            >
              <Typography variant="h6" style={{ textTransform: "none" }}>
                CARGA DOCUMENTO PDF
              </Typography>
            </Button>
          </label>
        </Grid>
      </Grid>
      {user ? marcaAsistido() : null}
      <Backdrop className={classespro.backdrop} open={progress}>
        <CircularProgress size={44} className={classespro.buttonProgress} />
      </Backdrop>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
    </React.Fragment>
  );
}
