import Http from './HttpProvider';

const CdoDocumentos = {
    obtenerDashboard() {
        return Http.Fetch('GET', 'api/documentos/dashboard');
    },
    /**
     * /api/documentos
     * Obtiene los documentos del backend.
     * @param {Number} page 
     * @param {Number} limit 
     */
    async obtenerDocumentos(keyword = '', page = 1, limit = 30) {
        return Http.Fetch('GET', `api/documentos?page=${page}&limit=${limit}&keyword=${keyword}`);
    },
    async obtenerDocumentosStatus(keyword = '', status = 1, page = 1, limit = 30) {
        return Http.Fetch('GET', `api/documentos/status/${status}?page=${page}&limit=${limit}&keyword=${keyword}`);
    },
}

export default CdoDocumentos;