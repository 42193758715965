import React, { Component } from "react";
import AddressForm from "./formFirmantes";
// import handleProgress from "util/HandleProgress";
// import fetchApi from "api/fetchApi";
// import handleNotification from "util/HandleNotification";
// import { isEmptyObject } from "util/Validators";
// import { SEND_DOCUMENT } from "constants/Endpoints";

class formFirmantesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  render() {
    const { idDocumento, senFirmante, handleDelete, idRequired } = this.props;
    return (
      <AddressForm
        idDocumento={idDocumento}
        senFirmante={senFirmante}
        handleDelete={handleDelete}
        idRequired={idRequired}
      />
    );
  }
}

export default formFirmantesContainer;
