import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import Tooltip from '@material-ui/core/Tooltip';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ReactFlow, {
  removeElements,
  addEdge,
  Handle
} from "react-flow-renderer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  button:{
    margin:10,
  },
  botondanger: {
    backgroundColor: "red",
    color:"white"
  },
  botongreen: {
    backgroundColor: "green",
    color:"white"
  },
  botonwhite: {
    backgroundColor: "white",
    color:"gray"
  },
  iconsmall: {
    fontSize:12,
    margin:2,
    marginTop:11, //15 center
    padding:0,
    cursor: "default"
  },
  iconsmall2: {
    fontSize:8,
    margin:2,
    //marginTop:15, //15 center
    //padding:0,
    cursor: "default"
  },
  nombrecatalogo:{
    fontSize:13,
    marginTop:7,
    marginLeft:10,
  },
  nombrearchivo:{
    marginTop:6,
    fontSize:10,
  },
  iconarchivo:{
    marginTop:0,
    fontSize:10,
  },
  paper:{
    width:"95%",
  },
  colapse:{
    marginLeft:20
  },
  p: {
    width: "90%",
    margin: "0",
    lineHeight: "0.7"
  },

  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    width:"100%"
  },


}));

const InputNode = ({ type, data, id }) => {
  const classes = useStyles();
  function nombreGrupo(idgrupo){
    const nombre = data.lista_grupos.find(item => item._id == idgrupo);
    if(nombre){
        return(nombre.name);
    }
    return(null);
  }
  function nombreCatalogo(idcatalogo){
    const nombre = data.lista_catalogos.find(item => item._id == idcatalogo);
    if(nombre){
        return(nombre.name);
    }
    return(null);
  }
  return (
    <>
      <Handle type="target" position="left" />
      {/*
      type: {type}
      <br />
      */}
      <p className={classes.p}><font size="1"><b>Proceso:</b></font></p>
      <p className={classes.p}><font size="1">{data.value}</font></p>
      <hr />
      <p className={classes.p}><font size="1"><b>Grupo responsable:</b></font></p>
      <p className={classes.p}><font size="1">{nombreGrupo(data.workgroup_id)}</font></p>
      <p className={classes.p}>&nbsp;</p>
      <p className={classes.p}></p>
      <p className={classes.p}><font size="1"><b>Catálogos:</b></font></p>
      {data.catalogos && data.catalogos.map((item,key) => (
        <p key={key} className={classes.p}><font size="1"><b>-</b>{nombreCatalogo(item)}</font></p>
      ))}
      <p className={classes.p} align="right" style={{float:"right"}}>
      <Tooltip title="Configurar nodo" placement="top">
        <EditIcon
          className={classes.iconsmall}
          color="primary"
          onClick={()=>{console.log("clic config")}}
        />
      </Tooltip>
      </p>

      <Handle type="source" position="right" id="a" />
    </>
  );
};

const TextAreaNode = ({ type, data }) => {
  return (
    <>
      <Handle type="target" position="left" />
      type: {type}
      <br />
      value:{data.value}
      <Handle type="source" position="right" id="a" />
    </>
  );
};

const BooleanNode = ({ type, data }) => {
  return (
    <>
      <Handle type="target" position="left" />
      type: {type}
      <br />
      value:{data.value == 1 ? "True" : "False"}
      <Handle type="source" position="right" id="a" />
    </>
  );
};

const Input2Node = ({ type, data }) => (
  <>
    <Handle type="target" position="left" />
    type: {type}
    <br />
    value - 1 :{data.value[0]}
    <br />
    value - 2 :{data.value[1]}
    <Handle type="source" position="right" id="a" />
  </>
);

const initialElements = [];

const initialInputFull = (grupos,catalogos)=>{
  return({
    type: "InputNode",
    data: { value: "",workgroup_id:"",catalogos:[],lista_grupos:grupos,lista_catalogos:catalogos},
    style: { border: "1px solid #777", padding: 10, width: 150 },
    position: { x: 250, y: 25 }
  });
}



const initialInput = {
  type: "InputNode",
  data: { value: "",workgroup_id:"",catalogos:[]},
  style: { border: "1px solid #777", padding: 10, width: 150 },
  position: { x: 250, y: 25 }
};

const initialTextArea = {
  type: "TextAreaNode",
  data: { value: "" },
  style: { border: "1px solid #777", padding: 10, width: 150 },
  position: { x: 250, y: 25 }
};

const initialBoolean = {
  type: "BooleanNode",
  data: { value: true },
  style: { border: "1px solid #777", padding: 10, width: 150 },
  position: { x: 250, y: 25 }
};

const initialInput2 = {
  type: "Input2Node",
  data: { value: ["", ""] },
  style: { border: "1px solid #777", padding: 10, width: 150 },
  position: { x: 250, y: 25 }
};

const nodeTypes = {
  InputNode: InputNode,
  TextAreaNode: TextAreaNode,
  BooleanNode: BooleanNode,
  Input2Node: Input2Node
};

export default function NewFlow(props) {
  const classes = useStyles();
  const [textoFlujo, setTextoFlujo] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [idGrupo, setIdGrupo] = useState("");
  const [catalogos, setCatalogos] = React.useState("");
  const [idCatalogo, setIdCatalogo] = useState("");
  const [grupos, setGrupos] = React.useState("");
  const [nodosFujo, setNodosFlujo] = React.useState("");
  const [modalConfirmar, setModalConfirmar] = useState(false);

  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setOpenMensaje(false);
  };

  function alerta(texto,severidad){
  setOpenMensaje(true);
  setMensaje(texto);
  setSeverity(severidad);
  }

  const [elements, setElements] = useState(initialElements);
  const [objectEdit, setObjectEdit] = useState({});

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onConnect = (params) =>
    setElements((els) => {
      return addEdge({ ...params, arrowHeadType: "arrowclosed" }, els);
    });

  const onElementClick = (event, object) => {
    console.log(object);
    if(object.type=="InputNode"){
      setIdGrupo(object.data.workgroup_id)
      setObjectEdit(object);
      setModalEditar(true);
    }
  };

  const onPaneClick = () => {
    setObjectEdit({});
  };

async function save(nodo){
  console.log("nodo en save",nodo);
  var dataForm = new FormData();
  dataForm.append('flow_id',nodo.flow_id);
  dataForm.append('workgroup_id',nodo.workgroup_id);
  dataForm.append('name',nodo.name);
  dataForm.append('step_id',nodo.step_id);
  dataForm.append('prev_step',nodo.prev_step);
  for (var i = 0; i < nodo.catalogos.length; i++) {
    dataForm.append('catalogos[]',nodo.catalogos[i]);
  }


  //let token = localStorage.getItem("Token");
  let token = `${process.env.REACT_APP_TOKEN}`;
  var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL_DEV}api/flownodes`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data : dataForm
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al crear nodos","error")
      });

}

async function handleGuardarNodos(){
  console.log("guardar",nodosFujo);

  for (var i = 0; i < nodosFujo.length; i++) {
    await save(nodosFujo[i]);
  }
  setModalConfirmar(false);
  //props.setModalAgregar(false);
  setElements(initialElements);
  props.onClose();
}

  const onSubmit = () => {
    console.log(elements);
    var guardar=true;
    var nodos=[];
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].type=="InputNode"){
        var previo="";
        //validar datos completos
        if(elements[i].data.value=="" || elements[i].data.workgroup_id==""){
          alerta("Datos de los nodos incompletos","error");
          guardar=false;
        }
        //fin validar
        //determinar nodos previos
          for (var j = 0; j < elements.length; j++) {
            if (elements[j].target==elements[i].id){
              previo=elements[j].source;
            }
          }
        //fin determinar
        nodos=[...nodos,
          {
            step_id:elements[i].id,
            name:elements[i].data.value,
            flow_id:props.flujoModificar._id,
            workgroup_id:elements[i].data.workgroup_id,
            catalogos:elements[i].data.catalogos,
            prev_step:previo
          }
        ]
      }
    }
    if(guardar && nodos.length>0){
      setNodosFlujo(nodos);
      setModalConfirmar(true);
    }
    else{
      alerta("El FLujo no contiene nodos","error");
    }
  };

  async function getGrupos(){
    //let token = localStorage.getItem("Token");
    let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL_DEV}api/workgroups`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
      axios(config)
        .then(function (response) {
          //console.log("Grupos:",response.data);
          setGrupos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setGrupos("");
        });
  }

  async function getCatalogos(){
    //let token = localStorage.getItem("Token");
    let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL_DEV}api/catalogos`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
      axios(config)
        .then(function (response) {
          //console.log("Catalogos:",response.data);
          setCatalogos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setCatalogos("");
        });
  }

  function nombreCatalogo(idcatalogo){
    const nombre = catalogos.find(item => item._id == idcatalogo);
    if(nombre){
        return(nombre.name);
    }
    return(null);
  }

  React.useEffect(()=>{
    getGrupos();
    getCatalogos();
  },[]);

  return (
    <div>
    <Typography variant="h6" style={{ textTransform: "none" }}>
      <b>Flujo:</b> {props.flujoModificar.name}
    </Typography>
      <div>
        <div style={{ textAlign: "left", padding: 10 }}>
          <Button
            //className={classes.button}
            variant="contained"
            className={classes.botongreen}
            //color="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            Guardar Flujo
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              //setTextoFlujo(false);
              const id = `${elements.length + 1}`;
              setElements([...elements, { ...initialInputFull(grupos,catalogos), id }]);
            }}
          >
            {textoFlujo? "Nuevo Flujo" : "Agregar Nodo"}
          </Button>
          <hr />

{/*
          <button
            onClick={() => {
              const id = `${elements.length + 1}`;

              setElements([...elements, { ...initialInput, id }]);
            }}
          >
            Add Input
          </button>{" "}
          <button
            onClick={() => {
              const id = `${elements.length + 1}`;

              setElements([...elements, { ...initialTextArea, id }]);
            }}
          >
            Add Text Area
          </button>{" "}
          <button
            onClick={() => {
              const id = `${elements.length + 1}`;

              setElements([...elements, { ...initialBoolean, id }]);
            }}
          >
            Add Boolean
          </button>{" "}
          <button
            onClick={() => {
              const id = `${elements.length + 1}`;

              setElements([...elements, { ...initialInput2, id }]);
            }}
          >
            Add 2 Input
          </button>
          */}
        </div>
      </div>
{/*
      <div style={{ textAlign: "left", padding: 0 }}
        {objectEdit.type === "InputNode" && (
          <React.Fragment>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Ingrese nombre del proceso"
            variant="outlined"
            fullWidth
            value={objectEdit.data.value}
            onChange={(e) => {
              setObjectEdit({
                ...objectEdit,
                data: { ...objectEdit.data, value: e.target.value }
              });

              const newElement = elements.map((item) => {
                if (item.id === objectEdit.id) {
                  return {
                    ...item,
                    data: { ...item.data, value: e.target.value }
                  };
                }
                return item;
              });

              setElements(newElement);
            }}
          />
          </React.Fragment>
        )}

      </div>
      <hr />
      */}
      <div style={{ height: 500 }}>
        <ReactFlow
          elements={elements}
          onElementsRemove={onElementsRemove}
          onConnect={onConnect}
          deleteKeyCode={46} /* 'delete'-key */
          onElementClick={onElementClick}
          onPaneClick={onPaneClick}
          nodeTypes={nodeTypes}
        />
      </div>

      {/*DIALOGO AGREGAR NODOS*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalEditar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Configurar
        </DialogTitle>
        <DialogContent>
        {objectEdit.type === "InputNode" && (
          <React.Fragment>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Ingrese nombre del proceso"
            variant="outlined"
            fullWidth
            value={objectEdit.data.value}
            onChange={(e) => {
              setObjectEdit({
                ...objectEdit,
                data: { ...objectEdit.data, value: e.target.value }
              });

              const newElement = elements.map((item) => {
                if (item.id === objectEdit.id) {
                  return {
                    ...item,
                    data: { ...item.data, value: e.target.value }
                  };
                }
                return item;
              });

              setElements(newElement);
            }}
          />
          </React.Fragment>
        )}

        {grupos.length>0 ?
          (
        <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label">Seleccione grupo</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={idGrupo}
            onChange={(e) => {
              setIdGrupo(e.target.value)
              setObjectEdit({
                ...objectEdit,
                data: { ...objectEdit.data, workgroup_id: e.target.value }
              });

              const newElement = elements.map((item) => {
                if (item.id === objectEdit.id) {
                  return {
                    ...item,
                    data: { ...item.data, workgroup_id: e.target.value}
                  };
                }
                return item;
              });

              setElements(newElement);
            }}
          >
            {grupos.map((item,key) => (
              <MenuItem key={key} value={item._id}>{item.name}</MenuItem>
            ))}
          </Select>
          </FormControl>
        ):null}

        {catalogos.length>0 ?
          (
        <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label">Seleccione catálogo</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={idCatalogo}
            onChange={(e) => {
              setIdCatalogo(e.target.value)
              var arrayCatalogo = [...objectEdit.data.catalogos,e.target.value];
              //console.log("array:",arrayCatalogo);
              setObjectEdit({
                ...objectEdit,
                data: { ...objectEdit.data, catalogos: arrayCatalogo }
              });

              const newElement = elements.map((item) => {
                if (item.id === objectEdit.id) {
                  return {
                    ...item,
                    data: { ...item.data, catalogos: arrayCatalogo }
                  };
                }
                return item;
              });

              setElements(newElement);

            }}
          >
            {catalogos.map((item,key) => (
              <MenuItem key={key} value={item._id}>{item.name}</MenuItem>
            ))}
          </Select>
          {objectEdit.data ?(
            //JSON.stringify(objectEdit.data.catalogos)
            objectEdit.data.catalogos.map((item,key)=>(
                <p key={key} className={classes.p}><font size="1">
                <b>-</b>{nombreCatalogo(item)}</font>
                <Tooltip title="Eliminar catálogo" placement="top">
                  <DeleteIcon
                    className={classes.iconsmall2}
                    color="primary"
                    onClick={()=>{
                      console.log("clic eliminar catalogo",item)
                      const arrayCatalogo = objectEdit.data.catalogos;
                      const index = arrayCatalogo.indexOf(item);
                      if (index > -1) {
                        arrayCatalogo.splice(index, 1);
                      }
                      //console.log(arrayCatalogo);
                      setObjectEdit({
                        ...objectEdit,
                        data: { ...objectEdit.data, catalogos: arrayCatalogo }
                      });

                      const newElement = elements.map((item) => {
                        if (item.id === objectEdit.id) {
                          return {
                            ...item,
                            data: { ...item.data, catalogos: arrayCatalogo }
                          };
                        }
                        return item;
                      });

                      setElements(newElement);
                    }}
                  />
                </Tooltip>
                </p>
            ))
          ):null}
          </FormControl>
        ):null}

        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            setModalEditar(false);
            setIdCatalogo("");
            //setAgregarSiguiente(false);
            //setNombreArchivo("");
          }}
          //className={classes.botondanger}
        >
          Salir
        </Button>
        {/*
          <Button
            variant="contained"
            //disabled={disabledAgregar}
            onClick={() => {
              //handleAgregarArchivo();
            }}
            className={classes.botongreen}
          >
            Agregar
          </Button>
          */}
        </DialogActions>
      </Dialog>
      {/* FIN AGREGAR NODOS*/}

      {/*DIALOGO CONFIRMAR NODO*/}
      <Dialog
        open={modalConfirmar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
            <Typography variant="h6" style={{ textTransform: "none" }}>
              ¿Está seguro de guardar nodos del flujo?<br />
            </Typography>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          onClick={()=>{
            setModalConfirmar(false)
          }}
          className={classes.botondanger}
        >
          Cancelar
        </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleGuardarNodos();
            }}
            className={classes.botongreen}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN CONFIRMAR NODO*/}

      {/*MENSAJE*/}
      <Snackbar
          open={openMensaje}
          autoHideDuration={3000}
          anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
          onClose={handleCloseMensaje}>
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}

    </div>
  );
};
