import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import CardAvatar from "components/Card/CardAvatar.js";
import avatar from "assets/img/avataruser.png";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardContent from "@material-ui/core/CardContent";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GavelIcon from "@material-ui/icons/Gavel";
import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

import stylespro from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

const useStylespro = makeStyles(stylespro);
export default function SectionPills() {
  const classes = useStyles();
  const clasesproduc = useStylespro();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <div className={classes.title}>
            <h2 style={{ fontWeight: "500" }}>
              Sobre Firma Digital Con-Certeza
            </h2>
          </div>
          <br />
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardHeader color="primary">
                  <CardAvatar profile>
                    <img src="https://certificaciondigital.online/wp-content/uploads/2021/01/Certificacion-Digital-Online-CDO-Firma-Con-Certeza-icono-que-es-1.png" />
                  </CardAvatar>
                </CardHeader>
                <CardBody profile>
                  <h3 align="left" className={classes.cardTitle}>
                    ¿Qué es Firma Digital Con-Certeza?
                  </h3>
                  <p
                    align="left"
                    style={{
                      height: "285px",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Firma Digital Con-Certeza es una herramienta que te permite
                    firmar un documento electrónico dibujando tu grafo en
                    cualquier dispositivo y en cuestión de minutos. Ahorra
                    tiempo, dinero y recursos, sin poner en riesgo la validez
                    legal de tus documentos.
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardHeader color="primary">
                  <CardAvatar profile>
                    <img src="https://certificaciondigital.online/wp-content/uploads/2021/01/Certificacion-Digital-Online-CDO-Firma-Con-Certeza-icono-como-firmo-1.png" />
                  </CardAvatar>
                </CardHeader>
                <CardBody profile>
                  <h3 align="left" className={classes.cardTitle}>
                    ¿Cómo firmo?
                  </h3>
                  <br />
                  <p
                    align="left"
                    style={{
                      height: "295px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Es muy sencillo, sólo tienes que ingresar a Mi firma con
                    certeza, cargar el documento a firmar, indicar al sistema
                    quiénes intervienen en el proceso de firma y listo! Firma
                    Digital Con-Certeza hará el resto del trabajo por tí hasta
                    obtener un documento completamente firmado.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardHeader color="primary">
                  <CardAvatar profile>
                    <img src="https://certificaciondigital.online/wp-content/uploads/2021/01/Certificacion-Digital-Online-CDO-Firma-Con-Certeza-icono-certeza-legal.png" />
                  </CardAvatar>
                </CardHeader>
                <CardBody profile>
                  <h3 align="left" className={classes.cardTitle}>
                    ¿Cuál es la certeza legal de los documentos firmados con
                    Firma Digital Con-Certeza?
                  </h3>
                  <p
                    align="left"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    Firma Digital Con-Certeza es una herramienta web de firma
                    digital que cumple al 100% con el marco normativo mexicano
                    referente a la firma electrónica y a la conservación de
                    documentos electrónicos, de tal forma que obtendrás
                    documentos con la misma certeza legal que aquéllos que son
                    firmados físicamente en papel.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <h3
            style={{
              fontSize: "2.1rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Al final del proceso de Firma Digital Con-Certeza obtendrás:
          </h3>

          <div className={clasesproduc.section}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <InfoArea
                  title="Documento totalmente firmado con validez legal"
                  description=""
                  icon={require("assets/img/icono-documentos1.png")}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <InfoArea
                  title="Un resumen del proceso de firma que identifica al documento, a los firmantes, los datos de las firmas y los sellos de tiempo."
                  description=""
                  icon={require("assets/img/icono-documentos2.png")}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <InfoArea
                  title="Una Constancia de Conservación de la Norma Oficial Mexicana NOM-151, en formato “.cons”"
                  description=""
                  icon={require("assets/img/icono-documentos3.png")}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <InfoArea
                  title="La representación gráfica de la Constancia NOM-151, en formato “pdf”"
                  description=""
                  icon={require("assets/img/icono-documentos4.png")}
                  iconColor="info"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div>

          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <h3
                  style={{
                    textAlign: "center",
                    color: "#00acc1",
                    fontSize: "2.1rem",
                    fontWeight: "500",
                  }}
                >
                  No dudes más y comienza a firmar con Firma Digital
                  Con-Certeza.{" "}
                </h3>
              </GridItem>
            </GridContainer>
          </div>
          {/* <GridContainer>
            <GridItem xs={12} sm={12} md={8} lg={12}>
              <NavPills
                color="primary"
                tabs={[
                  {
                    tabButton: "Dashboard",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Schedule",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Tasks",
                    tabIcon: List,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <NavPills
                color="rose"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                tabs={[
                  {
                    tabButton: "Dashboard",
                    tabIcon: Dashboard,
                    tabContent: (
                      <span>
                        <p>
                          Collaboratively administrate empowered markets via
                          plug-and-play networks. Dynamically procrastinate B2C
                          users after installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                        <br />
                        <p>
                          Dramatically visualize customer directed convergence
                          without revolutionary ROI. Collaboratively
                          administrate empowered markets via plug-and-play
                          networks. Dynamically procrastinate B2C users after
                          installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Schedule",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer> */}
        </div>
      </div>
    </div>
  );
}
