import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import GestureIcon from "@material-ui/icons/Gesture";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function FolderList() {
  const classes = useStyles();

  return (
    <List
      className={classes.root}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Lastest notifications
        </ListSubheader>
      }
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <GestureIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Firmado"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Sandra Adams
              </Typography>
              <br />
              {"10:17 am."}
            </React.Fragment>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <HighlightOffIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Rechazado"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Juan Carlos
              </Typography>
              <br />
              {"10:17 am."}
            </React.Fragment>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <GestureIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Firmado"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Luis Aguilar
              </Typography>
              <br />
              {"10:17 am."}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}
