import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Button from "@material-ui/core/Button";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import GroupIcon from "@material-ui/icons/Group";
import AppBar from "@material-ui/core/AppBar";
import PostAddIcon from '@material-ui/icons/PostAdd';
import IconButton from '@material-ui/core/IconButton';
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStylestable = makeStyles(styles2);

const useStyles = makeStyles((theme) => ({
  grafico: {
    //backgroundColor:"#3f51b5",
    //color:"white",
    margin: 0,
    borderStyle: "groove",
    borderColor: "#fb8c00",
    //borderColor: "#fb8c00",
    //borderColor: "#00acc1",
    width: "100%",
  },
  appbar: {
    width: "100%",
    backgroundColor: "#fb8c00",
    //backgroundColor:"#fb8c00",
    //backgroundColor:"#00acc1",
    color: "white",
    margin: 0,
    textAlign: "center",
    //    borderStyle: "groove",
    //    borderColor: "#3f51b5",
  },
  nodoname: {
    width: "100%",
    //backgroundColor:"#3f51b5",
    backgroundColor: "GhostWhite",
    //color:"white",
    margin: 0,
    textAlign: "left",
    //    borderStyle: "groove",
    //    borderColor: "#3f51b5",
  },
  iconsmall: {
    fontSize: 12,
    margin: 2,
    marginTop: 8, //15 center
    padding: 0,
  },
  iconsmall2: {
    fontSize: 10,
    margin: 2,
    marginTop: 5, //15 center
    padding: 0,
  },
  iconsmall3: {
    fontSize: 8,
    //margin:2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconcheck: {
    fontSize: 8,
    //color:"green",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    backgroundColor: "green",
    color: "white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconanexos: {
    fontSize: 8,
    //color:"green",
    maxWidth: "12em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    //backgroundColor:"green",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconopen: {
    fontSize: 8,
    //color:"red",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    backgroundColor: "red",
    color: "white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconsave: {
    fontSize: 5,
    //color:"green",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    //backgroundColor:"green",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  icondescartar: {
    fontSize: 5,
    //color:"green",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    //backgroundColor:"red",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  colapse: {
    marginLeft: 20,
    color: "orange",
  },
  colapse1: {
    marginLeft: 20,
  },
  nombrecatalogo: {
    fontSize: 14,
    marginTop: 0,
    marginLeft: 10,
    textAlign: "left",
  },
  nombrearchivo: {
    marginTop: 6,
    fontSize: 10,
  },
  iconarchivo: {
    marginTop: 0,
    fontSize: 8,
    //color:"orange"
  },
  iconperson: {
    color: "black",
    marginTop: 0,
    fontSize: 10,
  },
  semaforopendiente: {
    color: "red",
    fontSize: "10",
    marginRight: 2,
  },
  semaforocompletado: {
    color: "green",
    fontSize: "10",
    marginRight: 2,
  },
  botondanger: {
    backgroundColor: "red",
    color: "white",
  },
  botongreen: {
    fontSize: "8",
    backgroundColor: "green",
    color: "white",
  },
  input: {
    display: "none",
  },
  botonupload: {
    marginLeft: 5,
  },
}));

export default function MisPendientes(props){
  const classes = useStyles();
  const classes2 = useStylestable();
  const { user } = useSelector((state) => state.auth);
  const [nodos, setNodos] = React.useState([]);
  const [grupos, setGrupos] = React.useState([]);

  function getGrupos(){
    let token = localStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
    method: 'get',
    url: `${process.env.REACT_APP_URL}api/workgroups`,
    headers: {
      'Accept': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
      axios(config)
        .then(function (response) {

          let data = response.data;
          let misgrupos = [];
          for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].members.length; j++) {
              if(data[i].members[j].uid==user.id){
                misgrupos = [...misgrupos,data[i]]
              }
            }
          }
          //console.log("mis grupos",misgrupos)
          //console.log("Expedientes en proceso",props.expedientes)

          let misnodes = []
          for (var i = 0; i < props.expedientes.length; i++) {
            for (var j = 0; j < props.expedientes[i].nodes.length; j++) {
              for (var k = 0; k < misgrupos.length; k++) {
                if(props.expedientes[i].nodes[j].workgroup_id==misgrupos[k]._id){
                  misnodes = [...misnodes,props.expedientes[i].nodes[j]]
                }
              }
            }
          }
          //console.log("nodos",misnodes);
          setNodos(misnodes);
          setGrupos(misgrupos);
        })
        .catch(function (error) {
          console.log(error);
          setNodos([]);
        });
  }

  function DisplayGrupo(props) {
    const grupo = grupos.find((item) => item._id == props.idgrupo);
    if (grupo)
      return (
        <React.Fragment key={grupo._id}>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={
              <PeopleOutlineIcon color="primary" className={classes.colapse1} />
            }
            defaultExpandIcon={
              <GroupIcon color="primary" className={classes.colapse1} />
            }
          >
            <TreeItem
              nodeId="0"
              label={
                <Grid container>
                  <Grid item xs={11}>
                    <Typography className={classes.nombrecatalogo}>
                      <font size="2">{grupo.name}</font>
                    </Typography>
                  </Grid>
                </Grid>
              }
              className={classes.root}
            >
              {grupo.members.map((item, key) => (
                <TreeItem
                  nodeId={item._id}
                  key={key}
                  label={
                    <Grid container>
                      <Grid item>
                        <PersonIcon className={classes.iconperson} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.nombrearchivo}>
                          <font size="2">{item.user.name}</font>
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              ))}
            </TreeItem>
          </TreeView>
        </React.Fragment>
      );
    return null;
  }

  function ShowExpediente(props){
    const result = props.expedientes.filter(item => item._id == props.id);
    console.log("Result",result)
    return(
      <React.Fragment>
        (<b>{result[0].name}</b> código: <b>{result[0].custom_id}</b>)
        <IconButton
          aria-label="Completar expediente"
          onClick={()=>{
            props.setExpediente(result[0]);
            props.setModalReportar(true);
          }}
        >
          <PostAddIcon
          className={
            classes2.tableActionButtonIcon +
            " " +
            classes2.edit
          }
          />
        </IconButton>
      </React.Fragment>
    )

  }

  React.useEffect(() => {
    getGrupos();
  }, []);

  return(
    <div className={classes.grafico}>
    <AppBar position="static" className={classes.appbar}>
      <b>MIS EXPEDIENTES</b>
    </AppBar>
      {nodos.map((item, key) => (
        <Grid container key={key}>
          <Grid item xs={11}>
            <div className={classes.nodoname}>
              <font size="2" style={{ textAlign: "left", marginLeft: 10 }}>
                <b>- {item.name}</b>
              </font>
              <ShowExpediente
                id={item.expediente_id}
                expedientes={props.expedientes}
                setExpediente={props.setExpediente}
                setModalReportar={props.setModalReportar}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            style={{ textAlign: "right" }}
            className={classes.nodoname}
          >
            {item.status == 0 ? (
              <Tooltip title="Pendiente" placement="top">
                <FiberManualRecordIcon
                  className={classes.semaforopendiente}
                  style={{ fontSize: 12 }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Completado" placement="top">
                <CheckCircleIcon
                  className={classes.semaforocompletado}
                  style={{ fontSize: 12 }}
                />
              </Tooltip>
            )}
          </Grid>
          {/*
          <Grid item xs={12}>
            <DisplayGrupo idgrupo={item.workgroup_id} />
          </Grid>
          */}
        </Grid>
      ))}
    </div>
  );
}
