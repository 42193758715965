import React from "react";
import axios from "axios";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PDFView from "./PDFView";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from '@material-ui/core/Button';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appbar:{
    //backgroundColor:"#3f51b5",
    backgroundColor:"white",
  },
  titulo:{
    backgroundColor:"#3f51b5",
    //backgroundColor:"#00acc1",
    //backgroundColor:"white",
  },
  grafico:{
    //backgroundColor:"#3f51b5",
    //color:"white",
    margin:0,
    borderStyle: "groove",
    borderColor: "#3f51b5",
    //borderColor: "#fb8c00",
    //borderColor: "#00acc1",
    width:"100%"
  },
  botondanger: {
    backgroundColor: "red",
    color:"white"
  },
  botongreen: {
    backgroundColor: "green",
    color:"white"
  },
  botonwhite: {
    backgroundColor: "white",
    color:"gray"
  },
  iconsmall: {
    fontSize:12,
    margin:2,
    marginTop:8, //15 center
    padding:0,
  },
  iconsmall2: {
    fontSize:8,
    margin:2,
    //marginTop:15, //15 center
    //padding:0,
    cursor: "default"
  },
  nombrecatalogo:{
    fontSize:13,
    marginTop:7,
    marginLeft:10,
  },
  nombrearchivo:{
    marginTop:6,
    fontSize:10,
  },
  iconarchivo:{
    marginTop:0,
    fontSize:10,
  },
  paper:{
    width:"95%",
  },
  colapse:{
    marginLeft:20,
    color:"orange"
  },
  p: {
    width: "90%",
    margin: "0",
    lineHeight: "0.7"
  },

  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    width:"100%"
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#00acc1",
    '&:hover': {
      backgroundColor: "#00acc1",
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },


}));

export default function ExpedienteArchivos(props) {
    const classes = useStyles();
    const [entro, setEntro] = React.useState(0);
    const [documentos, setDocumentos] = React.useState("");
    const [documentosFiltrados, setDocumentosFiltrados] = React.useState("");
    const [expediente, setExpediente] = React.useState(false);
    const [modalVer, setModalVer] = React.useState(false);
    const [base64, setBase64] = React.useState(false);
    const [nombreArchivo, setNombreArchivo] = React.useState("");
    const [documentoShow, setDocumentoShow] = React.useState("");
    const [modalShow, setModalShow] = React.useState(false);

    const [openMensaje, setOpenMensaje] = React.useState(false);
    const [mensaje, setMensaje] = React.useState(false);
    const [severity, setSeverity] = React.useState("info");
    const handleCloseMensaje = (event, reason) => {
     if (reason === 'clickaway') {
       return;
     }
     setOpenMensaje(false);
    };

    function alerta(texto,severidad){
    setOpenMensaje(true);
    setMensaje(texto);
    setSeverity(severidad);
    }

    if(props.expediente && entro==0){
      getDocumentos();
      setExpediente(props.expediente)
      setEntro(1);
    }
    else{
      if(props.expediente!=expediente)
        setEntro(0);
    }

    async function getDocumentos(){
      let token = localStorage.getItem("Token");
      //let token = `${process.env.REACT_APP_TOKEN}`;
      var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/expedientes/documentos/${props.expediente._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
        axios(config)
          .then(function (response) {
            console.log("Documentos:",response.data)
            setDocumentos(response.data);
          })
          .catch(function (error) {
            console.log(error);
            setDocumentos("");
          });
    }


    function handleBase64(id){
      let token = localStorage.getItem("Token");
      //let token = `${process.env.REACT_APP_TOKEN}`;
      var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/documentos/${id}/preview/download`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
        axios(config)
          .then(function (response) {
            //console.log("base64:",response.data)
            setBase64("data: application / pdf; base64, "+response.data);
            //setGuardar(false);
            setModalVer(true);
          })
          .catch(function (error) {
            console.log(error);
            setBase64("");
          });
    }

    React.useEffect(()=>{
      console.log("ENTRÓ",props.expediente)
    },[]);

    return (
      <>
      <Grid container>
        <AppBar position="static" className={classes.titulo}>
        <b>Código: {expediente.custom_id}</b>
        </AppBar>
        <TreeView
          className={classes.root}
            defaultCollapseIcon={<FolderOpenIcon color="primary" className={classes.colapse} />}
            defaultExpandIcon={<FolderIcon color="primary" className={classes.colapse} />}
        >
          <TreeItem nodeId="0"
            label={
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.nombrecatalogo}>
                    <font size="2">{expediente.name}</font>
                  </Typography>
                </Grid>
              </Grid>
            }
            className={classes.root}
          >
          {/*display anexos*/}
            {documentos && documentos.map((itemdoc,key) => (
              <TreeItem nodeId={itemdoc._id} key={key} label={
                <Grid container>
                  <Grid item>
                    <Typography className={classes.nombrearchivo}>
                      <font size="2"><b>{itemdoc.filename+" #"+itemdoc.meta.custom_id}</b></font>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <React.Fragment>
                    <Tooltip title="Ver documento" placement="top">
                    <VisibilityIcon
                      className={classes.iconsmall}
                      color="primary"
                      onClick={()=>{
                        //console.log("ver",item)
                        alerta("Abriendo documento, por favor espere unos segundos","info")
                        handleBase64(itemdoc._id);
                      }}
                    />
                    </Tooltip>

                    <Tooltip title="Información del documento" placement="top">
                    <InfoIcon
                      className={classes.iconsmall}
                      color="primary"
                      onClick={()=>{
                        //console.log("info",item)
                        setNombreArchivo(itemdoc);
                        setDocumentoShow(itemdoc);
                        setModalShow(true);
                      }}
                    />
                    </Tooltip>
                    </React.Fragment>
                  </Grid>
                </Grid>
              } />
            ))}
            {/*fin anexos*/}
          </TreeItem>
        </TreeView>
      </Grid>

      {/*DIALOGO VER PDF*/}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={modalVer}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {nombreArchivo.name}
        </DialogTitle>
        <DialogContent>
          {base64 ? <PDFView base64={base64} /> :("NO hay PDF")}
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          //color="primary"
          onClick={()=>{
            setModalVer(false)
          }}
          className={classes.botondanger}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            //setModalVer(false)
            //const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            //const fileName = "vct_illustration.pdf";
            downloadLink.href = base64;
            downloadLink.download = nombreArchivo.name+".pdf";
            downloadLink.click();
          }}
          //className={classes.botondanger}
        >
          Descargar
        </Button>

        </DialogActions>
      </Dialog>
      {/* FIN VER PDF*/}

      {/*DIALOGO INFO*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalShow}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Documento
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {documentoShow ?(
              <>
                  <b>Nombre:</b> {documentoShow.filename}
                  <br></br>
                  <b>Fecha de registro:</b> {moment(documentoShow.created_at).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                  <br></br>
                  <b>Código:</b> {documentoShow.meta && documentoShow.meta.custom_id ? documentoShow.meta.custom_id :null}
                  <br></br>
                  <b>Usuario:</b> {documentoShow.users && documentoShow.users.name ? documentoShow.users.name+" ("+documentoShow.users.email+")" :null}
                  <br></br>
                  <b>Estatus:</b> {documentoShow.status && documentoShow.status.name ? documentoShow.status.name :null}
                  <br></br>
                  {documentoShow.reason ? (
                    <React.Fragment>
                      <font color="red"><b>{documentoShow.reason}</b></font>
                      <br></br>
                      <font color="red">Fecha de rechazo:</font>{" "}
                      <font color="red">
                      {moment(documentoShow.stopped_at).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                      </font>
                      <br></br>
                    </React.Fragment>
                  ) : null}
                  <b>Hash:</b> <font size="1">{documentoShow.hash}</font>
                  <br></br>
                  <b>ID:</b> {documentoShow._id}
                  <br></br>
                  <b>Tamaño:</b> {documentoShow.size}
                  <br></br>
              </>
            ):null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{
            setModalShow(false)
          }}
      //          className={classes.botondanger}
        >
          Cerrar
        </Button>
        </DialogActions>
      </Dialog>
      {/* FIN SHOW INFO*/}

      {/*MENSAJE*/}
      <Snackbar
          open={openMensaje}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
          onClose={handleCloseMensaje}>
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}
      </>
    );

}
