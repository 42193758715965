import React from "react";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import ControlPanel from "components/controlPanel/ControlPanel";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { Grid } from "@material-ui/core";
import { whiteColor } from "assets/jss/material-dashboard-react";
import { saveLog } from "containers/Logs/funcionesLog.js";
import moment from "moment";
import LogoFirma from "containers/Firmante/LogoFirma";
import rectangle from "containers/Firmante/rectangle-firma.png";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button2: {
    backgroundColor: green[500],
    color: whiteColor,
    alignItems: "center",
    top: "50%",
    left: "40%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Preview2(props) {
  //id=idDocumento idfirmante=idFirmante
  const inputRef = React.useRef();

  const classespro = useStylesprogrees();
  const [open, setOpen] = React.useState(true);
  let { idDoc, idfirmante } = useParams();
  const [base64, setbase64] = React.useState("");
  const [openPrivacidad, setopenPrivacidad] = React.useState(true);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [scale, setScale] = React.useState(1.0);
  const [left, setLeft] = React.useState(0);

  function Firmas() {
    var x;
    isMobile() ? (x = 0) : (x = left + 260);
    if (props.coordenadas && props.coordenadas.length > 0) {
      return (
        <div>
          {props.coordenadas
            .filter((name) => name.page === pageNumber)
            .map((detalle, index) => (
              <LogoFirma
                key={index}
                src={rectangle}
                w={detalle.w - 22}
                h={detalle.h - 15}
                coordenadas={
                  isMobile()
                    ? {
                        x: (detalle.x * 290) / detalle.width - 10,
                        y: (detalle.y * 130) / detalle.height - 35,
                      }
                    : {
                        x: detalle.x,
                        y: detalle.screenY - detalle.height - 22,
                      }
                }
                left={x + 28}
                bottom={detalle.top}
                texto={detalle.nombre}
                height={detalle.height}
                width={detalle.width}
                mobile={isMobile()}
                top={1160}
                topmobile={200}
              />
            ))}
        </div>
      );
    }
    return <div></div>;
  }

  React.useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      console.log("Ubicación PDF:");
      console.log(rect);
      setLeft(rect.x);
    }

    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}/preview/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [base64]);

  const handleClose = () => {
    //Guardar log aviso de privacidad
    //buscar datos firmante
    const token = `${process.env.REACT_APP_TOKEN}`;
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        saveLog(
          token,
          res.data.name + " " + res.data.last_name + " aceptó política " + idDoc
        );
      })
      .catch((error) => {
        console.error(error);
      });
    //fin log

    let newDate = new Date();
    let date_accepted = moment(newDate).format("YYYYMMDD HH:mm:ss");
    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idfirmante}?date_accepted=${date_accepted}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Firmante Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
    setopenPrivacidad(false);
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <div>
      {base64.length === 0 ? (
        <Backdrop className={classespro.backdrop} open={open}>
          <CircularProgress size={44} className={classespro.buttonProgress} />
        </Backdrop>
      ) : (
        <React.Fragment>
          {/* <Typography variant="h6" gutterBottom>
            Documento a firmar
          </Typography>
          <iframe
            src={`data:application/pdf;base64,${base64}`}
            type=" application / pdf "
            width="100%"
            height="475"
          ></iframe> */}
          <br /> <br />
          <div ref={inputRef}>
            <ControlPanel
              scale={scale}
              setScale={setScale}
              numPages={numPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              file={`data: application / pdf; base64, ${base64} `}
            />
            <Document
              error="Error al intentar cargar el PDF"
              loading="Cargando..."
              file={`data: application / pdf; base64, ${base64} `}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                scale={scale}
                height={pdfHeigth}
                width={pdfWidth}
                renderTextLayer={false}
                pageNumber={pageNumber}
              />
            </Document>
            <div className="text-center">
              <p>
                Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                {numPages || "--"}
              </p>
              <button
                className="btn btn-dark"
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Atrás
              </button>{" "}
              <button
                className="btn btn-dark"
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Siguiente
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
      <div>
        <Dialog
          open={openPrivacidad}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Aviso de privacidad"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                <div>
                  <div>
                    Al ingresar al servicio de firma electrónica se entenderá
                    que estás de acuerdo con el Aviso de Privacidad de
                    Certificación Digital Online. Si deseas conocer el Aviso de
                    Privacidad, ingresa a: {""}
                    <a
                      href="https://certificaciondigital.online/aviso-de-privacidad/"
                      target="_blank"
                    >
                      Aviso de privacidad
                    </a>{" "}
                  </div>
                  <Grid>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                      className={classespro.button2}
                    >
                      Aceptar
                    </Button>
                  </Grid>
                </div>
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      {base64.length > 0 ? <Firmas /> : null}
    </div>
  );
}
