/* eslint-disable no-case-declarations */
/* eslint-disable react/display-name */
import React from "react";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import GestureIcon from "@material-ui/icons/Gesture";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PeopleIcon from "@material-ui/icons/People";
import PauseIcon from "@material-ui/icons/Pause";
import MaterialTable from "material-table";
import GetAppIcon from "@material-ui/icons/GetApp";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
//log
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { useHistory } from "react-router-dom";
// import "./FimantesStyles.css";
const useStylestable = makeStyles(styles2);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FirmanteDashboard() {
  const history = useHistory();
  //*****************************************************
  //    LLAMADAS A LA API DE CDO
  //******************************************************
  //credenciales API CDO
  let username = "ce2af9cab947ed15288e058ecd5350f5";
  let password = "9c9dd0db9b4a23eddf64b96e28b1214b";
  const token = Buffer.from(`${username}:${password}`, "utf8").toString(
    "base64"
  );
  const PROXY_URL = "";

  const classes2 = useStylestable();
  const [docuEspera, setDocuEspera] = React.useState([]);
  const [docuFirmados, setDocuFirmados] = React.useState([]);
  //let docuEspera=[];
  //let docuFirmados=[];
  const [user, setUser] = React.useState("");
  const [valueState, setValueState] = React.useState(1);
  const [modalFirmantesCC, setModalFirmantesCC] = React.useState(false);
  const [listaFirmantes, setListaFirmantes] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [base64, setbase64] = React.useState("");
  const [scale, setScale] = React.useState(1.0);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(575);
  const [pdfWidth, setpdfWidth] = React.useState(660);
  const [openZip, setOpenZip] = React.useState(false);
  const [contratosID, setContratosID] = React.useState("");
  const [modalFirmantes, setModalFirmantes] = React.useState(false);
  const [modalCDO, setModalCDO] = React.useState(false);
  const [datosUsuarioCDO, setDatosUsuarioCDO] = React.useState("");
  const [botonFirmaFinal, setBotonFirmaFinal] = React.useState(true);
  const [tituloEspera, setTituloEspera] = React.useState("Total en espera");

  const handleFirmaCDO = (cdo_contratos_id) => {
    //console.log(user.email,cdo_contratos_id);
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      cdo_contratos_id +
      "/get";
    var config = {
      method: "get",
      url: URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log("Contrato CDO:", response.data);
        for (var i = 0; i < response.data.firmantes.length; i++) {
          if (response.data.firmantes[i].email === user.email) {
            const URL1 =
              "https://api.certificaciondigital.online/api/contrato/firmante/" +
              response.data.firmantes[i]._id +
              "/show-credentials";
            var config1 = {
              method: "get",
              url: URL1,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Basic " + token,
              },
            };
            axios(config1)
              .then(function (res) {
                //console.log(res.data);
                setDatosUsuarioCDO(res.data);
                setModalCDO(true);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  if (isMobile()) {
    //    setpdfHeigth(500);
    //setpdfWidth(290);
  }

  const handleChangeTabs = (event, value) => {
    setValueState(value);
    if (value == 1) {
      setHeaderColor("warning");
    } else if (value == 0) {
      setHeaderColor("success");
    }
  };

  const [headerColor, setHeaderColor] = React.useState("warning");

  const data = () => {
    document.body.style.overflow = "unset";
    let token = localStorage.getItem("Token");
    //console.log(token);
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/auth/user`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("EMAIL", response.data.user.email);
        setUser(response.data.user);
        Firmados(response.data.user.email);
        EnProceso(response.data.user.email);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    data();
  }, []);

  //pa mostrar el estatus cuando un documento es rechazado
  const estatusCDO = (data) => {
    //console.log("estatus:",data);
    if (data.rechazo) {
      return "Rechazó: " + data.motivo_rechazo;
    } else {
      if (data.firmo) {
        return "Firmó";
      } else {
        return "Pendiente";
      }
    }
  };

  function bucarFirmantesCDO(contratoCDO_id) {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contratoCDO_id +
      "/firmante/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Firmantes CDO:", response.data);
        setListaFirmantes(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleDescargarCDO = (nombre, status, id) => {
    setOpenZip(true);
    var nombre_archivo = nombre;
    var URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      id +
      "/descargar-certificado";
    if (status === 3) {
      nombre_archivo = nombre + ".zip";
    } else {
      URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        id +
        "/descargar";
    }

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
      setOpenZip(false);
    });
  };

  //descargar pdf firmado en con CERTEZA
  const getZipDocument = (idDocu, fileName) => {
    setOpenZip(true);
    const nameFormat = fileName.slice(0, -4);
    let token = `${process.env.REACT_APP_TOKEN}`;
    axios({
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/bundle/download`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nameFormat}.zip`);
      document.body.appendChild(link);
      link.click();
      setOpenZip(false);
    });
  };

  // funcion de abrir el pdf firmado
  const handleOpenPDFFirmado = async (idDocu) => {
    setOpenDialog(true);
    const token = `${process.env.REACT_APP_TOKEN}`;

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocu}/signed/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // funcion de abrir el pdf no firmado
  const handleOpenPDF = async (idDocu) => {
    setOpenDialog(true);
    const token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleclosepdf = () => {
    setOpenDialog(false);
    setbase64("");
    setScale(1.0);
  };

  function UltimaFirma(email) {
    let ultima = [];
    for (var i = 0; i < docuEspera.length; i++) {
      //console.log(docuEspera[i].documentos.firmantes);
      if (docuEspera[i].documentos.firmantes.length === 1) {
        //console.log("ÚNICO");
        ultima = [...ultima, docuEspera[i]];
      } else {
        if (
          docuEspera[i].documentos.firmantes[
            docuEspera[i].documentos.firmantes.length - 1
          ].email === email
        ) {
          //console.log("ES ÚLTIMO");
          let firmasprevia = 0;
          for (
            var j = 0;
            j < docuEspera[i].documentos.firmantes.length - 1;
            j++
          ) {
            console.log("previos:", docuEspera[i].documentos.firmantes[j]);
            if (docuEspera[i].documentos.firmantes[j].date_signed) {
              firmasprevia++;
            }
          }
          if (firmasprevia === docuEspera[i].documentos.firmantes.length - 1) {
            ultima = [...ultima, docuEspera[i]];
          }
        } else {
          console.log("NO ES ÚLTIMO");
        }
      }
    }
    //console.log("ultima",ultima);
    setDocuEspera(ultima);
  }

  function EnProceso(email) {
    let espera = [];
    let token = `${process.env.REACT_APP_TOKEN}`;
    const endpoint = `${process.env.REACT_APP_URL}api/firmantes?documento_status_id=2&email=${email}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        console.log("Data en proces ", resp.data);
        let i = 0;
        const myObjects = resp.data;
        for (var key in myObjects) {
          if (myObjects.hasOwnProperty(key)) {
            espera = [...espera, myObjects[key]];
          }
        }
        console.log("Espera:", espera);
        setDocuEspera(espera);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  }

  function Firmados(email) {
    let firmados = [];
    let token = `${process.env.REACT_APP_TOKEN}`;
    const endpoint = `${process.env.REACT_APP_URL}api/firmantes?documento_status_id=3&email=${email}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        //console.log("Data firmados ", resp.data);
        let i = 0;
        const myObjects = resp.data;
        for (var key in myObjects) {
          if (myObjects.hasOwnProperty(key)) {
            firmados = [...firmados, myObjects[key]];
          }
        }
        console.log("Firmados:", firmados);
        setDocuFirmados(firmados);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  }

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  return (
    <React.Fragment>
      <CustomTabs
        handleChange={handleChangeTabs}
        valueState={valueState}
        headerColor={headerColor}
        title=""
        tabs={[
          {
            tabName: "FIRMADOS (" + docuFirmados.length + ")",
            tabIcon: GestureIcon,
            tabContent: (
              <React.Fragment>
                <Grid container style={{ marginTop: 10 }}>
                  <div style={{ width: "100%" }}>
                    <MaterialTable
                      localization={localization}
                      options={{
                        exportButton: { csv: true, pdf: false },
                        exportAllData: true,
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                      }}
                      columns={[
                        {
                          title: "Compañía",
                          field: "documentos.users.company",
                        },
                        { title: "Nombre", field: "documentos.filename" },
                        {
                          title: "Fecha",
                          field: "documento.created_at",
                          render: (row) => (
                            <span>
                              {row["documentos"]["created_at"]
                                ? moment(
                                    row["documentos"]["created_at"]
                                  ).format("DD/MM/YYYY")
                                : null}
                            </span>
                          ),
                        },
                        { title: "Tipo", field: "documentos.type.name" },
                        {
                          title: "Firmantes",
                          field: "documentos.firmantes_names",
                        },
                        { title: "ID", field: "documentos._id" },
                      ]}
                      data={docuFirmados}
                      title={"Firmados (" + docuFirmados.length + ")"}
                      actions={[
                        {
                          icon: () => (
                            <GetAppIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Descargar PDF",
                          onClick: (event, rowData) => {
                            console.log("clic", rowData);
                            //Guardar log
                            let token = localStorage.getItem("Token");
                            saveLog(
                              token,
                              "Descargar " + rowData.documentos._id
                            );
                            saveLogCapture(
                              token,
                              "Descargar " + rowData.documentos._id
                            );
                            //fin log
                            switch (rowData.documentos.type.id) {
                              case 1:
                                console.log("SIMPLE");
                                getZipDocument(
                                  rowData.documentos._id,
                                  rowData.documentos.filename
                                );
                                break;
                              case 2:
                                console.log("SIMPLE+ID");
                                getZipDocument(
                                  rowData.documentos._id,
                                  rowData.documentos.filename
                                );
                                break;
                              case 3:
                                handleDescargarCDO(
                                  rowData.documentos.filename,
                                  rowData.documentos.documento_status_id,
                                  rowData.documentos.cdo_contratos_id
                                );
                                break;
                              case 8:
                                console.log("SIN CODIGO");
                                getZipDocument(
                                  rowData.documentos._id,
                                  rowData.documentos.filename
                                );
                                break;
                              default:
                                console.log("NO SOPORTADO");
                            }
                          },
                        },
                        {
                          icon: () => (
                            <VisibilityIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Ver documento",
                          onClick: (event, rowData) => {
                            switch (rowData.documentos.type.id) {
                              case 1:
                                console.log("SIMPLE");
                                handleOpenPDFFirmado(rowData.documentos._id);
                                break;
                              case 2:
                                console.log("SIMPLE+ID");
                                handleOpenPDFFirmado(rowData.documentos._id);
                                break;
                              case 3:
                                handleOpenPDF(rowData.documentos._id);
                                break;
                              case 6:
                                console.log("NOTIFICACIÓN");
                                handleOpenPDFFirmado(rowData.documentos._id);
                                break;
                              case 8:
                                console.log("SIN CODIGO");
                                handleOpenPDFFirmado(rowData.documentos._id);
                                break;
                              default:
                                console.log("NO SOPORTADO");
                            }
                          },
                        },
                        {
                          icon: () => (
                            <PeopleIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Participantes",
                          onClick: (event, rowData) => {
                            switch (rowData.documentos.type.id) {
                              case 1:
                                setListaFirmantes(rowData.documentos.firmantes);
                                setModalFirmantesCC(true);
                                break;
                              case 2:
                                setListaFirmantes(rowData.documentos.firmantes);
                                setModalFirmantesCC(true);
                                break;
                              case 3:
                                setContratosID({
                                  cc: rowData.documentos._id,
                                  cdo: rowData.documentos.cdo_contratos_id,
                                });
                                bucarFirmantesCDO(
                                  rowData.documentos.cdo_contratos_id
                                );
                                setModalFirmantes(true);
                                break;
                              case 8:
                                setListaFirmantes(rowData.documentos.firmantes);
                                setModalFirmantesCC(true);
                                break;
                              default:
                                console.log("NO SOPORTADO");
                            }
                          },
                        },
                      ]}
                    />
                  </div>
                </Grid>
              </React.Fragment>
            ),
          },
          {
            tabName: "EN ESPERA (" + docuEspera.length + ")",
            tabIcon: PauseIcon,
            tabContent: (
              <React.Fragment>
                <Grid container style={{ marginTop: 10 }}>
                  <div style={{ width: "100%" }}>
                    {botonFirmaFinal ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          UltimaFirma(user.email);
                          setBotonFirmaFinal(false);
                          setTituloEspera("En espera como firmante final");
                        }}
                        color="primary"
                      >
                        firma final
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          EnProceso(user.email);
                          setBotonFirmaFinal(true);
                          setTituloEspera("Total en espera");
                        }}
                      >
                        todos
                      </Button>
                    )}

                    <hr />
                    <MaterialTable
                      localization={localization}
                      options={{
                        exportButton: { csv: true, pdf: false },
                        exportAllData: true,
                        draggable: false,
                        pageSize: 20, // make initial page size
                        pageSizeOptions: [20, 50, 100, 500, 1000],
                        actionsColumnIndex: -1,
                      }}
                      columns={[
                        {
                          title: "Compañía",
                          field: "documentos.users.company",
                        },
                        { title: "Nombre", field: "documentos.filename" },
                        {
                          title: "Fecha",
                          field: "documento.created_at",
                          render: (row) => (
                            <span>
                              {row["documentos"]["created_at"]
                                ? moment(
                                    row["documentos"]["created_at"]
                                  ).format("DD/MM/YYYY")
                                : null}
                            </span>
                          ),
                        },
                        { title: "Tipo", field: "documentos.type.name" },
                        {
                          title: "Firmantes",
                          field: "documentos.firmantes_names",
                        },
                        { title: "ID", field: "documentos._id" },
                      ]}
                      data={docuEspera}
                      title={tituloEspera + " (" + docuEspera.length + ")"}
                      actions={[
                        (rowData) => ({
                          icon: () => (
                            <GestureIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Firmar",
                          onClick: (event, rowData) => {
                            if (rowData.date_signed) {
                              alert(
                                "Ud. ya firmó este documento, esta pendiente alguno de los otros firmantes"
                              );
                            } else {
                              const isLogged = true;
                              switch (rowData.documentos.type.id) {
                                case 1:
                                  console.log("SIMPLE");
                                  // window.open(
                                  //   `https://con-certeza.mx/sign/${rowData.documentos._id}/${rowData._id}`
                                  // );

                                  // /sign/miFirma/:idDoc/:idfirmante/:isLogged

                                  // window.location.href = `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`;

                                  history.push(
                                    `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                                  );
                                  break;
                                case 2:
                                  console.log("SIMPLE+ID");
                                  // window.open(
                                  //   `https://con-certeza.mx/signid/${rowData.documentos._id}/${rowData._id}`
                                  // );
                                  history.push(
                                    `/signid/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                                  );
                                  break;
                                case 3:
                                  console.log("FIEL");
                                  //window.open(`https://firma.certificaciondigital.online`);
                                  //setModalCDO(true);
                                  handleFirmaCDO(
                                    rowData.documentos.cdo_contratos_id
                                  );
                                  break;
                                case 8:
                                  console.log("SIMPLE SIN SMS");
                                  // window.open(
                                  //   `https://con-certeza.mx/sign/${rowData.documentos._id}/${rowData._id}`
                                  // );

                                  history.push(
                                    `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                                  );
                                  break;
                                default:
                                  console.log("NO SOPORTADO");
                              }
                            }
                          },
                          disabled: rowData.documentos.type.id === 4,
                        }),

                        {
                          icon: () => (
                            <VisibilityIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Ver documento",
                          onClick: (event, rowData) => {
                            handleOpenPDF(rowData.documentos._id);
                          },
                        },
                        {
                          icon: () => (
                            <PeopleIcon
                              className={
                                classes2.tableActionButtonIcon +
                                " " +
                                classes2.edit
                              }
                            />
                          ),
                          tooltip: "Participantes",
                          onClick: (event, rowData) => {
                            //console.log(rowData)
                            switch (rowData.documentos.type.id) {
                              case 1:
                                setListaFirmantes(rowData.documentos.firmantes);
                                setModalFirmantesCC(true);
                                break;
                              case 2:
                                setListaFirmantes(rowData.documentos.firmantes);
                                setModalFirmantesCC(true);
                                break;
                              case 3:
                                setContratosID({
                                  cc: rowData.documentos._id,
                                  cdo: rowData.documentos.cdo_contratos_id,
                                });
                                bucarFirmantesCDO(
                                  rowData.documentos.cdo_contratos_id
                                );
                                setModalFirmantes(true);
                                break;
                              case 8:
                                setListaFirmantes(rowData.documentos.firmantes);
                                setModalFirmantesCC(true);
                                break;
                              default:
                                console.log("NO SOPORTADO");
                            }
                          },
                        },
                      ]}
                    />
                  </div>
                </Grid>
              </React.Fragment>
            ),
          },
        ]}
      />
      {/*dialogo para ver pdf*/}
      <Dialog
        open={openDialog}
        maxWidth={"md"}
        // fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Tu documento"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {base64.length === 0 ? (
              <Backdrop>
                <CircularProgress size={44} />
              </Backdrop>
            ) : (
              <div align="center">
                <ControlPanel
                  scale={scale}
                  setScale={setScale}
                  numPages={numPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  file={`data: application / pdf; base64, ${base64} `}
                />
                <Document
                  error="Error al intentar cargar el PDF"
                  loading="Cargando..."
                  file={`data: application / pdf; base64, ${base64} `}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    scale={scale}
                    height={pdfHeigth}
                    width={pdfWidth}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                  />
                </Document>

                <div className="text-center">
                  <p>
                    Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                    {numPages || "--"}
                  </p>
                  <button
                    className="btn btn-dark"
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Atrás
                  </button>{" "}
                  <button
                    className="btn btn-dark"
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleclosepdf} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*fin dialogo para ver pdf*/}

      {/*MODAL MOSTRAR FIRMANTES CC*/}
      <Dialog
        open={modalFirmantesCC}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantesCC(false);
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          Información de los firmantes
        </DialogTitle>
        <DialogContent id="modal-slide-description">
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                }}
                columns={[
                  {
                    title: "Nombres",
                    field: "name",
                    render: (row) => (
                      <span>{row["name"] + " " + row["last_name"]}</span>
                    ),
                  },
                  { title: "Compañía", field: "company" },
                  {
                    title: "Medio de Notificación",
                    field: "notification_types_id",
                    render: (row) => (
                      <span>
                        {row["notification_types_id"] === 1
                          ? row["email"]
                          : row["phone_country_code"] + row["phone"]}
                      </span>
                    ),
                  },
                  {
                    title: "Estatus del Proceso",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["role"] === "Notificado"
                          ? "Notificado"
                          : row["date_signed"]
                          ? "Firmó"
                          : row["firmas"] && row["firmas"].length > 0
                          ? "Pendiente de confirmar código"
                          : "Pendiente de Firma"}
                      </span>
                    ),
                  },
                  {
                    title: "Registrado",
                    field: "created_at",
                    render: (row) => (
                      <span>
                        {row["created_at"]
                          ? moment(row["created_at"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Enviado",
                    field: "date_notified",
                    render: (row) => (
                      <span>
                        {row["date_notified"]
                          ? moment(row["date_notified"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Aviso de Privacidad Aceptado",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Visto",
                    field: "date_accepted",
                    render: (row) => (
                      <span>
                        {row["date_accepted"]
                          ? moment(row["date_accepted"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firmado",
                    field: "date_signed",
                    render: (row) => (
                      <span>
                        {row["date_signed"]
                          ? moment(row["date_signed"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                ]}
                data={listaFirmantes}
                title={""}
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setModalFirmantesCC(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*FIN DIALOG MOSTRAR FIRMANTES CC*/}

      {/*MODAL MOSTRAR FIRMANTES CDO*/}
      <Dialog
        open={modalFirmantes}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {
          setModalFirmantes(false);
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          Información de los firmantes
        </DialogTitle>
        <DialogContent id="modal-slide-description">
          <div>
            {listaFirmantes ? (
              <MaterialTable
                localization={localization}
                options={{
                  headerStyle: {
                    color: "green",
                  },
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  exportButton: false,
                }}
                columns={[
                  { title: "Nombre y Apellidos", field: "nombre" },
                  { title: "RFC", field: "rfc" },
                  {
                    title: "Firma",
                    field: "firmo",
                    render: (row) => <span>{estatusCDO(row)}</span>,
                  },
                  {
                    title: "Registro",
                    field: "fecha_registro",
                    render: (row) => (
                      <span>
                        {row["fecha_registro"]
                          ? moment(row["fecha_registro"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Notificación",
                    field: "fecha_inicio",
                    render: (row) => (
                      <span>
                        {row["fecha_inicio"]
                          ? moment(row["fecha_inicio"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                  {
                    title: "Firma",
                    field: "fecha_firma",
                    render: (row) => (
                      <span>
                        {row["fecha_firma"]
                          ? moment(row["fecha_firma"]).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : null}
                      </span>
                    ),
                  },
                ]}
                data={listaFirmantes}
                title={""}
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setModalFirmantes(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*FIN DIALOG MOSTRAR FIRMANTES CDO*/}

      {/*DIALOGO DE ESPERA PARA DESCARGA*/}
      {/* Dialogo de espera de descarga de paquete de documentos firmados */}
      <div>
        <Dialog
          open={openZip}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Descarga de documento"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                La carpeta con los documentos firmados se descargaran al lugar
                habitual de Descargas: Esto puede tardar unos segundos...
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {" "}
                <CircularProgress size={44} />
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      {/*FIN DIALOGO DE ESPERA PARA DESCARGA*/}

      {/*DIALOGO CREDENCIALES CDO*/}
      <Dialog
        open={modalCDO}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Por favor <strong>FIRMAR</strong> usando las siguientes credenciales:
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography></Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {/*<h5>Por favor <strong>FIRMAR</strong> usando las siguientes credenciales:</h5>*/}
              <p>
                <strong>Usuario:</strong> {datosUsuarioCDO.username}
              </p>
              <p>
                <strong>Password:</strong> {datosUsuarioCDO.password}
              </p>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.open(`https://firma.certificaciondigital.online`);
            }}
            color="primary"
          >
            Firmar
          </Button>
          <Button
            onClick={() => {
              setModalCDO(false);
            }}
            color="danger"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*FIN DIALOGO CREDENCIALES CDO*/}
    </React.Fragment>
  );
}
