import React from "react";

import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import moment from "moment";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import logoCDO from "assets/img/LOGOCDO.png";
import GridItem from "components/Grid/GridItem.js";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import localization from "components/localization/localization";
import PeopleIcon from "@material-ui/icons/People";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "components/Table/Table.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditGroup from "./editGroup";
import AddUsers from "containers/editGroup/addGroupAndUser/addUsers";
import AddGroup from "containers/editGroup/addGroupAndUser/addGroup";
import AddCompany from "containers/editGroup/addCompany/addCompany";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
      <GridItem>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Powered by "}
          <Link color="inherit" href="https://certificaciondigital.online/">
            CDO
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  );
}

const useStylestable = makeStyles(styles2);
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    width: 1000,
    height: 450,
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContainerEditGroup(props) {
  const classes2 = useStylestable();
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const [activeRender, setActiveRender] = React.useState(0);

  const [dialogUsers, setDialogUsers] = React.useState(false);
  const [dialogUsers2, setDialogUsers2] = React.useState(false);

  const [dialogEdit, setDialogEdit] = React.useState(false);
  const [user, setuser] = React.useState(false);
  const [dataGroups, setdataGroups] = React.useState([]);
  const [arrayData, setArrayData] = React.useState([]);

  const [dataUsers2, setdataUsers2] = React.useState([]);
  const [idGroup, setIdGroup] = React.useState("");
  const [openAddGr, setOpenAddGr] = React.useState(false);
  const [openAddCompany, setOpenAddCompany] = React.useState(false);
  const [stateGroups2, setstateGroups2] = React.useState([]);
  const [nameCompany, setNameCompany] = React.useState("");
  const [nameGroup, setNameGroup] = React.useState([]);

  React.useEffect(() => {
    getDataGroup();
  }, []);

  const getDataGroup = () => {
    let token = localStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    const URL = `${process.env.REACT_APP_URL}api/roles/group`;
    axios
      .get(URL, config)
      .then((res) => {
        const objectData = res.data;
        console.log("data1", objectData);

        var arrayitemData = [];
        for (let item in objectData) {
          console.log("ITEMDATA", objectData[item]);
          arrayitemData.push(objectData[item]);
        }

        arrayitemData.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");

          return item;
        });
        setArrayData(arrayitemData);

        console.log("DataGroups", objectData[`Correduría 82`]);
        const NewArrayKeys = Object.keys(objectData);
        console.log("dataKeys", NewArrayKeys);

        setstateGroups2(objectData);
        const itemda = itemCompany(arrayitemData);

        // var newObject = Array.of.apply( NewArrayKeys)
        // console.log("RESULTADOS", result);
      })
      .catch((error) => {
        alert("se produjo un error al consultar los grupos");
        console.error(error);
      });
  };

  const handleOpenDialogUser2 = () => {
    setDialogUsers2(false);
  };

  const handleOpenGroups = (data) => {
    console.log("dataUser121", data);
    setNameCompany(data.name);

    data.groups.map(function (item) {
      item.created_at = moment(item.created_at).format("DD-MM-YYYY");

      return item;
    });
    setdataGroups(data.groups);
    setActiveRender(1);
  };

  const itemCompany = (data) => {
    console.log("dataUSersitme", data);
    // const values = data.map((item) => [
    //  item
    // ]);

    // return values;
  };

  const handleEditGroup = (data) => {
    setIdGroup(data.id);
    setDialogEdit(true);
  };

  const handleopenunit = (data) => {
    setActiveRender(2);
    console.log("DATAUSERGRUOP", data);
    setNameGroup(data.id);
    setdataUsers2(data.users);
  };
  const itemUsers2 = (data) => {
    console.log("dataUSersitme");
    const values = Object.values(data).map((item) => [
      item.name,
      item.id,
      item.email,
    ]);

    return values;
  };

  const HandleSigUp = (data) => {
    console.log("DATAGRUPOPOO", data);
    setDialogUsers(true);
  };

  const addCompany = () => {
    setOpenAddCompany(true);
  };

  const OpenAddGroup = () => {};

  return (
    <Container>
      {activeRender === 0 ? (
        <Grid>
          <MaterialTable
            localization={localization}
            title={`Compañias (${arrayData.length})`}
            columns={[
              { title: "Nombre", field: "name" },

              { title: "Fecha de creación", field: "created_at" },
              { title: "N° Grupos", field: "group_count" },
            ]}
            data={arrayData}
            actions={[
              {
                icon: "add",
                tooltip: "Agregar Compañia",
                isFreeAction: true,
                onClick: (event) => addCompany(),
              },

              (rowData) => ({
                icon: () => (
                  <PeopleIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Ver Grupos",
                onClick: (event, rowData) => {
                  handleOpenGroups(rowData);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <EditIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Editar usuario",
                // onClick: (event, rowData) => handleEditGroup(rowData),
              }),
            ]}
            options={{
              pageSize: 12, // make initial page size
              pageSizeOptions: [20, 50, 100],
              actionsColumnIndex: -1,
              exportButton: true,
            }}
          />
        </Grid>
      ) : activeRender === 1 ? (
        <Grid>
          <Button
            size="large"
            className={classes.button}
            startIcon={<ArrowBackIcon />}
            onClick={() => setActiveRender(0)}
          >
            Regresar
          </Button>
          <MaterialTable
            localization={localization}
            title={`Grupos (${dataGroups.length})`}
            columns={[
              { title: "Nombre ", field: "name" },
              { title: "ID", field: "id" },
              { title: "Fecha de creación", field: "created_at" },
              { title: "Descripcion", field: "description" },
              { title: "N° de usuarios", field: "users.length" },
            ]}
            data={dataGroups}
            actions={[
              {
                icon: "add",
                tooltip: "Agregar grupo",
                isFreeAction: true,
                onClick: (event) => setOpenAddGr(true),
              },
              (rowData) => ({
                icon: () => (
                  <LockOpenIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: " Ver permisos",
                // onClick: (event, rowData) =>
                //   handleOpenFirmantesRechazados(rowData),
              }),
              (rowData) => ({
                icon: () => (
                  <PeopleIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Ver usuarios",
                onClick: (event, rowData) => handleopenunit(rowData),
              }),
              (rowData) => ({
                icon: () => (
                  <EditIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Editar usuario",
                onClick: (event, rowData) => handleEditGroup(rowData),
              }),
            ]}
            options={{
              pageSize: 5, // make initial page size
              pageSizeOptions: [5, 10, 20, 500],
              actionsColumnIndex: -1,
              exportButton: true,
            }}
          />
        </Grid>
      ) : activeRender === 2 ? (
        <Grid>
          <Button
            size="large"
            className={classes.button}
            startIcon={<ArrowBackIcon />}
            onClick={() => setActiveRender(1)}
          >
            Regresar
          </Button>
          <MaterialTable
            localization={localization}
            title={`Usuarios (${dataUsers2.length})`}
            columns={[
              { title: "Nombre ", field: "name" },
              { title: "ID", field: "id" },
              { title: "Correo electronico", field: "email" },
            ]}
            data={dataUsers2}
            actions={[
              {
                icon: "add",
                tooltip: "Agregar usuario",
                isFreeAction: true,
                onClick: (event, rowData) => HandleSigUp(rowData),
              },
              (rowData) => ({
                icon: () => (
                  <EditIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Editar usuario",
                onClick: (event, rowData) => handleEditGroup(rowData),
              }),
            ]}
            options={{
              pageSize: 5, // make initial page size
              pageSizeOptions: [5, 10, 20, 500],
              actionsColumnIndex: -1,
              exportButton: true,
            }}
          />
        </Grid>
      ) : (
        ""
      )}

      {/* Dialogo para ver cambiar logo y urii de los grupos */}
      <Container>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={dialogEdit}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Editar grupo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <EditGroup setDialogEdit={setDialogEdit} idGroup={idGroup} />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogEdit(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {/* Dialogo para registrar un usuario */}
      <Container>
        <Dialog
          // fullWidth={true}
          maxWidth="md"
          open={dialogUsers}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registrar usuario"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <AddUsers
                  nameCompany={nameCompany}
                  nameGroup={nameGroup}
                  getDataGroup={getDataGroup}
                  setDialogUsers={setDialogUsers}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogUsers(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {/* Dialogo para registrar un grupo */}
      <Container>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openAddGr}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registrar grupo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <AddGroup
                  setOpenAddGr={setOpenAddGr}
                  nameCompany={nameCompany}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAddGr(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {/* Dialogo para registrar una compañia */}
      <Container>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openAddCompany}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registrar grupo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <AddCompany getDataGroup={getDataGroup} />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAddCompany(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Container>
  );
}
