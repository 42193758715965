import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CargaDocumento from "containers/FirmaAvanzada/CargaDocumento";
import VistaPrevia from "containers/FirmaAvanzada/VistaPrevia";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/fondoblack.jpg";
import logo from "assets/img/ConCertezaGris.png";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//credenciales API CDO
const username = "ce2af9cab947ed15288e058ecd5350f5";
const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
//const PROXY_URL = 'https://sistemas.lemperroni.com/';
const PROXY_URL = "";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "30%",
      marginRight: "auto",
      marginTop: "13%",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  divacciones: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  footer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
}));

function getSteps() {
  return ["Carga documento", "Vista previa", "Genera constancia"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Cargar el Documento";
    case 1:
      return "Vista Previa";
    case 2:
      return "Generar Constancia";
    default:
      return "Unknown stepIndex";
  }
}

export default function Flujo() {
  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  //layout
  const classes = useStyles();
  const classes2 = useStyles2();
  const mainPanel = React.createRef();
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [paperWidth, setPaperWidth] = React.useState("50%");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pdf, setPDF] = React.useState("");
  const [archivo, setArchivo] = React.useState("");
  const [constancia, setConstancia] = React.useState("");
  const [mensajeError, setMensajeError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");
  const [colorAlert, setColorAlert] = React.useState("info");

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageErrorSnack, setMessageErrorSnack] = React.useState("");
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const openAlerta = (error) => {
    setOpenSnack(true);
    setMessageErrorSnack(error);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //steps
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // initialize and destroy the PerfectScrollbar plugin

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    if (isMobile) {
      setPaperWidth("95%");
    }
  }, []);



  //API SOLICITUD DE CONSTANCIA CDO
  function makeGetRequestConstancia() {
    const URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud";

    const data = new FormData();
    //data.append('contrato', fs.createReadStream('/path/to/file'));
    data.append("archivo", archivo);
    data.append("descripcion", "Constancia NOM151 -" + archivo.name);
    console.log("DATAbodyfirmante", data);

    var urlDocumento = PROXY_URL + URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          setMensajeError(true);
        }
      );
    });
  }

  //API CREAR DOCUMENTO EN CON-CERTEZA
  function makeGetRequestDocumentoCC() {
    const URL = `${process.env.REACT_APP_URL}api/documentos`;
    let tokenCC = localStorage.getItem("Token");

    const data = new FormData();
    data.append("filename", archivo);
    data.append("descripcion", archivo.name);
    data.append("documento_types_id", "5");
    //data.append('cdo_contratos_id', contratocdo);
    //console.log("DATAbodyfirmante", data);

    var urlDocumento = URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          setMensajeError(true);
          //openAlerta("Imposible cargar el documento debido a que ha sido registrado con anterioridad");
          setColorAlert("danger");
          showNotificacion("Imposible cargar el documento debido a que ha sido registrado con anterioridad");

        }
      );
    });
  }

  function makeUpdateDocumentoCC(idDocumento, constanciaCDO) {
    let tokenCC = `${process.env.REACT_APP_TOKEN}`;
    let newStatus = 5;
    //let constancia=JSON.stringify(constanciaCDO);
    //let constancia =`{"id" : "${constanciaCDO.id}", "hash" : "${constanciaCDO.hash}", "datetime" : "${constanciaCDO.datetime}", "descripcion" : "${constanciaCDO.descripcion}", "originalname" : "${constanciaCDO.originalname}"}`
    let constancia = `{"id" : "${constanciaCDO.id}", "hash" : "${constanciaCDO.hash}", "datetime" : "${constanciaCDO.datetime}", "descripcion" : "${constanciaCDO.descripcion}", "originalname" : "${constanciaCDO.originalname}"}`;

    /*
        let constancia = `{\n    "id": "${constanciaCDO.id}",\n    "hash": "${
          constanciaCDO.hash
        }",\n    "datetime": "${constanciaCDO.datetime}",\n    "descripcion": "${
          constanciaCDO.descripcion
        }",\n    "originalname": ${constanciaCDO.originalname}\n}`;
*/
    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocumento}?documento_status_id=${newStatus}&constancia=${constancia}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function registra() {
    //se crea el ducumento en con certeza
    var resultCC = await makeGetRequestDocumentoCC();
    console.log("RESULTCC:", resultCC);

    if (resultCC) {
      //se genera la constancia desde CDO
      var result = await makeGetRequestConstancia();
      console.log("CONSTANCIA:", result);
      setConstancia(result);
      console.log("Constancia CDO OK");



      //se actualiza el documento creado en CC con su constancia
      makeUpdateDocumentoCC(resultCC[1]._id, result);
      console.log("FIN DEL PROCESO");
    }
    /*
        setContrato(result);
        console.log('Contrato OK');
        var resultCC = await makeGetRequestContratoCC(result.id);
        console.log("RESULTCC:",resultCC);
        setContratoCC(resultCC);
      */
    handleNext();
  }

  const handleRegistra = () => {
    registra();
  };

  const handleReset = () => {
    goTop();
    setActiveStep(0);
    setPDF("");
  };

  const handleDescargar = () => {
    //////////////////////////////////
    var nombre_archivo = constancia.descripcion + ".zip";
    var URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud/" +
      constancia.id +
      "/descargar";

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
    ////////////////////////////////////
    setActiveStep(0);
    setPDF("");
  };

  const handleGenerar = () => {
    goTop();
    showNotificacion(
      "Generando Constancia de Conservación, por favor espere un momento"
    );
    registra();
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  return (
    <React.Fragment>
      {/*<div className={classes.root}>*/}
      <div style={{ marginTop: "100px" }} ref={ref}>
        <Grid
          style={{
            marginTop: "10%",
            width: paperWidth,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Paper>
            <Typography variant="h4" align="center">
              Constancia de Conservación
            </Typography>
            <Typography align="center">
              Generar Constancia de Conservación
            </Typography>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : null}
            {/*
              <div
                style={{
                  display: 'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  marginLeft:"25%"
                }}
              >
              */}
            {activeStep === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "25%",
                }}
              >
                <CargaDocumento
                  handleNext={handleNext}
                  setPDF={setPDF}
                  setArchivo={setArchivo}
                />
              </div>
            ) : activeStep === 1 ? (
              <div style={{ width: "95%" }}>
                <VistaPrevia handleNext={handleNext} pdf={pdf} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerar}
                  style={{
                    float: "right",
                    marginBottom: "10%",
                  }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Generar constancia
                  </Typography>
                </Button>
                <Button
                  onClick={handleReset}
                  style={{
                    float: "right",
                    marginBottom: "10%",
                  }}
                >
                  <Typography
                    variant="button"
                    style={{ textTransform: "none", color: "red" }}
                  >
                    Cancelar
                  </Typography>
                </Button>
              </div>
            ) : (
              <div>
                <Typography variant="h6" align="center">
                  Su constancia ha sido generada
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDescargar}
                  style={{
                    marginLeft: "40%",
                  }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Descargar
                  </Typography>
                </Button>
              </div>
            )}
            <br />
            <br />
            <br />
          </Paper>
        </Grid>
      </div>

      <Snackbar
        place="tc"
        color={colorAlert}
        icon={InfoIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => {
          setOpenAlert(false)
          setColorAlert("info")
        }}
        close
      />

    </React.Fragment>
  );
}
