import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
//import ResizableRect from 'react-resizable-rotatable-draggable'
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import HelpIcon from "@material-ui/icons/Help";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import BackspaceIcon from "@material-ui/icons/Backspace";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import UndoIcon from "@material-ui/icons/Undo";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import LogoFirma from "./LogoFirma";
import CuadroFirma from "./CuadroFirma";
import rectangle from "./rectangle-firma.png";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UbicarFirma(props) {
  const inputRef = useRef();
  const [tokenstate, setTokenstate] = React.useState("");
  //const [personalizada, setPersonalizada] = React.useState(false);
  //const personalizar = () => setPersonalizada(personalizada => !personalizada);
  const idDocumento = props.idDocumento;
  const nombre = props.nombre;
  const setCoordenadasFirma = props.setCoordenadasFirma;
  const topmt = props.topmt;

  const [base64, setbase64] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [marginTop, setMarginTop] = React.useState(600);
  //referencia a la distancia a la izq del DIV
  const [left, setLeft] = React.useState(0);
  const [disableFijar, setDisableFijar] = React.useState(true);
  const [defx, setDefx] = React.useState(0);
  const [defaultpos, setDefaultpos] = React.useState(true);

  //almacena la coordenadas de la última posisición de la firma
  const [coordenadas, setCoordenadas] = useState({
    page: 0,
    x: 0,
    y: 0,
    screenX: 0,
    screenY: 0,
    w: 0,
    h: 0,
    top: 0,
    left: 0,
    nombre: "",
    height: 0,
    width: 0,
  });

  //almacena las firmas del firmante actual
  const [ubicacionFirma, setUbicacionFirma] = React.useState([
    {
      page: 0,
      x: 0,
      y: 0,
      screenX: 0,
      screenY: 0,
      w: 0,
      h: 0,
      top: 0,
      left: 0,
      nombre: "",
      height: 0,
      width: 0,
    },
  ]);

  //almacena todas las firmas configuradas en el documento
  const [ubicacionFirmaHistoria, setUbicacionFirmaHistoria] = React.useState([
    {
      page: 0,
      x: 0,
      y: 0,
      screenX: 0,
      screenY: 0,
      w: 0,
      h: 0,
      top: 0,
      left: 0,
      nombre: "",
      height: 0,
      width: 0,
    },
  ]);

  React.useEffect(() => {
    const rect = inputRef.current.getBoundingClientRect();
    console.log("Ubicación PDF:");
    console.log(rect);
    setLeft(rect.left);
    //var localY = event.clientY

    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
      setMarginTop(400);
    }
    let token = localStorage.getItem("Token");
    setTokenstate(token);

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [base64]);

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function clickFijar() {

    if (props.nuevoFirmante) {
      props.setNuevoFirmante(false);
      setUbicacionFirma([
        {
          page: 0,
          x: 0,
          y: 0,
          screenX: 0,
          screenY: 0,
          w: 0,
          h: 0,
          top: 0,
          left: 0,
          nombre: "",
          height: 0,
          width: 0,
        },
      ]);
      setUbicacionFirma([coordenadas]);
      setCoordenadasFirma([coordenadas]);
    } else {
      //variable local
      setUbicacionFirma([...ubicacionFirma, coordenadas]);
      //envio al padre
      setCoordenadasFirma([...ubicacionFirma, coordenadas]);
    }

    setUbicacionFirmaHistoria([...ubicacionFirmaHistoria, coordenadas]);

    /*ORIGINAL
      //variable local
      setUbicacionFirma([...ubicacionFirma, coordenadas]);
      //envio al padre
      setCoordenadasFirma([...ubicacionFirma, coordenadas]);
*/
  }

  function clickLimpiar() {
    //borra las firmas del firmante actual
    setCoordenadas({
      page: 0,
      x: 1,
      y: -63,
      screenX: 0,
      screenY: 0,
      w: 0,
      h: 0,
      top: 0,
      left: 0,
      nombre: "",
      height: 0,
      width: 0,
    });
    setUbicacionFirma([
      {
        page: 0,
        x: 1,
        y: -63,
        screenX: 0,
        screenY: 0,
        w: 0,
        h: 0,
        top: 0,
        left: 0,
        nombre: "",
        height: 0,
        width: 0,
      },
    ]);
    const newList = ubicacionFirmaHistoria.filter(
      (name) => name.nombre !== nombre
    );
    setUbicacionFirmaHistoria(newList);

    //borra todo
    //setUbicacionFirma([{}])
    //setCoordenadas({})
  }

  function Firmas() {
    //let result = ubicacionFirma.filter(det => det.pagina = 1);
    //{ubicacionFirma.map((detalle, index) =>
    if (ubicacionFirmaHistoria.length > 1) {
      return (
        <div>
          {ubicacionFirmaHistoria
            .filter((name) => name.page === pageNumber)
            .map((detalle, index) => (
              <LogoFirma
                key={index}
                src={rectangle}
                w={detalle.w - 25}
                h={detalle.h - 25}
                coordenadas={{ x: detalle.screenX + 10, y: detalle.screenY }}
                left={detalle.left}
                bottom={detalle.top}
                texto={detalle.nombre}
                height={detalle.height}
                width={detalle.width}
                mobile={isMobile()}
                topmt={topmt}
              />
            ))}
        </div>
      );
    }
    return <div></div>;
  }

  return (
    <React.Fragment>
      <hr />
      <Grid>
        {/*
        <input
          type="checkBox"
          checked={personalizada}
          onClick={personalizar}
          onChange={() => console.log("Changed!")}
        />
        */}
        Fijar la ubicación de la Firma
        <Tooltip
          title="Clic para arrastrar y mover recuadro a la posición deseada, luego clic para soltar y presione el botón FIJAR. Presione DESHACER si desea cambiar la última ubicación"
          aria-label="Mover recuadro"
          placement="right"
        >
          <HelpOutlineIcon />
        </Tooltip>
        <br />
        {/*
        <Button
          color="danger" size="sm"
          type="button"
          onClick={clickLimpiar}
        >
          <BackspaceIcon /> Borrar
        </Button>
*/}
        <Button color="info" size="sm" type="button" onClick={clickLimpiar}>
          <UndoIcon /> Borrar
        </Button>
        <Button
          color="success"
          size="sm"
          type="button"
          disabled={disableFijar}
          onClick={clickFijar}
        >
          <LibraryAddCheckIcon />
          Fijar
        </Button>
        <br />
        <font color="red">Firmas en el Documento:</font>
        {ubicacionFirmaHistoria.length - 1}
        <div className="text-center">
          <p>
            Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
          </p>
          <button
            className="btn btn-dark"
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Atrás
          </button>{" "}
          <button
            className="btn btn-dark"
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Siguiente
          </button>
        </div>
        <hr />
        <div ref={inputRef}>
          <Document
            error="Error al intentar cargar el PDF"
            loading="Cargando..."
            file={`data: application / pdf; base64, ${base64} `}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              //size="LEGAL"
              scale={1}
              height={pdfHeigth}
              width={pdfWidth}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          </Document>
          <CuadroFirma
            pagina={pageNumber}
            defx={defx}
            defy={marginTop}
            left={left}
            setCoordenadas={setCoordenadas}
            nombre={nombre}
            setDisableFijar={setDisableFijar}
            //setDefaultpos={setDefaultpos}
            //defaultpos={defaultpos}
          />
        </div>
      </Grid>
      <Firmas />
    </React.Fragment>
  );
}

export default UbicarFirma;
