import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
// import Card from "components/Card/Card.js";

// import CardHeader from "components/Card/CardHeader.js";
// import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Close from "@material-ui/icons/Close";

import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PauseIcon from "@material-ui/icons/Pause";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";

import Backdrop from "@material-ui/core/Backdrop";

import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

//alert
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import GestureIcon from "@material-ui/icons/Gesture";
import PeopleIcon from "@material-ui/icons/People";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylestable = makeStyles(styles2);
const useStyles = makeStyles(styles);

export default function TableList() {
  const classes2 = useStylestable();
  const classespro = useStylesprogrees();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogInfo, setOpenDialogInfo] = React.useState(false);
  const [openDialogFirmantes, setOpenDialogFirmantes] = React.useState(false);
  const [openZip, setOpenZip] = React.useState(false);
  const [dataFirmados, setDataFirmados] = React.useState([]);
  const [dataEnProceso, setDataEnProceso] = React.useState([]);
  const [dataRechazados, setDataRechazados] = React.useState([]);
  const [base64, setbase64] = React.useState("");
  const [DeleteID, setDeleteID] = React.useState("");
  const [colorHeader, setColorHeader] = React.useState("success");

  //data individual de documento
  const [dataDocu, setDataDocu] = React.useState({});

  const [open, setOpen] = React.useState(true);
  const [tokenstate, setTokenstate] = React.useState("");

  const [docuData, setDocuData] = React.useState([]);

  const [firmantes, setFirmantes] = React.useState([]);

  React.useEffect(() => {
    async function getdata() {
      let token = localStorage.getItem("Token");

      datatable(token);
      docuFirmados();
      docuProceso();
      docuRegister();
    }
    getdata();
  }, []);

  const docuRegister = () => {
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/status/4`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        console.log("Data Rechazados ", resp.data);
        const ok = itemsDocuRegistrado(resp.data);
        setDataRechazados(ok);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const docuFirmados = () => {
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/status/3`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        console.log("Data firmados ", resp.data);
        const ok = itemsDocuFirmados(resp.data);
        setDataFirmados(ok);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };
  const docuProceso = () => {
    let token = localStorage.getItem("Token");
    const endpoint = `${process.env.REACT_APP_URL}api/documentos/status/2`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        console.log("Data en proces ", resp.data);
        const ok = itemsDocuProceso(resp.data);
        setDataEnProceso(ok);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };
  // funcion  que crear la data de documentos firmados
  const itemsDocuFirmados = (data) => {
    const values = Object.values(data).map((item) => [
      item.filename,
      item._id,
      moment(item.created_at, "YYYY-MM-DD").format("DD-MM-YYYY"),

      <Grid>
        <Tooltip
          id="tooltip-top"
          title="Firmantes"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <PeopleIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenFirmantes(item);
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="Información"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <VisibilityIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenInfo(item);
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="Visualizar"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <PictureAsPdfIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                getZipDocument(item._id);
              }}
            />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          id="tooltip-top-start"
          title="Eliminar"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton
            aria-label="Close"
            className={classes2.tableActionButton}
            // onClick={() => {
            //   handleClickOpen(item._id);
            // }}
          >
            <Close
              className={classes2.tableActionButtonIcon + " " + classes2.close}
            />
          </IconButton>
        </Tooltip> */}
      </Grid>,
    ]);
    return values;
  };

  //funcion para descargar el paquete de constancia del documentos

  const getZipDocument = (idDocu) => {
    setOpenZip(true);

    let token = localStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocu}/bundle/download`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "response.zip");
      document.body.appendChild(link);
      link.click();
      setOpenZip(false);
    });
  };

  //funcion para crear la data de datos Documentos en PROCESO

  const itemsDocuProceso = (data) => {
    const values = Object.values(data).map((item) => [
      item.filename,
      item._id,
      moment(item.created_at, "YYYY-MM-DD").format("DD-MM-YYYY"),
      <Grid>
        <Tooltip
          id="tooltip-top"
          title="Firmantes"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <PeopleIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                openFirmantesProcess(item);
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="Información"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <VisibilityIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenInfo(item);
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="Visualizar"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <PictureAsPdfIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenPDF(item._id);
              }}
            />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          id="tooltip-top-start"
          title="Eliminar"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton
            aria-label="Close"
            className={classes2.tableActionButton}
            // onClick={() => {
            //   handleClickOpen(item._id);
            // }}
          >
            <Close
              className={classes2.tableActionButtonIcon + " " + classes2.close}
            />
          </IconButton>
        </Tooltip> */}
      </Grid>,
    ]);

    console.log("values en proceso", values);
    return values;
  };

  const itemsDocuRegistrado = (data) => {
    const values = Object.values(data).map((item) => [
      item.filename,
      item._id,
      moment(item.created_at, "YYYY-MM-DD").format("DD-MM-YYYY"),
      <Grid>
        <Tooltip
          id="tooltip-top"
          title="Firmantes"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <PeopleIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenFirmantes(item);
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="Información"
          placement="top"
          classes={{ tooltip: classes2.tooltip }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <VisibilityIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenInfo(item);
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="Visualizar"
          placement="top"
          classes={{ tooltip: classes2.tooltip }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <PictureAsPdfIcon
              onClick={() => {
                handleOpenPDF(item._id);
              }}
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              // onClick={() => {
              //   handleOpenPDF(item._id);
              // }}
            />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          id="tooltip-top-start"
          title="Eliminar"
          placement="top"
          classes={{ tooltip: classes2.tooltip }}
        >
          <IconButton
            aria-label="Close"
            className={classes2.tableActionButton}
            // onClick={() => {
            //   handleClickOpen(item._id);
            // }}
          >
            <Close
              className={classes2.tableActionButtonIcon + " " + classes2.close}
            />
          </IconButton>
        </Tooltip> */}
      </Grid>,
    ]);
    return values;
  };

  const datatable = async (token) => {
    setTokenstate(token);
    const endpoint = `${process.env.REACT_APP_URL}api/documentos`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        console.log("repuesta table", resp.data);
        const ok = itemsDocu(resp.data);
        setDocuData(ok);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    console.log("id close", DeleteID);
  };
  const handleClickOpen = (id) => {
    setOpenDialog(true);
    setDeleteID(id);
    console.log("id open", id);
  };

  const handleOpenInfo = (data) => {
    setOpenDialogInfo(true);

    const ok = itemFirmante(data.firmantes);
    setFirmantes(ok);
    console.log("data open info", ok);
    setDataDocu(data);
  };

  const handleOpenFirmantes = (data) => {
    setOpenDialogFirmantes(true);

    const ok = itemFirmante(data.firmantes);

    setFirmantes(ok);
    console.log("data open info1 ", ok);
    setDataDocu(data);
  };

  const openFirmantesProcess = (data) => {
    setOpenDialogFirmantes(true);

    const ok = itemFirmante(data.firmantes);

    if (ok.length === 1) {
      const ok = itemFirmante(data.firmantes);

      const firmante1 = ok[0];

      if (firmante1[3] === "Invalid date") {
        delete firmante1[3];
        const countfir = firmante1.push("No ha firmado");
        console.log("data open info2", countfir);
      }
      setFirmantes(ok);
      console.log("data open info1 ", ok);
      setDataDocu(data);
    } else if (ok.length === 2) {
      const firmante1 = ok[0];
      const firmante2 = ok[1];
      if (firmante1[3] === "Invalid date" && firmante2[3] === "Invalid date") {
        delete firmante1[3];
        delete firmante2[3];
        const countfir = firmante1.push("No ha firmado");
        const countfir2 = firmante2.push("No ha firmado");
        console.log("data open info2", countfir, countfir2);
      } else if (firmante2[3] === "Invalid date") {
        delete firmante2[3];
        const countfir2 = firmante2.push("No ha firmado");

        setFirmantes(ok);
        console.log("data open info", ok);
        setDataDocu(data);
      }

      setFirmantes(ok);
      console.log("data final", ok);
      setDataDocu(data);
    }
  };

  const itemFirmante = (data) => {
    const values = Object.values(data).map((item) => [
      item.name,
      item.last_name,
      item.role,
      moment(item.date_signed).format("DD-MM-YYYY LT "),
      "MX",
    ]);

    return values;
  };

  const handleCloseInfo = () => {
    setOpenDialogInfo(false);
  };
  const handleCloseFirmante = () => {
    setOpenDialogFirmantes(false);
  };

  const handleOpenPDF = async (idDocu) => {
    setOpenDialog(true);

    const token = localStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/documentos/${idDocu}/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setbase64(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleclosepdf = () => {
    setOpenDialog(false);
    setbase64("");
  };

  const handlecambiar = () => {
    console.log("holasi funcione");
  };

  //funcion que crea la data de la tabla
  const itemsDocu = (data) => {
    const values = Object.values(data).map((item) => [
      item.filename,
      item._id,
      moment(item.created_at, "YYYY-MM-DD").format("DD-MM-YYYY"),
      <Grid>
        <Tooltip
          id="tooltip-top"
          title="Información"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <VisibilityIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenInfo(item);
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-top"
          title="Visualizar"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton aria-label="Edit" className={classes2.tableActionButton}>
            <PictureAsPdfIcon
              className={classes2.tableActionButtonIcon + " " + classes2.edit}
              onClick={() => {
                handleOpenPDF(item._id);
              }}
            />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          id="tooltip-top-start"
          title="Eliminar"
          placement="top"
          classes={{
            tooltip: classes2.tooltip,
          }}
        >
          <IconButton
            aria-label="Close"
            className={classes2.tableActionButton}
            onClick={() => {
              handleClickOpen(item._id);
            }}
          >
            <Close
              className={classes2.tableActionButtonIcon + " " + classes2.close}
            />
          </IconButton>
        </Tooltip> */}
      </Grid>,
    ]);
    return values;
  };

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        {dataFirmados.length === 0 && dataEnProceso.length === 0 ? (
          <Backdrop className={classespro.backdrop} open={open}>
            <CircularProgress size={44} className={classespro.buttonProgress} />
          </Backdrop>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title="Documentos:"
              headerColor={colorHeader}
              tabs={[
                {
                  tabName: "firmados",
                  tabIcon: GestureIcon,
                  tabContent: (
                    <div>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={[
                          "Nombre",
                          "ID",
                          "Fecha de creacion",
                          "Acciones",
                        ]}
                        tableData={dataFirmados}
                      />
                    </div>
                  ),
                },
                {
                  tabName: "En espera ",
                  tabIcon: PauseIcon,
                  tabContent: (
                    <div>
                      {" "}
                      <Table
                        tableHeaderColor="primary"
                        tableHead={[
                          "Nombre",
                          "ID",
                          "Fecha de creacion",
                          "Acciones",
                        ]}
                        tableData={dataEnProceso}
                      />
                    </div>
                  ),
                },
                {
                  tabName: "Rechazados",
                  tabIcon: CancelIcon,
                  tabContent: (
                    <div>
                      {" "}
                      <Table
                        tableHeaderColor="primary"
                        tableHead={[
                          "Nombre",
                          "ID",
                          "Fecha de creacion",
                          "Acciones",
                        ]}
                        tableData={dataRechazados}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </GridItem>

          // <GridItem xs={12} sm={12} md={12}>
          //   <Card>
          //     <CardHeader color="success">
          //       <h4 className={classes.cardTitleWhite}>Todos tus documentos</h4>
          //       <p className={classes.cardCategoryWhite}>
          //         Aqui se muestran todos los documentos que tienes Rechazados
          //       </p>
          //     </CardHeader>
          //     <CardBody>
          //       <Table
          //         tableHeaderColor="primary"
          //         tableHead={["Nombre", "ID", "Fecha de creacion", "Acciones"]}
          //         tableData={docuData}
          //       />
          //     </CardBody>
          //   </Card>
          // </GridItem>
        )}
        ;{/* Dialogo de para ver los pdf */}
        <div>
          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            // onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {"Tu documento"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {base64.length === 0 ? (
                  <Backdrop className={classespro.backdrop} open={open}>
                    <CircularProgress
                      size={44}
                      className={classespro.buttonProgress}
                    />
                  </Backdrop>
                ) : (
                  <iframe
                    src={`data:application/pdf;base64,${base64}`}
                    type=" application / pdf "
                    width="500px"
                    height="400"
                  ></iframe>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleclosepdf} color="success">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* Dialogo de ver datos del documento */}
        <div>
          <Dialog
            open={openDialogInfo}
            TransitionComponent={Transition}
            keepMounted
            // onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {"Información del documento"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Typography>
                  <div>
                    Nombre del documento: {dataDocu.filename}
                    <br></br>
                    <hr></hr>
                    Hash: {dataDocu.hash}
                    <br></br>
                    <hr></hr>
                    ID el documento: {dataDocu._id}
                    <br></br>
                    <hr></hr>
                    Tamaño de documento: {dataDocu.size}
                    <hr></hr>
                  </div>
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseInfo} color="success">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* Dialogo de ver datos de los firmantes */}
        <div>
          <Dialog
            open={openDialogFirmantes}
            TransitionComponent={Transition}
            keepMounted
            // onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {"Información de los firmantes"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Typography>
                  <div>
                    <div>
                      <Table
                        tableHeaderColor="success"
                        tableHead={[
                          "Nombre",
                          "Apellidos",
                          "Actuación",
                          "Firma",
                        ]}
                        tableData={firmantes}
                      />
                    </div>
                  </div>
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFirmante} color="success">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* Dialogo de espera de descarga de paquete de documentos firmados */}
        <div>
          <Dialog
            open={openZip}
            TransitionComponent={Transition}
            keepMounted
            // onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {"Descarga de documento"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Typography>
                  La carpeta con los documentos firmados se descargaran al lugar
                  habitual de Descargas: Esto puede tardar unos segundos...
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  {" "}
                  <CircularProgress
                    size={44}
                    className={classespro.buttonProgress2}
                  />
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </GridContainer>
    </div>
  );
}
