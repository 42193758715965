import axios from "axios";
import qs from "querystring";

let _token = null;

/**
 * GetToken singleton.
 * Returns JWT token, according to microservice.
 */
const GetToken = function () {
  if (_token !== null && typeof _token === "string" && _token.length > 0)
    return _token;
  _token = localStorage.getItem("Token");
  return _token;
};

const http = axios.create({
  baseURL: process.env.REACT_APP_URL,
  withCredentials: true,
  crossDomain: true,
});

http.defaults.withCredentials = true;
http.defaults.crossDomain = true;

const HttpProvider = {
  async Fetch(
    method,
    url,
    data = undefined,
    contentType = "application/json",
    usePublicToken = false
  ) {
    /**
     * If not protocol is providen, we can assume that
     * CDO microservice is going to be used.
     */
    if (url.indexOf("http") != 0) {
      url = process.env.REACT_APP_URL + url;
    }

    const token = usePublicToken ? process.env.REACT_APP_TOKEN : GetToken();
    http.defaults.headers.Authorization = `Bearer ${token}`;
    http.defaults.headers.Accept = "application/json";
    http.defaults.headers["Content-Type"] = contentType;
    http.defaults.withCredentials = true;
    http.defaults.crossDomain = true;

    try {
      const response = await http[method.toLowerCase()](url, data);

      return response.data;
    } catch (e) {
      // Ignorar errores 401, Unauthorized.
      if (e.message.includes("401")) {
        throw e;
      }
      alert("HTTP error:" + e.message);
      throw e;
    }
  },
};

export default HttpProvider;
