import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import axios from "axios";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Store from "@material-ui/icons/Store";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import CONSTANCIA from "assets/img/CONSTANCIA_LOGO.png";
import { useHistory } from "react-router-dom";
import firmasimple from "assets/img/firmaSimple2.png";
import firmacerti from "assets/img/NEWFIRMACONIDE.png";
import firmaAvanzada from "assets/img/firmaFiel.png";
import serviciosLegales from "assets/img/Legales.png";
import DateRange from "@material-ui/icons/DateRange";
import AUTOFIRMA from "assets/img/logoAutoFirma.png";
import vistoBueno from "assets/img/vistoBueno.png";
import FIRMARAPIDA from "assets/img/firmasincodigo.png";
import Typography from "@material-ui/core/Typography";
import Checkout from "containers/CheckoutNew";
import Tooltip from "@material-ui/core/Tooltip";
import Components from "containers/Nom151/Flujo.js";
import ContainerAutoSign from "containers/autoSign/autoSingContainer";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import VistoBuenoContainer from "containers/vistoBueno/vistoBuenoContainer";
//checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
//*libreria para reproducir video
import ReactPlayer from "react-player";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

const useStylespro = makeStyles((theme) => ({
  Textfound: {
    fontFamily: "Helvetica",
    color: "#000000",
  },
  Imagen: {
    margin: "6px 12px !important",
  },
  top: {
    marginTop: "30px",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const classestext = useStylespro();
  const history = useHistory();
  const [idflujo, setIdFlujo] = React.useState(0);
  const [activeRender, setActiveRender] = React.useState(0);
  const [openVideo, setOpenVideo] = React.useState(true);
  const [state, setState] = React.useState(false);
  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    let Video1 = localStorage.getItem("Video1");
    let NoVideo = localStorage.getItem("NoVideo");
    handleRoles();
    console.log("VIDEO1", Video1);
    if (Video1 == "true" || NoVideo == "true") {
      setOpenVideo(false);
    }
  }, []);

  const handleRoles = () => {
    let token = localStorage.getItem("Token");

    axios
      .get(`${process.env.REACT_APP_URL}api/roles/group`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = Object.values(res.data);
        console.log("DATAROLES", data);
        setRoles(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleflowSign = (id) => {
    console.log("idFLujo", id);
    setActiveRender(1);
    setIdFlujo(id);
  };

  const handleVistobueno = (id) => {
    setActiveRender(4);
    setIdFlujo(id);
  };
  const handleAutoDeclaracion = () => {
    setActiveRender(3);
  };

  const handleChange = (event) => {
    setState(event.target.checked);
  };

  const handleCloseVideo = () => {
    if (state == true) {
      localStorage.setItem("NoVideo", true);
    }
    setOpenVideo(false);

    localStorage.setItem("Video1", true);
  };
  return (
    <div>
      {activeRender === 0 ? (
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ textTransform: "none", marginLeft: 18 }}
            >
              Selecciona el tipo de proceso de firma.
              <br />
            </Typography>
          </GridItem>

          {/* <GridItem xs={12} sm={6} md={3}>
              <CardHeader stats icon onClick={handleServiciosLegales}>
                <CardIcon color="danger" onClick={handleServiciosLegales}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ cursor: "pointer" }}
                  >
                    <Tooltip title="Crear constancias de conservacion de documentos">
                      <img
                        src={CONSTANCIA}
                        alt="Con-Certeza"
                        width="105"
                        height="130"
                        className={classestext.Imagen}
                      />
                    </Tooltip>
                  </Grid>
                </CardIcon>
              </CardHeader>
            </GridItem> */}

          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader stats icon onClick={() => handleflowSign(1)}>
                <CardIcon color="info">
                  <Tooltip title="Firma autógrafa digital sencilla y segura con certeza jurídica.">
                    <img
                      src={firmasimple}
                      alt="Con-Certeza"
                      width="65"
                      height="65"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{ textAlign: "center", fontSize: "11px" }}
                  className={classestext.Textfound}
                >
                  FIRMA SIMPLE CON <br /> CONFIRMACIÓN <br /> POR CÓDIGO
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader stats icon onClick={() => handleflowSign(2)}>
                <CardIcon color="success">
                  <Tooltip title="Firma autógrafa digital con validacion de identificacion.">
                    <img
                      src={firmacerti}
                      alt="Con-Certeza"
                      width="65"
                      height="63"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{ textAlign: "center", fontSize: "11px" }}
                  className={classestext.Textfound}
                >
                  FIRMA SIMPLE <br /> CON IDENTIFICACIÓN <br />
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader
                stats
                icon
                onClick={() => (window.location.href = "/firmaavanzada")}
              >
                <CardIcon color="warning">
                  <Tooltip title="Firma autógrafa digital con validacion de identificacion.">
                    <img
                      src={firmaAvanzada}
                      alt="Con-Certeza"
                      width="65"
                      height="65"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{ textAlign: "center", fontSize: "11px" }}
                  className={classestext.Textfound}
                >
                  FIRMA ELECTRÓNICA
                  <br /> AVANZADA <br />
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader stats icon onClick={handleAutoDeclaracion}>
                <CardIcon color="primary">
                  <Tooltip title="Proceso de auto-firma, tu eres el firmante.">
                    <img
                      src={AUTOFIRMA}
                      alt="Con-Certeza"
                      width="65"
                      height="65"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{ textAlign: "center", fontSize: "11px" }}
                  className={classestext.Textfound}
                >
                  <br /> AUTOFIRMA <br />
                  <br />
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader stats icon onClick={() => handleflowSign(8)}>
                <CardIcon color="danger">
                  <Tooltip title="Proceso de firma en un solo paso, sin código de confirmación.">
                    <img
                      src={FIRMARAPIDA}
                      alt="Con-Certeza"
                      width="67"
                      height="65"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "11px",
                    marginTop: "-1px",
                  }}
                  className={classestext.Textfound}
                >
                  FIRMA SIMPLE CONFIRMACIÓN CON CASILLA DE VERIFICACIÓN
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader stats icon onClick={() => handleVistobueno(3)}>
                <CardIcon color="rose">
                  <Tooltip title="Proceso de aprobación de documento">
                    <img
                      src={vistoBueno}
                      alt="Con-Certeza"
                      width="67"
                      height="65"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "11px",
                    marginTop: "1px",
                  }}
                  className={classestext.Textfound}
                >
                  <br />
                  VISTO BUENO
                </p>
                <br />
              </CardHeader>
            </Card>
          </GridItem> */}
          {/* dialogo para ver video de nuevo proceso*/}
          {/* <div>
            <Dialog
              // fullWidth={true}
              maxWidth="md"
              open={openVideo}
              TransitionComponent={Transition}
              keepMounted
              // onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {"¡Conoce el nuevo proceso de carga!"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <ReactPlayer
                    style={{
                      marginTop: "-20px",
                    }}
                    url={require("assets/videos/nuevoProceso.mp4")}
                    width="100%"
                    controls
                    playing={openVideo}
                    volume="0.1"
                  />
                </DialogContentText>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="No volver a mostrar"
                />
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={() => handleCloseVideo()}>
                  Cerrar
                </Button>
              </DialogActions>
            </Dialog>
          </div> */}
          {/* <GridItem xs={12} sm={6} md={3}>
            {rendervideo === false ? (
              <Tooltip
                title="¡Da clic para conocer las mejoras al proceso de firma!"
                placement="top"
              >
                <ReactPlayer
                  onClick={() => setRenderVideo(true)}
                  style={{
                    marginTop: "-20px",
                  }}
                  url={require("assets/videos/videoCortoNuevoProceso.mp4")}
                  width="200%"
                  heigth="120"
                  playing
                  loop
                />
              </Tooltip>
            ) : (
              <ReactPlayer
                onClick={() => alert("va empezar el otro video")}
                style={{
                  marginTop: "-20px",
                }}
                url={require("assets/videos/nuevoProceso.mp4")}
                width="200%"
                controls
                playing
                volume="0.1"
              />
            )}
          </GridItem> */}
        </GridContainer>
      ) : activeRender === 1 ? (
        <Checkout
          idprocces={idflujo}
          setActiveRender={setActiveRender}
          roles={roles}
        />
      ) : activeRender === 2 ? (
        <Components setActiveRender={setActiveRender} />
      ) : activeRender === 3 ? (
        <ContainerAutoSign setActiveRender={setActiveRender} />
      ) : activeRender === 4 ? (
        <VistoBuenoContainer
          idprocces={idflujo}
          setActiveRender={setActiveRender}
        />
      ) : (
        ""
      )}
    </div>
  );
}
