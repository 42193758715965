import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
//imput
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";

import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "relative",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function AddressFormConfirm(props) {
  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  const { handleConfirmar, success } = props;
  const classes = useStyles();
  const [valuepin, setValuePin] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleInput = (event) => {
    setValuePin(event.target.value);
    console.log("piin", event.target.value);
  };

  const handleConfirmarset = () => {
    goTop();
    handleConfirmar(valuepin);
  };

  let disablePin = true;

  if (valuepin !== "") {
    disablePin = false;
  }
  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
        ref={ref}
      >
        <Grid item xs={12} sm={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Por favor, ingrese el código de confirmación.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="pin"
            name="pin"
            label="Código"
            type="number"
            fullWidth
            autoComplete="given-name"
            onChange={handleInput}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
            error={valuepin.length > 3 ? false : true}
          />
        </Grid>
      </Grid>
      <div>
        <br></br>
      </div>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={disablePin}
          onClick={handleConfirmarset}
        >
          Confirmar
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={success}>
        <Grid>
          <CircularProgress size={44} className={classes.buttonProgress} />
          <br></br>
          <Typography variant="body2" color="textSecondary" align="center">
            Espera un poco
          </Typography>
        </Grid>
      </Backdrop>
    </React.Fragment>
  );
}
