import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import Header from "components/Header/Header.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Cardlogin/Card.js";
import CardBody from "components/Cardlogin/CardBody.js";
import CardHeader from "components/Cardlogin/CardHeader.js";
import CardFooter from "components/Cardlogin/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import logo from "containers/Logo.png";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/fondolo-min.jpg";
//import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function LoginPage(props) {
  // const dispatch = useDispatch();
  // const token = useSelector((state) => state.token);
  // console.log("el estado total de mi app es ", token);
  const history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState("");
  const [password, setPassWord] = React.useState("");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const classes = useStyles();
  const classes2 = useStyles2();
  const { ...rest } = props;

  React.useEffect(() => {
    localStorage.removeItem("Token");
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleSendLogin = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);

    let urlLogin = `${process.env.REACT_APP_URL}api/auth/login`;

    axios
      .post(urlLogin, data, {})
      .then((response) => {
        // Respuesta del servidor
        // dispatch({
        //   type: "SET_TOKEN",
        //   payload: response.data.access_token,
        // });
        const token = response.data.access_token;

        localStorage.setItem("Token", token);
        //console.log("token inicio de sesion", token);

        history.push("/admin/dashboard");
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data.message);
          alert(error.response.data.message);
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 6000);
    }
  };

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand={
          <div>
            <img src={logo} alt="logo" width="230" height="80" />
          </div>
        }
        // rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        // className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form} onSubmit={handleSendLogin}>
                  <CardHeader color="info" className={classes.cardHeader}>
                    <h3>Con Certeza</h3>
                    <h5
                      href="https://certificaciondigital.online/"
                      className={classes.block}
                      target="_blank"
                      color="primary"
                    >
                      Certificacion Digital Online
                    </h5>
                    {/* <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fa fa-twitter"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fa fa-facebook-square"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fa fa-google-plus"} />
                      </Button>
                    </div> */}
                  </CardHeader>
                  <h4 className={classes.divider}> Inicia sesíon</h4>
                  <CardBody>
                    <CustomInput
                      labelText="Correo..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (e) => {
                          const { value } = e.target;
                          console.log("email", email);
                          setEmail(value);
                        },
                        value: email,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Contraseña"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e) => {
                          const { value } = e.target;
                          console.log("password ", password);
                          setPassWord(value);
                        },
                        value: password,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      // onClick={handleSendLogin}
                      type="submit"
                      color="success"
                      size="lg"
                      disabled={loading}
                      className={buttonClassname}
                      simple
                    >
                      INICIAR SESION
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={44}
                        className={classes2.buttonProgress}
                      />
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
