import React from "react";
import Carousel from 'react-material-ui-carousel'
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
//icons
import PieChartIcon from '@material-ui/icons/PieChart';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import BarChartIcon from '@material-ui/icons/BarChart';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';

import GraficaDetalle from "./GraficaDetalle";
import GraficaPrincipal from "./GraficaPrincipal";
import GraficaPromedioFirma from "containers/Graficas/PromedioFirmaPorUsuario";
import GraficaPromedioDocumentos from "containers/Graficas/PromedioFirmaDocumentosBar";
import GraficaPrincipalPie from "./GraficaPrincipalPie"
import GraficaStatus from "containers/Graficas/StatusBar"
import DocumentosPorEstatus from "containers/Graficas/DocumentosPorEstatus";
import RangoPromFirmaDocumentosBar from "containers/Graficas/RangoPromFirmaDocumentosBar";

import moment from "moment";
import axios from "axios";
export default function EstadisticasGraficas() {
  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  const [dataGraficas, setDataGraficas] = React.useState("");
  const [tipoGrafica, setTipoGrafica] = React.useState(2);
  const [disabled1, setDisabled1] = React.useState(false);
  const [disabled2, setDisabled2] = React.useState(false);
  const [disabled3, setDisabled3] = React.useState(false);
  const [disabled4, setDisabled4] = React.useState(false);
  const [disabled5, setDisabled5] = React.useState(false);
  const [disabled6, setDisabled6] = React.useState(false);
  const [disabled7, setDisabled7] = React.useState(false);
  const [disabled8, setDisabled8] = React.useState(false);
  const [disabled9, setDisabled9] = React.useState(false);
  const [disabled10, setDisabled10] = React.useState(false);
  const [disabled11, setDisabled11] = React.useState(false);
  const [disabled12, setDisabled12] = React.useState(false);
  const [disabled13, setDisabled13] = React.useState(false);
  const [mostrarBotones, setMostrarBotones] = React.useState(true);


  function consultarDatos(status){
    //let token = localStorage.getItem("Token");
    let token = `${process.env.REACT_APP_TOKEN}`;
    //DATA
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/status/${status}/stats`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let arr = response.data;
        var dias = [];
        var totaldias=0;
        for (var i = arr.daily.labels.length-1; i >=0 ; i--) {
          totaldias+=arr.daily.series[i];
          dias = [
            ...dias,
            {x: arr.daily.labels[i], y: arr.daily.series[i]}
          ];
        }
        console.log("dias",dias);
        var meses = [];
        var totalmeses=0;
        for (var i = arr.monthly.labels.length-1; i >=0 ; i--) {
          totalmeses+=arr.monthly.series[i];
          meses = [
            ...meses,
            {x: arr.monthly.labels[i].substring(0, 3), y: arr.monthly.series[i]}
          ];
        }
        console.log(meses);
      })
      .catch(function (error) {
        console.log(error);
      });
        //FIN DATA
  }

  React.useEffect(() => {

      }, []);

function Grafica(){

  switch(tipoGrafica) {

        case 1:
            return (
              <Paper>
                <GraficaPrincipal />
              </Paper>
            )
        case 2:
            return (
              <Paper>
                  <DocumentosPorEstatus
                    setMostrarBotones={setMostrarBotones}
                  />
              </Paper>
            )
        case 3:
            return (
              <Paper>
                <GraficaPrincipalPie />
              </Paper>
            )
        case 4:
            return (
              <Paper>
                <GraficaPromedioDocumentos color={"#00acc1"} />
              </Paper>
            )
        case 5:
            return (
              <Paper>
                <GraficaPromedioFirma color={"#00acc1"} />
              </Paper>
            )
        case 6:
            return (
              <Paper>
                <RangoPromFirmaDocumentosBar />
              </Paper>
            )
        case 7:
            return (
              <Paper>
              <GraficaStatus
                status={0}
                titulo={"EN TOTAL"}
                color={"#00acc1"}
              />
              </Paper>
            )
        case 8:
            return (
              <Paper>
                <GraficaStatus
                status={3}
                titulo={"FIRMADOS"}
                color={"#66bb6a"}
              />
              </Paper>
            )
        case 9:
            return (
              <Paper>
                <GraficaStatus
                  status={2}
                  titulo={"EN PROCESO"}
                  color={"#ffa726"}
                />
              </Paper>
            )
        case 10:
            return (
              <Paper>
                <GraficaStatus
                  status={4}
                  titulo={"RECHAZADOS"}
                  color={"#ef5350"}
                />
              </Paper>
            )
        case 11:
            return (
              <Paper>
                <GraficaStatus
                  status={6}
                  titulo={"DETENIDOS"}
                  color={"#ab47bc"}
                />
              </Paper>
            )
        case 12:
            return (
              <Paper>
                <GraficaStatus
                  status={1}
                  titulo={"REGISTRADOS"}
                  color={"#ab47bc"}
                />
              </Paper>
            )
        case 13:
            return (
              <Paper>
                <GraficaStatus
                status={5}
                titulo={"CONSERVADOS"}
                color={"#66bb6a"}
              />
              </Paper>
            )
        default:      return <h1>NO hay gráfica</h1>
      }

}

  return (
    <React.Fragment>
    <div ref={ref}>
      <Grafica />
    </div>
    <hr />
{
  mostrarBotones ? (

    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <Paper>
      {/*
      <Tooltip title="Documentos por estatus" placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled1}
          onClick={() => {
            setTipoGrafica(1);
            setDisabled1(true);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <FiberManualRecordIcon />
        </IconButton>
      </Tooltip>
      */}

      <Tooltip title={<h5>Totales por estatus</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled2}
          onClick={() => {
            setMostrarBotones(false);
            setTipoGrafica(2);
            setDisabled1(false);
            setDisabled2(true);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <VerticalSplitIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos por estatus</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled3}
          onClick={() => {
            setTipoGrafica(3);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(true);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <PieChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Días en promedio para firma de documentos</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled4}
          onClick={() => {
            setTipoGrafica(4);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(true);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <BarChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Horas en promedio de los firmantes</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled5}
          onClick={() => {
            setTipoGrafica(5);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(true);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <BarChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos según tiempo promedio de firma</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled6}
          onClick={() => {
            setTipoGrafica(6);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(true);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <ViewHeadlineIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos en total</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled7}
          onClick={() => {
            setTipoGrafica(7);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(true);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <InsertChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos firmados</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled8}
          onClick={() => {
            setTipoGrafica(8);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(true);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <InsertChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos en proceso</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled9}
          onClick={() => {
            setTipoGrafica(9);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(true);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <InsertChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos rechazados</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled10}
          onClick={() => {
            setTipoGrafica(10);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(true);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <InsertChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos detenidos</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled11}
          onClick={() => {
            setTipoGrafica(11);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(true);
            setDisabled12(false);
            setDisabled13(false);
            goTop();
          }}
          >
          <InsertChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos registrados</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled12}
          onClick={() => {
            setTipoGrafica(12);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(true);
            goTop();
          }}
          >
          <InsertChartIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={<h5>Documentos conservados</h5>} placement="top">
        <IconButton
          aria-label="Gráfica"
          color="primary"
          disabled={disabled13}
          onClick={() => {
            setTipoGrafica(13);
            setDisabled1(false);
            setDisabled2(false);
            setDisabled3(false);
            setDisabled4(false);
            setDisabled5(false);
            setDisabled6(false);
            setDisabled7(false);
            setDisabled8(false);
            setDisabled9(false);
            setDisabled10(false);
            setDisabled11(false);
            setDisabled12(false);
            setDisabled13(true);
            goTop();
          }}
          >
          <InsertChartIcon />
        </IconButton>
      </Tooltip>

      </Paper>
    </div>

  ):null
}

    </React.Fragment>
  )
}
