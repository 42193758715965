import React from 'react';
import {
makeVisFlexible,
 XYPlot,
 XAxis,
 YAxis,
 ChartLabel,
 VerticalGridLines,
 HorizontalGridLines,
 VerticalBarSeries,
 VerticalBarSeriesCanvas,
 LabelSeries,
 RadialChart,
 Borders
 } from 'react-vis';
import './style.css'; // css styling from the react-vis node module

class Grafica extends React.Component {
  constructor(props) {
    super(props);
}
  render() {
//  const greenData = [{x: 'A', y: 10}, {x: 'B', y: 5}, {x: 'C', y: 15}];
//  const blueData = [{x: 'A', y: 12}, {x: 'B', y: 2}, {x: 'C', y: 11}];
/*
  const labelData = greenData.map((d, idx) => ({
    x: d.x,
    y: Math.max(greenData[idx].y, blueData[idx].y)
  }));
  */
    const FlexibleXYPlot = makeVisFlexible(XYPlot);
    const useCanvas = true;
    const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS';
    const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

var data = [];
if(this.props.data){
  data=this.props.data;
}
else{
  data = [
    {x: 0, y: 0},
  ];
}
const chartDomain = [0, 100];
    return (
      <div>
      <h5><b>{this.props.titulo}</b></h5>
      <XYPlot
        xType="ordinal"
        width={this.props.width}
        height={this.props.height}
        xDistance={100}
      >
      {/*
          <VerticalGridLines />
          <HorizontalGridLines />
          */}
          <HorizontalGridLines />
          <Borders style={{
            all: {fill: '#fff'},
            bottom: {fill: '#fff'}
          }}/>
          {/*
          <XAxis />
          <YAxis />
          */}
          <XAxis />
          <BarSeries
            color={this.props.color}
            data={data}
          />
          <LabelSeries
          animation
          allowOffsetToBeReversed
          data={data.map((obj) => {
            return { ...obj, label: obj.y.toString(), style: {fontSize: 10} };
          })}
          labelAnchorX="middle"
          labelAnchorY="text-after-edge"
        />
        </XYPlot>
      </div>
    );
  }
}

export default Grafica;
